import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import LoadingBar from "react-top-loading-bar"
import BASE_URL from "../../API";
import { NavLink } from "react-router-dom";

export default function Index() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    const [progress, setProgress] = useState(70)
    const [states, setstates] = useState([]);
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        axios.get(BASE_URL.URL1 + "/api/Home/GetPharmacyStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                //console.log(err);
            })
    }, []);
    //console.log(states);

    return (
        <>
            <Helmet>
                <title>Best Pharmacies Near Me | Pharmacies in USA | DHPFinder</title>
                <meta name="description" content="Find best Pharmacies, get directions, addresses, phone numbers and more." />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            <div class="page-title-area" style={{ backgroundImage: 'url(../images/pharmacy/mainbannerpharmacy.jpg)' }}>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="page-title-item">
                            <h2>Pharmacy</h2>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li class="active">Pharmacy</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="doctor-search-area">
                <div class="container">
                    <form>
                        <div class="row doctor-search-wrap">
                            <div class="col-sm-6 col-lg-6">
                                <div class="doctor-search-item">
                                    <div class="form-group"><i class="icofont-hospital"></i><label>Search</label><input
                                        type="text" class="form-control" placeholder="Pharmacy Name" /></div><button
                                            type="submit" class="btn doctor-search-btn"><i
                                                class="icofont-search-1"></i></button>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="doctor-search-item">
                                    <div class="form-group"><i class="icofont-doctor-alt"></i><label>Search</label><input
                                        type="text" class="form-control" placeholder="City Name" /></div><button
                                            type="submit" class="btn doctor-search-btn"><i
                                                class="icofont-search-1"></i></button>
                                </div>
                            </div>
                            {/* <div class="col-sm-6 col-lg-6">
                                <div class="doctor-search-item">
                                    <div class="form-group"><i class="icofont-hospital"></i><label>Category</label><select
                                        class="form-control">
                                        <option>Neurosurgeon</option>
                                        <option>Cardiology</option>
                                        <option>Pathology</option>
                                        <option>Dental Care</option>
                                    </select></div>
                                </div>
                            </div> */}
                        </div>
                    </form>
                </div>
            </div>

            <div className="services-area my-4">
                {
                    states.length > 0 ?
                        <div className="container">
                            <div className="section-title-two"><span>States</span>
                                <h2 style={{ width: '100%' }}>Select By States</h2>
                            </div>
                            <div className="row justify-content-center" id="states">
                                {
                                    states.map((a =>
                                        <div className="col-md-4 col-6 col-lg-4">
                                            <NavLink to={'/best-pharmacies/' + a.state.trim().replace(/ /g, '-').toLowerCase() + '/'}>
                                                <div className="container">
                                                    <div className="row service-item rounded-4" style={{ padding: '5px', border: '1px solid blue' }}>
                                                        <div className="col-md-3">
                                                            <img src={a.flag} style={{ width: '50px', height: '50px', borderRadius: '50px' }} />
                                                        </div>
                                                        <div className="col-md-9" style={{ textAlign: 'left' }}>
                                                            <h5>{a.state}</h5>
                                                            <small>Best Pharmacies in {a.state.replace('District of Columbia', 'D.C.')}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        : <div className="text-center"><h2><b>Loading...</b></h2></div>
                }
            </div>
            {/* <br />
            <br />          
            <div className="services-area my-4">
                <div className="container">
                    <div className="section-title-two">
                        <h4 style={{ marginLeft: '-1%' }}><b><u>Frequently Asked Questions</u></b></h4>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-8 border pt-3">
                            <h6>Is pharmacy a good career in us?</h6>
                            <p>Pharmacy is considered an great profession in the United States and depends on a variety of factors, market demand, including personal interests, potential salary, job satisfaction, and career opportunities.</p>
                            <p>The U.S. Department of Work Measurements (BLS) expects employment as a professional pharmacist to grow by 3% from 2022 to 2032, roughly the same as the average for all occupations. Around 13,400 new jobs will be created annually over the next 10 years.</p>
                        </div>
                        <div className="col-md-8 border pt-3 mt-1">
                            <h6>How do US pharmacies work?</h6>
                            <p>Pharmacies are routinely openly moved by, although many are part of large pharmacy chains. Retail pharmacies should fulfill specific requirements and guidelines of training,  for example, keeping precise stock records, having secure capacity regions for medication and appropriately labeling and bundling medication.</p>
                        </div>
                        <div className="col-md-8 border pt-3 mt-1">
                            <h6>What is the minimum salary of pharmacist in US?</h6>
                            <p>The average pharmacist salary in the USA is $119,026 per year or $57.22 per hour.  Section level positions start at $104,825 per year while most experienced laborers make up to $147,034 per year.</p>
                        </div>
                        <div className="col-md-8 border pt-3 mt-1">
                            <h6>What is the maximum salary of pharmacist in USA?</h6>
                            <p>The average salary for Pharmacist is $1,32,419 per year in the US. The average extra money remuneration for a Pharmacist in the US is $11,470, with a range from $8,603 - $16,059.</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <br />
            <br />
            <br />
        </>
    )
}