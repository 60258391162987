import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { Helmet } from "react-helmet";
import BASE_URL from "../../API";

export default function BlogDetails() {
    var parameter = useParams();
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    var newUrl = currentUrl.slice(0, -1);
    if (lastElement == "/") {
        window.history.pushState({}, null, newUrl);
    }

    const [nxtlink, setnxtlink] = useState(0)
    const [progress, setProgress] = useState(70)
    const [blogdetailserr, setblogdetailserr] = useState(0)
    const [blogdetails, setblogdetails] = useState([]);
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        axios.get(BASE_URL.URL1 + "/api/Blog/GetBlogDetails?blogname=" + parameter.parameter.trim().replace(/-/g, ' ').toLowerCase())
            .then(response => {
                window.scroll({ top: 0, left: 0, behavior: 'smooth' });
                if (response.data.length > 0) {
                    setblogdetails(response.data)
                    setProgress(100)
                } else {
                    setblogdetailserr(1)
                    setProgress(100)
                }
            })
            .catch(err => {
                //console.log(err);
                setblogdetailserr(1)
                setProgress(100)
            })
    }, [nxtlink]);
    //console.log(blogdetails);

    const [relatdblogs, setrelatedblogs] = useState([]);
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        axios.get(BASE_URL.URL1 + "/api/Blog/GetRelatedBlogs?blogname=" + parameter.parameter.trim().replace(/-/g, ' ').toLowerCase())
            .then(response => {
                setrelatedblogs(response.data)
            })
            .catch(err => {
                //console.log(err);
            })
    }, [nxtlink]);
    //console.log(relatdblogs);

    if (blogdetails.length > 0) {
        const dateString = blogdetails[0].publishDate;
        const date = new Date(dateString);
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        blogdetails[0].publishDate = date.toLocaleDateString('en-US', options);
    }

    if (relatdblogs.length > 0) {
        for (let i = 0; i < relatdblogs.length; i++) {
            const dateString = relatdblogs[i].publishDate;
            const date = new Date(dateString);
            const options = { month: 'long', day: 'numeric', year: 'numeric' };
            relatdblogs[i].publishDate = date.toLocaleDateString('en-US', options);
        }
    }

    let previousHeading = null; // Track the previous value
    return (
        <>
            {
                blogdetailserr != 1 ?
                    blogdetails.length > 0 ?
                        <>
                            <LoadingBar color='blue' progress={100} height={5} />
                            <Helmet>
                                <title>{blogdetails[0].blogDetailsHeading} | DHPFinder</title>
                                <meta name="description" content={blogdetails[0].metaDescription} />
                            </Helmet>
                            <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <div class="page-title-item">
                                            <h2 style={{ textTransform: 'capitalize' }}>{blogdetails[0].blogDetailsHeading}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="blog-details-area pt-100">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <div class="blog-details-item">
                                                <div class="blog-details-img"><img src={'https://www.dhpfinder.com' + blogdetails[0].blogImage} alt={blogdetails[0].blogDetailsHeading} />
                                                    <br />
                                                    <label id="date">Published: <span>{blogdetails[0].publishDate}</span></label>
                                                    <br />
                                                    {
                                                        blogdetails.map((d, i) => {
                                                            const shouldPrint = d.blogDetailsHeading !== previousHeading;
                                                            previousHeading = d.blogDetailsHeading
                                                            return (
                                                                <>
                                                                    {
                                                                        shouldPrint ?
                                                                            <div>
                                                                                {
                                                                                    d.contentTypeSpecial.toUpperCase() == 'S' ?
                                                                                        <h5>{d.blogDetailsHeading}</h5>
                                                                                        : <h4 className="font-weight-bold">{d.blogDetailsHeading}</h4>
                                                                                }
                                                                                {
                                                                                    blogdetails.map((a, k) => {
                                                                                        return (
                                                                                            <>
                                                                                                {
                                                                                                    d.blogDetailsHeading == a.blogDetailsHeading ?
                                                                                                        a.contentType.toUpperCase() == 'P' ?
                                                                                                            <p className="text-justify">{a.paragraph}</p>
                                                                                                            : a.contentType.toUpperCase() == 'L' ?
                                                                                                                <div class="blog-details-previous">
                                                                                                                    <ul>
                                                                                                                        {
                                                                                                                            a.list.split('|').map((b, e) => {
                                                                                                                                return (
                                                                                                                                    <div className="d-flex mb-1">
                                                                                                                                        <b className="pr-2">{e + 1}. </b><li>{b}</li>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </ul>
                                                                                                                </div>
                                                                                                                : ''
                                                                                                        : ''
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div class="blog-details-form pb-100">
                                                <div class="blog-details-form-wrap">
                                                    <h2>Drop your comment</h2>
                                                    {/* <form> */}
                                                        <div class="form-group"><textarea class="form-control" id="your_comments" rows="8" placeholder="Your Comments"></textarea></div>
                                                        <div class="row">
                                                            <div class="col-lg-6">
                                                                <div class="form-group"><input type="text" class="form-control" placeholder="Name" /></div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group"><input type="email" class="form-control" placeholder="Email" /></div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group"><input type="text" class="form-control" placeholder="Phone" /></div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group"><input type="text" class="form-control" placeholder="Subject" /></div>
                                                            </div>
                                                        </div>
                                                        <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post A Comment</button></div>
                                                    {/* </form> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="blog-details-item">
                                                <div class="blog-details-search">
                                                    <form>
                                                        <input type="text" class="form-control" placeholder="Search" />
                                                        <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
                                                    </form>
                                                </div>
                                                <div class="blog-details-recent">
                                                    <h3>Recent Blogs</h3>
                                                    <ul>
                                                        {
                                                            relatdblogs.length > 0 &&
                                                            relatdblogs.map((d =>
                                                                <>
                                                                    <li>
                                                                        <img src={'https://www.dhpfinder.com' + d.blogImage} alt={d.blogDetailsHeading} />
                                                                        <NavLink onClick={() => { setnxtlink(nxtlink + 1); setblogdetails([]); setrelatedblogs([]) }} to={'/blogs/' + d.blogHeading.trim().replace(/ /g, '-').toLowerCase()}>{d.blogHeading}
                                                                            <ul>
                                                                                <li><i class="icofont-calendar"></i> {d.publishDate}</li>
                                                                            </ul>
                                                                        </NavLink>
                                                                    </li>
                                                                    <br />
                                                                </>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </> : <div><h2 className="text-center pt-5 mt-5 mb-3 my-auto"><b>Loading...</b></h2><br /><br /></div>
                    : <>
                        <LoadingBar color='blue' progress={100} height={5} />
                        <Helmet>
                            <title>Blogs Not Found | DHPFinder</title>
                            <meta name="description" content="Blogs Not Found" />
                        </Helmet>
                        <div>
                            <h2 className="text-center pt-5 mt-5 mb-3 my-auto">
                                <b>Blogs Not Found</b>
                            </h2>
                            <br />
                            <div className="col-12 text-center">
                                <NavLink to='/' className='btn btn-danger text-center'>Go To Home</NavLink>
                            </div>
                            <br />
                            <br />
                        </div>
                    </>
            }
        </>
    )


    // if (parameter.parameter == "adhd-diagnosis") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>ADHD Diagnosis | DHPFinder</title>
    //                 <meta name="description" content="A timely ADHD diagnosis may lead to effective management of the disorder. Read more about diagnosing this condition." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/1.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>November, 6 2023</span></label>
    //                                     <p className="text-justify">Attention deficit disorder (ADD), now more commonly known as ADHD (attention deficit hyperactivity disorder), is a mental condition that is highly prevalent across the U.S. The disorder mostly affects children, but it could persist into adulthood too. The disorder is characterized by a combination of problems affecting the individual’s ability to maintain focus and causing high levels of hyperactivity and impulsive behavior. These can have serious negative impacts on a person’s daily routine, and may trigger the onset of depression and damage a person’s self-esteem.</p>
    //                                     <p className="text-justify">Despite the widespread attention that ADD receives, it is very hard to formally diagnose ADD in individuals. This is because the symptoms can often be mistaken as a response to stress or due to another factor. Because children are generally more energetic and impulsive than adults, there is a higher chance of error in anADD diagnosiswithin this age group.</p>
    //                                     <p className="text-justify">The symptoms are classified into three categories: inattentiveness, hyperactivity, and impulsive behavior. These include, but are not restricted to:</p>
    //                                 </div>
    //                                 <div class="blog-details-previous">
    //                                     <ul>
    //                                         <li><b>1.</b> Being easily distracted</li>
    //                                         <li><b>2.</b> Failing to complete tasks like schoolwork</li>
    //                                         <li><b>3.</b> Having difficulty awaiting turns</li>
    //                                         <li><b>4.</b> Constant fidgeting</li>
    //                                         <li><b>5.</b> Forgetfulness</li>
    //                                     </ul>
    //                                     <br />
    //                                     <p className="text-justify">In order to help make an ADD diagnosis easier, the American Academy of Pediatrics has developed some set guidelines to help physicians identify the presence of ADD in children. One test for diagnosing ADDin children counts the number of symptoms the child is experiencing from each of the above categories before the age of 7. The test then questions whether the symptoms have been present for over 6 months in the child, and whether they cause difficulty across all settings, including school, home, and in relationships with others. In adults, an ADD diagnosis is made through a psychological evaluation of the individual; in addition to consulting with a physician to rule out any external contributing factors. Some doctors also use simple questionnaires to determine the presence of ADD.</p>
    //                                     <p className="text-justify">Regardless of what method is used to diagnose ADD in a child or an adult, these tests are not capable of pointing out the cause of the condition in the individual. If a person is diagnosed with ADD, it is important to consult with a physician and develop a strategy to control its influence on their lifestyle.</p>
    //                                 </div>
    //                                 {/* <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                         <h2>Sources:</h2>
    //                                         <ul>
    //                                             <li><a href="http://www.mayoclinic.com/health/adhd/DS00275" target="_blank">http://www.mayoclinic.com/health/adhd/DS00275</a></li>
    //                                             <li><a href="http://www.ncbi.nlm.nih.gov/pubmedhealth/PMH0002518/" target="_blank">http://www.ncbi.nlm.nih.gov/pubmedhealth/PMH0002518/</a></li>
    //                                             <li><a href="http://www.add.org/?page=ADHD_faqs" target="_blank">http://www.add.org/?page=ADHD_faqs</a></li>
    //                                             <li><a href="http://www.nhs.uk/Conditions/Attention-deficit-hyperactivity-disorder/Pages/Introduction.aspx" target="_blank">http://www.nhs.uk/Conditions/Attention-deficit-hyperactivity-disorder/Pages/Introduction.aspx</a></li>
    //                                         </ul>
    //                                     </div> */}
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/2.png" alt="Recent" /><NavLink to="/blogs/adhd-treatment">ADD Treatment</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 10 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/3.png" alt="Recent" /><NavLink to="/blogs/adult-adhd">Adult ADD</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 14 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/4.png" alt="Recent" /><NavLink to="/blogs/misdiagnosis-of-adhd">Misdiagnosis of ADHD</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 18 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "adhd-treatment") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>ADHD Treatment | DHPFinder</title>
    //                 <meta name="description" content="While there is no ADHD treatment that can cure the condition, options are available to help individuals manage their symptoms. Read on to learn more." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/2.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>November, 10 2023</span></label>
    //                                     <p className="text-justify">Attention Deficit Disorder (ADD) is a chronic mental condition that impairs attentiveness in an individual, increases hyperactivity, and causes a rise in impulsive behavior. While it is possible for the intensity of the disorder to decrease as children get older, some will carry it with them into adulthood.</p>
    //                                     <p className="text-justify">What further complicates matters is the ambiguity regarding the causes of ADD. This makes it more difficult to develop a formal treatment for ADD. However, several ADD treatment options have been developed to help individuals manage their symptoms and carry on with their daily routines.</p>
    //                                     <p className="text-justify"><b>What are the ADD Treatment options?</b></p>
    //                                     <p className="text-justify"><b>Treatments for ADD</b> are divided into two categories: psychotherapy and medications. Depending on the age and symptoms of an individual, doctors recommend using one type, or a combination of the two, to help the person manage and gain more control over the disorder.</p>
    //                                     <p className="text-justify">Psychotherapy involves working closely with the child or adult to help them gain control over their emotions. Children at a young age can undergo behavioral therapy to help them become more attentive and calm. This may also help them learn how to behave appropriately in different settings, like in school or at home. Adults can also benefit from psychotherapy by learning techniques to control their impulsive urges and maintain enough discipline to complete important tasks. Moreover, individuals can learn to overcome the negative feelings they may have as a result of their condition, and maintain a positive outlook on life.</p>
    //                                     <p className="text-justify"><b>ADD treatment</b> may also rely on medication. Ironically, doctors may prescribe medicines like Ritalin to help manage ADD symptoms. Ritalin is a stimulant used by individuals to become more alert, which seems to be counter-intuitive as a treatment option. However, this medication has a calming effect on ADD individuals, and helps to improve their ability to focus.</p>
    //                                     <p className="text-justify">With medication, it is hard to use a blanket approach because each patient’s health, age, and lifestyle habits factor into the type and dosage of medication prescribed. Besides Ritalin, other commonly used medicines for treating ADD include:</p>
    //                                 </div>
    //                                 <div class="blog-details-previous">
    //                                     <ul>
    //                                         <li><b>1.</b> Adderall</li>
    //                                         <li><b>2.</b> Concerta</li>
    //                                         <li><b>3.</b> Focalin</li>
    //                                         <li><b>4.</b> Ritalin</li>
    //                                         <li><b>5.</b> Vyvanse</li>
    //                                     </ul>
    //                                     <br />
    //                                     <p className="text-justify">It is important to note that these medicines are only effective at helping to manage the symptoms in an individual and not completely curing them.</p>
    //                                 </div>
    //                                 {/* <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                             <h2>Sources:</h2>
    //                                             <ul>
    //                                                 <li><a href="http://www.helpguide.org/mental/adhd_add_treatments_coping.htm" target="_blank">http://www.helpguide.org/mental/adhd_add_treatments_coping.htm</a></li>
    //                                                 <li><a href="http://psychcentral.com/lib/2007/additional-treatments-for-adhd/" target="_blank">http://psychcentral.com/lib/2007/additional-treatments-for-adhd/</a></li>
    //                                                 <li><a href="http://www.mayoclinic.com/health/adhd/DS00275/DSECTION=treatments-and-drugs" target="_blank">http://www.mayoclinic.com/health/adhd/DS00275/DSECTION=treatments-and-drugs</a></li>
    //                                                 <li><a href="http://www.nimh.nih.gov/health/publications/attention-deficit-hyperactivity-disorder/how-is-adhd-treated.shtml" target="_blank">http://www.nimh.nih.gov/health/publications/attention-deficit-hyperactivity-disorder/how-is-adhd-treated.shtml</a></li>
    //                                             </ul>
    //                                         </div> */}
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/1.png" alt="Recent" /><NavLink to="/blogs/adhd-diagnosis">ADD Diagnosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/3.png" alt="Recent" /><NavLink to="/blogs/adult-adhd">Adult ADD</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 14 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/4.png" alt="Recent" /><NavLink to="/blogs/misdiagnosis-of-adhd">Misdiagnosis of ADHD</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 18 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "adult-adhd") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Adult ADHD | DHPFinder</title>
    //                 <meta name="description" content="Adult ADHD is a serious mental condition which is capable of ruining social relationships. However, ADHD can be managed with medications and therapy." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/3.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>November, 14 2023</span></label>
    //                                     <p className="text-justify">Adult attention deficit disorder (ADD) is a chronic condition, but there are treatments available to help manage the disorder. The condition, however, must be taken seriously as it is capable of adversely affecting a person’s day-to-day life.</p>
    //                                     <p className="text-justify">The following symptoms may indicate attention deficit disorder in adults:</p>
    //                                 </div>
    //                                 <div class="blog-details-previous">
    //                                     <ul>
    //                                         <li><b>1.</b> Trouble concentrating and focusing</li>
    //                                         <li><b>2.</b> Impulsivity</li>
    //                                         <li><b>3.</b> Restlessness</li>
    //                                         <li><b>4.</b> Unstable relationships</li>
    //                                         <li><b>5.</b> Trouble handling stress</li>
    //                                         <li><b>6.</b> Short temper</li>
    //                                         <li><b>7.</b> Frequent mood swings</li>
    //                                         <li><b>8.</b> Low frustration tolerance</li>
    //                                         <li><b>9.</b> Disorganization</li>
    //                                         <li><b>10.</b> Difficulty completing tasks</li>
    //                                     </ul>
    //                                 </div>
    //                                 <div class="blog-details-img">
    //                                     <p className="text-justify">ADD usually starts in childhood, and a person’s symptoms maystart to subside as the person grows older. There are some individuals whose symptoms never go away as they age, so they may be diagnosed with adult ADD. The following are some of the risk factors which may lead to ADD:</p>
    //                                 </div>
    //                                 <div class="blog-details-previous">
    //                                     <ul>
    //                                         <li><b>1.</b> Children born prematurely</li>
    //                                         <li><b>2.</b> Children exposed to environmental toxins like lead</li>
    //                                         <li><b>3.</b> Pregnant mothers exposed to environmental toxins like polychlorinated biphenyls (PCBs) may give birth to children with ADD</li>
    //                                         <li><b>4.</b> Pregnant mothers who smoke, use drugs, or drink alcohol may give birth to children with ADD</li>
    //                                         <li><b>5.</b> Children with a family history of ADD or other mental disorders may also be at an increased risk of developing ADD </li>
    //                                     </ul>
    //                                 </div>
    //                                 <div class="blog-details-img">
    //                                     <p className="text-justify">If you are diagnosed with ADD, it is very important to remember that there are treatment options available. With proper treatment, possibly a combination of medications and therapy, you may be able to control your ADD. Stimulants are often prescribed by the doctors to restore the chemical balance in the brain. If you cannot take stimulants for any reason, your doctor may prescribe antidepressants and atomoxetine. In addition, psychological counseling may also help. It will help you to:</p>
    //                                 </div>
    //                                 <div class="blog-details-previous">
    //                                     <ul>
    //                                         <li><b>1.</b> Control your temper</li>
    //                                         <li><b>2.</b> Improve your relationship with relatives, friends, and colleagues</li>
    //                                         <li><b>3.</b> Improve your self-esteem</li>
    //                                         <li><b>4.</b> Cope with your past social and academic failures</li>
    //                                         <li><b>5.</b> Get better at problem-solving</li>
    //                                         <li><b>6.</b> Control your impulsiveness</li>
    //                                         <li><b>7.</b> Improve your organizational and management skills</li>
    //                                     </ul>
    //                                     <br />
    //                                     <p className="text-justify">Furthermore, psychotherapy may also be advised by your doctor to help you deal with how the condition is affecting your life and relationships. Therefore, the condition that developed in your childhood and stayed with you into your adulthood can be managed, even if not cured permanently. What is important is to keep in touch with your healthcare provider and not give up.</p>
    //                                 </div>
    //                                 {/* <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                                 <h2>Sources:</h2>
    //                                                 <ul>
    //                                                     <li><a href="http://www.childmind.org/en/posts/ask-an-expert/2011-8-30-what-difference-between-add-and-adhd" target="_blank">http://www.childmind.org/en/posts/ask-an-expert/2011-8-30-what-difference-between-add-and-adhd</a></li>
    //                                                     <li><a href="http://www.healthline.com/health/add-adhd-attention-deficit-difference-between-add-adhd" target="_blank">http://www.healthline.com/health/add-adhd-attention-deficit-difference-between-add-adhd</a></li>
    //                                                     <li><a href="http://www.mayoclinic.com/health/adult-adhd/DS01161" target="_blank">http://www.mayoclinic.com/health/adult-adhd/DS01161</a></li>
    //                                                     <li><a href="http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=causes" target="_blank">http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=causes</a></li>
    //                                                     <li><a href="http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=symptoms" target="_blank">http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=symptoms</a></li>
    //                                                     <li><a href="http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=risk-factors" target="_blank">http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=risk-factors</a></li>
    //                                                     <li><a href="http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=treatments-and-drugs" target="_blank">http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=treatments-and-drugs</a></li>
    //                                                 </ul>
    //                                             </div> */}
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/1.png" alt="Recent" /><NavLink to="/blogs/adhd-diagnosis">ADD Diagnosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/2.png" alt="Recent" /><NavLink to="/blogs/adhd-treatment">ADD Treatment</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 10 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/4.png" alt="Recent" /><NavLink to="/blogs/misdiagnosis-of-adhd">Misdiagnosis of ADHD</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 18 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "misdiagnosis-of-adhd") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Misdiagnosis of ADHD | DHPFinder</title>
    //                 <meta name="description" content="A misdiagnosis of ADHD is common due to the symptoms being so similar to other conditions. Read on for more information on ADHD and diagnosing it." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/4.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>November, 18 2023</span></label>
    //                                     <p className="text-justify">Attention deficit hyperactive disorder (ADHD) is a condition that affects children and may persist into adulthood. This disorder has several different symptoms which are categorized into three groups: inattentiveness, hyperactivity, and impulsive behavior.</p>
    //                                     <p className="text-justify">While there are methods to help determine the presence of ADHD in an individual, the nature of the symptoms is sometimes responsible for a misdiagnosis of ADHD. Difficult behavior at school or being hyperactive can often be mistaken as a sign of ADHD in a child, while the actual cause may be due to external factors unrelated to ADHD. Children with certain learning disabilities affecting their attention can also be mistaken to have ADHD. In adults, having trouble focusing or being able to see tasks through to completion is also a sign of depression, anxiety, or a lack of sleep. However, because these behaviors are similar to symptoms of ADHD, there are chances of a misdiagnosis.</p>
    //                                     <p className="text-justify">A misdiagnosis of ADHD is also possible because people are unaware of the criteria for a correct diagnosis. Physicians and therapists will follow accepted methods to help determine the presence of ADHD in children. This may include counting the number of symptoms from each of the aforementioned categories observed in a child under the age of 7. However, in order for these symptoms to qualify as ADHD, they must have persisted for more than 6 months across multiple settings, whether at home, in school, or in public places.</p>
    //                                     <p className="text-justify">An ADHD misdiagnosis could have several negative effects on an individual. Treatment for the condition may involve drugs like Ritalin to help improve the ability of a person to focus on tasks and information. However, these drugs could have harmful side effects in children, which may make them unsafe for use unless there is an actual need. A misdiagnosis may also negatively influence a person’s perception of themselves, triggering problems with self-esteem.</p>
    //                                     <p className="text-justify">In order to avoid a misdiagnosis of ADHD, various medical organizations have developed strict guidelines to provide more clarity to the testing process. This includes conducting a psychological evaluation as well as a consulting a physician to rule out all external possibilities. This added thoroughness to the procedure lends more credibility to the diagnosis.</p>
    //                                 </div>
    //                                 {/* <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                                     <h2>Sources:</h2>
    //                                                     <ul>
    //                                                         <li><a href="http://www.nhs.uk/Conditions/Attention-deficit-hyperactivity-disorder/Pages/Diagnosis.aspx" target="_blank">http://www.nhs.uk/Conditions/Attention-deficit-hyperactivity-disorder/Pages/Diagnosis.aspx</a></li>
    //                                                         <li><a href="http://www.health.usnews.com/health-news/articles/2012/06/20/does-your-child-really-have-adhd" target="_blank">http://www.health.usnews.com/health-news/articles/2012/06/20/does-your-child-really-have-adhd</a></li>
    //                                                         <li><a href="http://www.mayoclinic.com/health/adhd/DS00275/DSECTION=tests-and-diagnosis" target="_blank">http://www.mayoclinic.com/health/adhd/DS00275/DSECTION=tests-and-diagnosis</a></li>
    //                                                         <li><a href="http://www.wausaupersonalinjurylawyer.com/articles/adhd-dangers-of-misdiagnosis" target="_blank">http://www.wausaupersonalinjurylawyer.com/articles/adhd-dangers-of-misdiagnosis</a></li>
    //                                                     </ul>
    //                                                 </div> */}
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/1.png" alt="Recent" /><NavLink to="/blogs/adhd-diagnosis">ADD Diagnosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/2.png" alt="Recent" /><NavLink to="/blogs/adhd-treatment">ADD Treatment</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 10 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/3.png" alt="Recent" /><NavLink to="/blogs/adult-adhd">Adult ADD</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 14 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "natural-remedies-for-add") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Natural Remedies for ADD | DHPFinder</title>
    //                 <meta name="description" content="Natural remedies for ADD may or may not produce similar results as conventional treatment options. Consult with your doctor before starting such treatment." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/5.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>November, 23 2023</span></label>
    //                                     <p className="text-justify">There is no permanent cure for adult attention deficit disorder (ADD). People looking for natural remedies for ADD should know that there is no conclusive medical evidence to show that they alleviate the various ADD symptoms. Furthermore, there are no natural cures for ADD as the mental condition is permanent, but symptoms may be controlled.</p>
    //                                     <p className="text-justify">Some people diagnosed with ADD may prefer a more natural treatment for ADD rather than following a more conventional regimen of medications and therapies. They may prefer this because they cannot take stimulants generally prescribed to ADD patients, or would like to avoid the various side effects associated with ADD medications.</p>
    //                                     <p className="text-justify">There is some thought that caffeine, especially in high doses, may be beneficial to both adults and children with ADD. However, as mentioned above, caffeine may be no match for conventional medications. Other alternatives to caffeine may be herbs like green oats, gotu kola, Siberian ginseng, brahmi, and ginkgo biloba. These herbs are thought to help restore the chemical imbalance in the brain which may be one of the causes of ADD. These herbs and their supposed benefits are discussed below:</p>
    //                                 </div>
    //                                 <div class="blog-details-previous">
    //                                     <ul>
    //                                         <li><b>1.</b> Green oats- works as a nerve tonic and has a stimulating effect over a period of time.</li>
    //                                         <li><b>2.</b> Gotu kola- stress is one of the symptoms of ADD. Gotu kola may reduce chemicals in the blood that are released during stress.</li>
    //                                         <li><b>3.</b> Siberian ginseng–this is linked to an increase in work output and brain activity.</li>
    //                                         <li><b>4.</b> Brahmi- is an antioxidant which may improve cognition and learning.</li>
    //                                         <li><b>5.</b> Ginkgo leaves also work as antioxidants and increase blood flow to the brain. They also increase glucose metabolism in the brain.</li>
    //                                     </ul>
    //                                     <br />
    //                                     <p className="text-justify">The use of Chinese herbs is another option thought to help relieve your ADD symptoms. A herbal regimen may help you bypass the side effects associated with various ADD drugs; however, ADD natural remedies may or may nor work effectively in all those diagnosed with ADD. Some natural remedies may also come with their own set of side effects. </p>
    //                                     <p className="text-justify">Therefore, it is advised that you consult with your doctor and/or dietician before opting for a more natural treatment to help control your ADD symptoms. Choose a treatment that suits you and with which you are comfortable.</p>
    //                                 </div>
    //                                 {/* <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                                         <h2>Sources:</h2>
    //                                                         <ul>
    //                                                             <li><a href="http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=lifestyle-and-home-remedies" target="_blank">http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=lifestyle-and-home-remedies</a></li>
    //                                                             <li><a href="http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=alternative-medicine" target="_blank">http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=alternative-medicine</a></li>
    //                                                             <li><a href="http://www.childmind.org/en/posts/ask-an-expert/2011-8-30-what-difference-between-add-and-adhd" target="_blank">http://www.childmind.org/en/posts/ask-an-expert/2011-8-30-what-difference-between-add-and-adhd</a></li>
    //                                                             <li><a href="http://www.healthline.com/health/add-adhd-attention-deficit-difference-between-add-adhd" target="_blank">http://www.healthline.com/health/add-adhd-attention-deficit-difference-between-add-adhd</a></li>
    //                                                             <li><a href="http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=treatments-and-drugs" target="_blank">http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=treatments-and-drugs</a></li>
    //                                                             <li><a href="http://www.naturalnews.com/026081_adhd_herbal_remedies.html" target="_blank">http://www.naturalnews.com/026081_adhd_herbal_remedies.html</a></li>
    //                                                             <li><a href="http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=treatments-and-drugs" target="_blank">http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=treatments-and-drugs</a></li>
    //                                                             <li><a href="http://www.adhd.org.nz/cause1.html" target="_blank">http://www.adhd.org.nz/cause1.html</a></li>
    //                                                         </ul>
    //                                                     </div> */}
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/1.png" alt="Recent" /><NavLink to="/blogs/adhd-diagnosis">ADD Diagnosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/2.png" alt="Recent" /><NavLink to="/blogs/adhd-treatment">ADD Treatment</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 10 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/3.png" alt="Recent" /><NavLink to="/blogs/adult-adhd">Adult ADD</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 14 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "natural-remedies-for-adhd") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Natural Remedies for ADHD | DHPFinder</title>
    //                 <meta name="description" content="There may be natural remedies for ADHD, but they may or may not prove to be as effective as conventional treatment. You should talk to your doctor first." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/32.jpg" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>November, 25 2023</span></label>
    //                                     <p className="text-justify">Natural remedies for ADHD may suit individuals who do not want to be conventionally treated with medications and therapy for their condition. People may have a number of reasons for deciding on a natural treatment option. However, it is worth noting that there is no conclusive medical evidence that shows that foregoing conventional treatments for natural treatments is going to be as effective or better. Therefore, it is advisable that if you are thinking of opting for natural ADHD treatment,you talk to your doctor in detail about the possible pros and cons of ADHD natural remedies. The following natural remedies are thought to help alleviate some ADHD symptoms:</p>
    //                                 </div>
    //                                 <div class="blog-details-previous">
    //                                     <ul>
    //                                         <li><b>1.</b> Yoga or meditation- a regular yoga or meditation routine may help you relax and learn discipline, in turn helping you deal positively with impulsivity and short-temperedness.</li>
    //                                         <li><b>2.</b> Herbal supplements- there is no conclusive evidence to support the assertion that taking herbal supplements will help alleviate your ADHD symptoms. Therefore, talk to your doctor before taking herbal supplements.</li>
    //                                         <li><b>3.</b> Special diet- the rule of the thumb is to eliminate foods that aggravate your ADHD symptoms. However, eliminating these foods may leave with you an unhealthy diet plan, which is why it is important that you talk to your doctor and a dietician before starting a limited diet.</li>
    //                                         <li><b>4.</b> Brain-healthy diet- good fats like omega-3 oils are good for maintaining the chemical balance in the brain, helping this vital organ to function properly.</li>
    //                                         <li><b>5.</b> Mineral or vitamin supplements- a right mineral and vitamin balance is necessary for a healthy mind and body. However, once again, it is still inconclusive whether mineral and vitamin supplements can help alleviate your ADHD symptoms.</li>
    //                                     </ul>
    //                                     <br />
    //                                     <p className="text-justify">All in all, choosing a more natural way of managing your ADHD symptoms may help you bypass some of the side effects associated with ADHD medications. However, the flip side of the option means that you are relying on a treatment which has not been conclusively shown to alleviate ADHD symptoms. It is always a good idea to talk to your doctor and a dietician before starting any natural or herbal treatment plans to manage your ADHD symptoms.</p>
    //                                 </div>
    //                                 {/* <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                                             <h2>Sources:</h2>
    //                                                             <ul>
    //                                                                 <li><a href="http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=lifestyle-and-home-remedies" target="_blank">http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=lifestyle-and-home-remedies</a></li>
    //                                                                 <li><a href="http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=alternative-medicine" target="_blank">http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=alternative-medicine</a></li>
    //                                                                 <li><a href="http://www.childmind.org/en/posts/ask-an-expert/2011-8-30-what-difference-between-add-and-adhd" target="_blank">http://www.childmind.org/en/posts/ask-an-expert/2011-8-30-what-difference-between-add-and-adhd</a></li>
    //                                                                 <li><a href="http://www.healthline.com/health/add-adhd-attention-deficit-difference-between-add-adhd" target="_blank">http://www.healthline.com/health/add-adhd-attention-deficit-difference-between-add-adhd</a></li>
    //                                                                 <li><a href="http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=treatments-and-drugs" target="_blank">http://www.mayoclinic.com/health/adult-adhd/DS01161/DSECTION=treatments-and-drugs</a></li>
    //                                                                 <li><a href="http://www.naturalnews.com/026081_adhd_herbal_remedies.html" target="_blank">http://www.naturalnews.com/026081_adhd_herbal_remedies.html</a></li>
    //                                                             </ul>
    //                                                         </div> */}
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/1.png" alt="Recent" /><NavLink to="/blogs/adhd-diagnosis">ADD Diagnosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/2.png" alt="Recent" /><NavLink to="/blogs/adhd-treatment">ADD Treatment</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 10 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/3.png" alt="Recent" /><NavLink to="/blogs/adult-adhd">Adult ADD</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 14 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "what-causes-adhd") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>What Causes ADHD | DHPFinder</title>
    //                 <meta name="description" content="Understanding what causes ADHD is difficult, but a number of factors have been thought to be triggers for its onset. Read on to learn more about the causes of ADHD." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/6.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>November, 27 2023</span></label>
    //                                     <p className="text-justify">Attention deficit disorder (ADD) is a chronic condition that impairs a person’s ability to pay sustained attention to simple tasks and increases hyperactivity and impulsive behaviors. The disorder is common among children, but it has the potential to keep affecting people into adulthood. Despite being the subject of widespread research and attention, scientists are still unsure of what causes ADD in an individual.</p>
    //                                     <p className="text-justify">Genetics is thought to be one of the causes of attention deficit disorder. Research has shown that a child with ADD is four times more likely to have a close relative that has been diagnosed with the same condition. It is possible that mutations in certain genes affect the chemical balance in the brain, causing symptoms of inattentiveness and hyperactivity to set in.</p>
    //                                     <p className="text-justify">Another one of the possible causes of ADD is trauma or injury to the brain, especially in children. Whether via physical injury or toxin exposure, the development of the brain in a child may be affected, causing them to display erratic behavior. However, there is still no strong evidence to substantiate this claim, continuing to leave the question of “What causes ADD?” to remain largely unanswered</p>
    //                                     <p className="text-justify">Exposure to harmful chemicals or metals like nicotine and lead, respectively, during early years of development is also thought to be a cause of ADD. Mothers who smoke during pregnancy run the risk of decreasing oxygen supply to the baby, which can severely impair normal development. As a result, the child is thought to be at a higher risk of developing ADHD</p>
    //                                     <p className="text-justify">The lack of verified evidence over what causes ADD has also led to some myths on the subject. Some people believe that poor parenting, a diet rich in sugar or caffeine, and even excess exposure to TV are potential causes. However, over the course of many years of research on each of these factors, it has been comprehensively proven that none of these can cause the onset of ADD in a child.</p>
    //                                     <p className="text-justify">Until we can thoroughly understand the factors causing ADD, scientists cannot develop an effective cure. This is why doctors and psychiatrists work closely with children and adults to develop strategies to help them manage their symptoms.</p>
    //                                 </div>
    //                                 {/* <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                                                 <h2>Sources:</h2>
    //                                                                 <ul>
    //                                                                     <li><a href="http://www.myadhd.com/causesofadhd.html" target="_blank">http://www.myadhd.com/causesofadhd.html</a></li>
    //                                                                     <li><a href="http://www.nimh.nih.gov/health/publications/attention-deficit-hyperactivity-disorder/complete-index.shtml" target="_blank">http://www.nimh.nih.gov/health/publications/attention-deficit-hyperactivity-disorder/complete-index.shtml</a></li>
    //                                                                     <li><a href="http://psychcentral.com/lib/2007/causes-of-attention-deficit-disorder-adhd/" target="_blank">http://psychcentral.com/lib/2007/causes-of-attention-deficit-disorder-adhd/</a></li>
    //                                                                     <li><a href="http://www.mayoclinic.com/health/adhd/DS00275" target="_blank">http://www.mayoclinic.com/health/adhd/DS00275</a></li>
    //                                                                 </ul>
    //                                                             </div> */}
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/1.png" alt="Recent" /><NavLink to="/blogs/adhd-diagnosis">ADD Diagnosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/2.png" alt="Recent" /><NavLink to="/blogs/adhd-treatment">ADD Treatment</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 10 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/3.png" alt="Recent" /><NavLink to="/blogs/adult-adhd">Adult ADD</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 14 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "adhd-symptoms") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>ADHD Symptoms | DHPFinder</title>
    //                 <meta name="description" content="People with attention deficit disorder display symptoms that may hinder their ability to learn or complete tasks. Read on for some ADHD symptoms." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/7.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>November, 29 2023</span></label>
    //                                     <p className="text-justify">Attention deficit disorder (ADD) is a chronic disorder that affects 5-8% of school children, and some of these children may have it for their entire life. The symptoms that a person suffering from ADD displays may negatively affect the person’s education, social interactions, and employment.</p>
    //                                     <p className="text-justify">The main symptoms of ADD are the inability to remain focused, impulsive behavior, and hyperactivity. Normally, ADD symptoms are first observed in early childhood. Although most children are restless, it may be ADD if the overactive or inattentive behavior becomes more persistent and keeps the child from functioning normally in school or at home.</p>
    //                                     <p className="text-justify">If the following symptoms continue and interfere with a child’s normal routine, you should get in touch with your doctor and discuss the possibility that your child may have ADD.</p>
    //                                     <h4>Attention Deficit Disorder Symptoms</h4>
    //                                     <ul>
    //                                         <li style={{ color: 'black' }}><b>Inattention</b></li>
    //                                         <ol>
    //                                             <li>Not listening properly</li>
    //                                             <br />
    //                                             <li>Not giving attention to details</li>
    //                                             <br />
    //                                             <li>Unable to remember things</li>
    //                                             <br />
    //                                             <li>Difficulty organizing tasks</li>
    //                                             <br />
    //                                             <li>Inability to complete tasks (school work or household chores)</li>
    //                                             <br />
    //                                             <li>Losing things frequently</li>
    //                                             <br />
    //                                             <li>Unable to follow instructions</li>
    //                                             <br />
    //                                             <li>Avoiding tasks demanding logical thinking and reasoning</li>
    //                                         </ol>
    //                                         <br />
    //                                         <li style={{ color: 'black' }}><b>Hyperactivity</b></li>
    //                                         <ol>
    //                                             <li>Excessive fidgeting</li>
    //                                             <br />
    //                                             <li>Excessive running around or climbing</li>
    //                                             <br />
    //                                             <li>Interrupting conversations or talking continuously</li>
    //                                             <br />
    //                                             <li>Having trouble sitting or standing still</li>
    //                                             <br />
    //                                             <li>Blurting answers without hearing the question</li>
    //                                         </ol>
    //                                         <br />
    //                                         <li style={{ color: 'black' }}><b>Impulsive behavior</b></li>
    //                                         <ol>
    //                                             <li>Impatient attitude</li>
    //                                             <br />
    //                                             <li>Not waiting for a turn</li>
    //                                             <br />
    //                                             <li>Acting without thinking</li>
    //                                         </ol>
    //                                     </ul>
    //                                     <br />
    //                                     <p className="text-justify">The extent of each type of symptoms determines the type of ADD a person is diagnosed with:</p>
    //                                     <ul>
    //                                         <li>Predominantly hyperactive-impulsive</li>
    //                                         <br />
    //                                         <li>Predominantly inattentive</li>
    //                                         <br />
    //                                         <li>Combined-hyperactive and inattentive</li>
    //                                     </ul>
    //                                 </div>
    //                                 {/* <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                                                     <h2>Sources:</h2>
    //                                                                     <ul>
    //                                                                         <li><a href="http://www.nimh.nih.gov/health/publications/attention-deficit-hyperactivity-disorder/what-are-the-symptoms-of-adhd-in-children.shtml" target="_blank">http://www.nimh.nih.gov/health/publications/attention-deficit-hyperactivity-disorder/what-are-the-symptoms-of-adhd-in-children.shtml</a></li>
    //                                                                         <li><a href="http://www.help4adhd.org/en/about/what/WWK1" target="_blank">http://www.help4adhd.org/en/about/what/WWK1</a></li>
    //                                                                         <li><a href="http://www.medicinenet.com/attention_deficit_hyperactivity_disorder_adhd/page2.htm#what_are_adhd_symptoms_and_signs" target="_blank">http://www.medicinenet.com/attention_deficit_hyperactivity_disorder_adhd/page2.htm#what_are_adhd_symptoms_and_signs</a></li>
    //                                                                     </ul>
    //                                                                 </div> */}
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/1.png" alt="Recent" /><NavLink to="/blogs/adhd-diagnosis">ADD Diagnosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/2.png" alt="Recent" /><NavLink to="/blogs/adhd-treatment">ADD Treatment</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 10 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/3.png" alt="Recent" /><NavLink to="/blogs/adult-adhd">Adult ADD</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 14 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "what-is-adhd") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>What is ADHD | DHPFinder</title>
    //                 <meta name="description" content="Attention deficit disorder makes it difficult for an individual to control their behavior. What is ADHD? Read on for more information, and how it can be treated." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/8.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>December, 1 2023</span></label>
    //                                     <p className="text-justify">What is ADD and how can you help your child if he/she starts to develop symptoms? Attention deficit disorder is a neurobiological disorder. It is a condition in which you are unable to concentrate, and your attention keeps drifting one thing to another. This results in impulsive or unmanageable behavior. The symptom of ADD can make it difficult to manage simple tasks and thus disrupt normal activities. Recently, ADD has been relabeled as ADHD, attention deficit hyperactivity disorder, in order to encompass all of the symptoms related to the disorder.</p>
    //                                     <h4>Types of Attention Deficit Disorders</h4>
    //                                     <p className="text-justify">The three main symptoms are inattentiveness, hyperactivity, and impulsive behavior. The signs and symptoms vary from case to case.</p>
    //                                     <ul>
    //                                         <li style={{ color: 'black' }}><b>There are three categories into which people with ADD/ADHD may be divided:</b></li>
    //                                         <ol>
    //                                             <li>1) Predominantly Inattentive: The most prominent symptom is the inability to hold attention for a long period of time. This makes it difficult for an individual to complete a task and he/she may keep forgetting things.</li>
    //                                             <br />
    //                                             <li>2) Predominantly Hyperactive-Impulsive: The dominant symptom is over activity in this case. An individual is unable to sit for long without fidgeting or may interrupt conversations. Impulsive behavior at times can be dangerous and may result in accidents or injury.</li>
    //                                             <br />
    //                                             <li>3) Having both conditions: This is the type in which an individual displays both symptoms equally.</li>
    //                                         </ol>
    //                                     </ul>
    //                                     <br />
    //                                     <h4>Coping with ADD</h4>
    //                                     <p className="text-justify">Although the disorder is not curable, there are treatment options available that have been continuously improving over the years. Managing the symptoms will make routine tasks more manageable for people with ADD and help to improve the quality of their life.</p>
    //                                     <p className="text-justify">Treating ADD/ADHD focuses on reducing its symptoms. A healthcare professional can help by giving appropriate medication, along with therapies or counseling. A combination of these can be used as advised by your doctor.</p>
    //                                     <p className="text-justify">Medication is used to control brain activity that is thought to trigger the symptoms. Such medicines may have a calming effect, help to reduce hyperactivity, and improve physical coordination.</p>
    //                                     <p className="text-justify">The therapies used may include behavior therapy, cognitive therapy or, psycho-therapy. These therapies help an individual to modify behavior, set goals, and develop learning skills that helps him/her manage daily routine tasks. These therapies are also helpful in improving a person’s self-esteem and coping with the emotional effects of living with this disorder. Family support also contributes to helping individuals become more independent and functional.</p>
    //                                 </div>
    //                                 {/* <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                                                         <h2>Sources:</h2>
    //                                                                         <ul>
    //                                                                             <li><a href="http://www.medicinenet.com/attention_deficit_hyperactivity_disorder_adhd/page6.htm#what_is_the_role_of_alternative_therapies_in_adhd" target="_blank">http://www.medicinenet.com/attention_deficit_hyperactivity_disorder_adhd/page6.htm#what_is_the_role_of_alternative_therapies_in_adhd</a></li>
    //                                                                             <li><a href="http://www.cdc.gov/ncbddd/adhd/facts.html" target="_blank">http://www.cdc.gov/ncbddd/adhd/facts.html</a></li>
    //                                                                             <li><a href="http://www.add.org/?page=ADHD_Fact_Sheet" target="_blank">http://www.add.org/?page=ADHD_Fact_Sheet</a></li>
    //                                                                         </ul>
    //                                                                     </div> */}
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/1.png" alt="Recent" /><NavLink to="/blogs/adhd-diagnosis">ADD Diagnosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/2.png" alt="Recent" /><NavLink to="/blogs/adhd-treatment">ADD Treatment</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 10 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/3.png" alt="Recent" /><NavLink to="/blogs/adult-adhd">Adult ADD</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 14 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "adhd-facts") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>ADHD Facts | DHPFinder</title>
    //                 <meta name="description" content="Attention deficit disorder (ADHD) is characterized by excessive impulsiveness, hyperactivity, and inattentiveness. Read some interesting ADHD facts here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/9.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>December, 3 2023</span></label>
    //                                     <h4>What is ADD?</h4>
    //                                     <ul>
    //                                         <li>1)Attention deficit disorder (ADD) is a behavioral disorder that usually begins in childhood.</li>
    //                                         <li>2)The most prominent symptoms may be impulsive behavior, inattention, or hyperactivity to the extent thatit interferes with normal activities.</li>
    //                                         <li>3)It is one of the most common chronic disorders in children.</li>
    //                                     </ul>
    //                                     <br />
    //                                     <h4>The Term ADD (Attention Deficit Disorder)</h4>
    //                                     <ul>
    //                                         <li>1)Attention deficit disorder (ADD), as the term explains is concerned with the lack of attention. Recently, it has been called ADHD (attention deficit hyperactivity disorder) to encompass all three symptoms.</li>
    //                                         <li>2)ADD was the term used in 1980s that excluded the word “hyperactivity”. The reason was thathyperactivity may or may not accompany restless behavior in this disorder.</li>
    //                                         <li>3)Despite renaming the illness, it does not mean that a child always displays hyperactivity symptoms. In fact, there are classifications of the disease based on which symptom may be more dominant than the other.</li>
    //                                     </ul>
    //                                     <h4>General Facts about ADD</h4>
    //                                     <ul>
    //                                         <li>1)ADD is more common in boys than girls</li>
    //                                         <li>2)According to the American Psychiatric Association, 3% to 7% of children have ADD</li>
    //                                         <li>3)It is not a disorder that only affects children</li>
    //                                         <li>4)More cases of ADD diagnosis are reported for teenagers as compared to children</li>
    //                                         <li>5)It has been observed that one-half to two-thirds of children with ADHD continue to experience problems coping with the disorder</li>
    //                                     </ul>
    //                                     <h4>Causes of ADD</h4>
    //                                     <ul>
    //                                         <li>1)The cause of the disease is not very clear.</li>
    //                                         <li>2)Studies suggest there may be a connection with genetics.</li>
    //                                         <li>3)It has been observed that there may be biological factors that influence neurotransmitter activity in the brain, leading to ADD.</li>
    //                                     </ul>
    //                                     <h4>Symptoms of ADD</h4>
    //                                     <ul>
    //                                         <li>1)The symptoms can be noticed at an early age.</li>
    //                                         <br />
    //                                         <li>2)Impulsive behavior</li>
    //                                         <br />
    //                                         <li>3)Inattentiveness or lack of concentration</li>
    //                                         <br />
    //                                         <li>4)Hyperactivity</li>
    //                                     </ul>
    //                                     <p className="text-justify">As a result of these symptoms, children may experience persistent learning, behavioral, social, or adjustment problems.</p>
    //                                     <h4>Diagnosis and Treatment Facts</h4>
    //                                     <ul>
    //                                         <li>1)There is no one particular test for the diagnosis of ADD.</li>
    //                                         <li>2)It is not curable, but treatment can make it more manageable.</li>
    //                                         <li>3)Treatment could include medication as prescribed by health care professionals along with therapy, counseling, or training to manage symptoms.</li>
    //                                     </ul>
    //                                 </div>
    //                                 {/* <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                                                             <h2>Sources:</h2>
    //                                                                             <ul>
    //                                                                                 <li><a href="http://www.medicinenet.com/attention_deficit_hyperactivity_disorder_adhd/article.htm#what_is_attention_deficit_hyperactivity_disorder_adhd" target="_blank">http://www.medicinenet.com/attention_deficit_hyperactivity_disorder_adhd/article.htm#what_is_attention_deficit_hyperactivity_disorder_adhd</a></li>
    //                                                                                 <li><a href="http://www.cdc.gov/ncbddd/adhd/facts.html" target="_blank">http://www.cdc.gov/ncbddd/adhd/facts.html</a></li>
    //                                                                                 <li><a href="http://nichcy.org/disability/specific/adhd#what" target="_blank">http://nichcy.org/disability/specific/adhd#what</a></li>
    //                                                                                 <li><a href="http://www.medicinenet.com/attention_deficit_hyperactivity_disorder_in_teens/article.htm" target="_blank">http://www.medicinenet.com/attention_deficit_hyperactivity_disorder_in_teens/article.htm</a></li>
    //                                                                                 <li><a href="http://www.cdc.gov/ncbddd/adhd/data.html" target="_blank">http://www.cdc.gov/ncbddd/adhd/data.html</a></li>
    //                                                                                 <li><a href="http://www.add.org/?page=ADHD_Fact_Sheet" target="_blank">http://www.add.org/?page=ADHD_Fact_Sheet</a></li>
    //                                                                             </ul>
    //                                                                         </div> */}
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/1.png" alt="Recent" /><NavLink to="/blogs/adhd-diagnosis">ADD Diagnosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/2.png" alt="Recent" /><NavLink to="/blogs/adhd-treatment">ADD Treatment</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 10 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/3.png" alt="Recent" /><NavLink to="/blogs/adult-adhd">Adult ADD</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> November, 14 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "cause-of-allergies") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Cause of Allergies | DHPFinder</title>
    //                 <meta name="description" content="The cause of allergies may be different in every person depending on what substance triggers an allergic reaction in their body. Read on for more information." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/10.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>December, 6 2023</span></label>
    //                                     <p className="text-justify">We often come across people who say they are ‘allergic’ to certain things. Some people may have an allergic reaction to foods like peanuts and fruits, while others may experience a runny nose and watery eyes when they come in contact with animals like cats. This only goes to show how the cause of allergies may be different in each person. However, all substances that trigger an allergic reaction are collectively referred to as allergens. </p>
    //                                     <br />
    //                                     <h4>The true cause of allergies</h4>
    //                                     <p className="text-justify">Allergens are proteins and molecules in the environment to which a human body may have an adverse reaction. In most cases, these substances are harmless, and are easily removed from the body of an individual. However, some people’s immune systems regard these as a threat and launch defensive mechanisms which manifest themselves as allergies. As a result, when they eat a certain food item or come into contact with a certain animal, they have an allergic reaction. A family history of an allergic reaction to certain substances may increase a person’s chances of developing the same allergy. An allergic reaction may be categorized by symptoms such as:</p>
    //                                     <div class="blog-details-previous">
    //                                         <ul>
    //                                             <li>1)Itching</li>
    //                                             <li>2)Inflammation and swelling in tissues</li>
    //                                             <li>3)Excess mucus and stuffiness in the nose</li>
    //                                             <li>4)Runny nose</li>
    //                                             <li>5)Watery eyes</li>
    //                                         </ul>
    //                                         <p className="text-justify">If the allergic reaction is severe and overwhelming, it may be referred to as anaphylaxis. If timely treatment is not sought, this can also be fatal</p>
    //                                         <p className="text-justify">Individuals with known allergies must take great care to avoid things that cause an allergic reaction in their bodies. This includes scrutinizing labels on food items to ensure that no allergen is present in it. Some individuals also carry an injection of adrenaline or epinephrine with them at all times; adrenaline is a hormone in the body that counters the effects of an allergic reaction. If you experience any of the aforementioned symptoms on a frequent basis, you should consult a doctor to find out if you are allergic to something.</p>
    //                                         <p className="text-justify">While there is no absolute ‘cure’ for allergies, medicines, like antihistamines, are available to help soothe the inflammation and other symptoms. Your doctor may prescribe you supplementary vitamin pills to boost your levels of vitamin C and E and strengthen your immune system. They may also recommend a decongestant to help reduce the stuffiness in your sinuses if that is one of the symptoms of your allergies.</p>
    //                                     </div>
    //                                 </div>
    //                                 {/* <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                                                                 <h2>Sources:</h2>
    //                                                                                 <ul>
    //                                                                                     <li><a href="http://www.medicinenet.com/allergy/page2.htm" target="_blank">http://www.medicinenet.com/allergy/page2.htm</a></li>
    //                                                                                     <li><a href="http://www.nhs.uk/Conditions/Allergies/Pages/Causes.aspx" target="_blank">http://www.nhs.uk/Conditions/Allergies/Pages/Causes.aspx</a></li>
    //                                                                                     <li><a href="http://www.aafa.org/display.cfm?id=9&cont=79" target="_blank">http://www.aafa.org/display.cfm?id=9&cont=79</a></li>
    //                                                                                     <li><a href="http://www.bbc.co.uk/health/physical_health/conditions/in_depth/allergies/aboutallergies_what.shtml" target="_blank">http://www.bbc.co.uk/health/physical_health/conditions/in_depth/allergies/aboutallergies_what.shtml</a></li>
    //                                                                                 </ul>
    //                                                                             </div> */}
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/11.png" alt="Recent" /><NavLink to="/blogs/coughing-and-allergies">Coughing and Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 8 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/12.png" alt="Recent" /><NavLink to="/blogs/fruit-allergies">Fruit Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 11 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/13.png" alt="Recent" /><NavLink to="/blogs/children-allergy-symptoms">Children Allergy Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 15 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "coughing-and-allergies") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Coughing and Allergies | DHPFinder</title>
    //                 <meta name="description" content="Coughing and allergies often go hand in hand, causing great discomfort to a person. What triggers this coughing, and how can you treat it? Read on to find out." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/11.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>December, 8 2023</span></label>
    //                                     <p className="text-justify">Coughing is the body’s natural reflex when the airways are irritated by a chemical or a foreign object. This is why we cough when there is too much dust or pollen in the air, or if we accidentally choke on our food. Several illnesses may also cause coughing such as the simple flu or asthma. In order to understand the link between coughing and allergies though, we need to know a little more about allergies themselves.</p>
    //                                     <p className="text-justify">Allergies are adverse reactions caused when a body is exposed to proteins called allergens. Normally harmless, these allergens induce an allergic reaction in individuals whose immune systems are highly sensitized. The enhanced immune response against these allergens results in inflammation in the body, excess mucus production, and constriction of the muscles in the airways. This may cause difficulty in breathing, wheezing, and more often than not, a persistent cough.</p>
    //                                     <p className="text-justify">While allergies and coughing are related, you cannot tell if you have an allergy simply from the ‘type’ of cough you have. Other symptoms of allergic reactions offer a clearer diagnosis. Allergies tend to induce a cough that persists for 2 to 3 weeks, known as an acute cough. If the allergies go untreated, this cough can become progressively worse and is referred to as sub-acute coughing if it persists for as long as 3 to 8 weeks. Consulting a doctor immediately can not only help you identify the source of your coughing, but also get timely treatment for the allergy.</p>
    //                                     <br />
    //                                     <h4>Treating Allergies and Coughing</h4>
    //                                     <p className="text-justify">Three kinds of medicines are typically used to soothe coughing brought about by an allergic reaction. These are:</p>
    //                                     <div class="blog-details-previous">
    //                                         <ul>
    //                                             <li>1)Oral cough suppressants</li>
    //                                             <li>2)Oral expectorants</li>
    //                                             <li>3)Topical medications</li>
    //                                         </ul>
    //                                         <p className="text-justify">Oral cough suppressants work by acting on the brain to suppress activity that is causing a cough. These can only be obtained through a doctor’s prescription. While they are useful in reducing the frequency of coughing, they are not very effective at alleviating pain. Topical medications like camphor and menthol have anesthetic properties that are able to reduce the pain and the inflammation in the airways caused as a result of coughing too much. Cough expectorants work by clearing the mucus out of the airways, which help to reduce coughing and ease breathing.</p>
    //                                     </div>
    //                                 </div>
    //                                 {/* <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                                                                     <h2>Sources:</h2>
    //                                                                                     <ul>
    //                                                                                         <li><a href="http://www.aanma.org/2009/02/calming-your-cough/" target="_blank">http://www.aanma.org/2009/02/calming-your-cough/</a></li>
    //                                                                                         <li><a href="http://allergies.about.com/od/childhoodallergies/a/atopicmarch.htm" target="_blank">http://allergies.about.com/od/childhoodallergies/a/atopicmarch.htm</a></li>
    //                                                                                         <li><a href="http://www.medicinenet.com/cold_flu_allergy/page5.htm" target="_blank">http://www.medicinenet.com/cold_flu_allergy/page5.htm</a></li>
    //                                                                                     </ul>
    //                                                                                 </div> */}
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/10.png" alt="Recent" /><NavLink to="/blogs/cause-of-allergies">Cause of Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/12.png" alt="Recent" /><NavLink to="/blogs/fruit-allergies">Fruit Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 11 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/13.png" alt="Recent" /><NavLink to="/blogs/children-allergy-symptoms">Children Allergy Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 15 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "fruit-allergies") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Fruit Allergy | DHPFinder</title>
    //                 <meta name="description" content="Although not as common as some of the other allergies, people may develop a fruit allergy. What causes this, and what are its symptoms? Read on to find out." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/12.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>December, 11 2023</span></label>
    //                                     <p className="text-justify">Unlike allergies to nuts and milk or pollen, allergies to fruits are relatively uncommon. Nonetheless, having a fruit allergy can make life difficult. Individuals who have this type of allergy need to exercise extra caution to avoid any form of the fruit they are allergic to in their diet. Exposure to the allergen causes one or more of the following fruit allergy symptoms to arise:</p>
    //                                     <div class="blog-details-previous">
    //                                         <ul>
    //                                             <li>1)Hives or swelling in the mouth or parts of the body that the fruit has come in contact with</li>
    //                                             <li>2)Abdominal pain</li>
    //                                             <li>3)Vomiting</li>
    //                                             <li>4)Itchy or tingly feeling in the lips and tongue</li>
    //                                         </ul>
    //                                         <p className="text-justify">The most common kind of fruit allergy is towards apples or strawberries. Other fruits, like pears, cherries, or plums are also on the list though. All fruits and plants contain particular proteins called profilins which are thought to trigger an allergic reaction in susceptible individuals. When an individual with a strawberry allergy, for instance, eats or touches the fruit, the immune system mounts a very strong response against the profilins in the fruit. This causes the individual to break out in hives and experience the symptoms mentioned above.</p>
    //                                         <p className="text-justify">The simplest way to determine whether you have an allergy to a particular fruit is to consult a doctor who could conduct a simple skin prick test. This involves a small amount of the fresh fruit extract being inserted intra-dermal with a needle and then observing the site for any swelling. Alternatively testing for the presence of IgE antibodies in response to a skin prick is another way to diagnose fruit allergies.</p>
    //                                     </div>
    //                                     <h4>Dealing with Fruit Allergies</h4>
    //                                     <p className="text-justify">While it is hard to eliminate an allergy, you can prevent an allergic reaction from occurring by following a few simple steps. For starters, make sure you read the labels on all the processed food items you purchase. Companies are required by law to report the presence of allergens in their products, and these should be visible on the packaging. Carrying an epinephrine/adrenaline injector device with you is also a good idea. Adrenaline is a chemical produced by the body to reduce the inflammatory response of allergic reactions. In case you come into accidental contact with the fruit you are allergic to, adrenaline will help you avoid anaphylaxis or extreme allergic response, which can be fatal.</p>
    //                                 </div>
    //                                 {/* <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                                                                         <h2>Sources:</h2>
    //                                                                                         <ul>
    //                                                                                             <li><a href="http://health.usnews.com/health-news/articles/2007/12/11/a-pesky-allergy-to-fruit-and-vegetables" target="_blank">http://health.usnews.com/health-news/articles/2007/12/11/a-pesky-allergy-to-fruit-and-vegetables</a></li>
    //                                                                                             <li><a href="http://www.allergyuk.org/allergy-to-fruit-and-vegetables/allergy-to-fruit-and-vegetables" target="_blank">http://www.allergyuk.org/allergy-to-fruit-and-vegetables/allergy-to-fruit-and-vegetables</a></li>
    //                                                                                             <li><a href="http://www.sch.edu.au/health/factsheets/joint/?fruit_and_vegetable_allergy.htm" target="_blank">http://www.sch.edu.au/health/factsheets/joint/?fruit_and_vegetable_allergy.htm</a></li>
    //                                                                                             <li><a href="http://www.hc-sc.gc.ca/fn-an/securit/kitchen-cuisine/allerg-eng.php" target="_blank">http://www.hc-sc.gc.ca/fn-an/securit/kitchen-cuisine/allerg-eng.php</a></li>
    //                                                                                         </ul>
    //                                                                                     </div> */}
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/10.png" alt="Recent" /><NavLink to="/blogs/cause-of-allergies">Cause of Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/11.png" alt="Recent" /><NavLink to="/blogs/coughing-and-allergies">Coughing and Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 11 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/13.png" alt="Recent" /><NavLink to="/blogs/children-allergy-symptoms">Children Allergy Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 15 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "children-allergy-symptoms") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Child Allergy Symptoms | DHPFinder</title>
    //                 <meta name="description" content="By properly identifying children allergy symptoms, you can pinpoint your child’s allergy and start treatment in a timely manner. Click here for more details." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/13.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>December, 15 2023</span></label>
    //                                     <p className="text-justify">Allergies are very common in children. Allergy symptoms may depend on what is causing their allergy and what part of the body it affects. Children may grow out of their allergies, but some allergies, particularly some food allergies, may remain with them for life. If this is the case, then these children will always have to be careful about what they eat.</p>
    //                                     <h4>Various Child Allergy Symptoms</h4>
    //                                     <p className="text-justify"><b>Contact Dermatitis</b> – This allergic reaction is triggered by coming into contact with various substances such as a food item, soaps and shampoos, or secretions from plants like poison ivy or poison sumac. Symptoms usually surface after 48 hours of exposure, and include an initial rash around the affected area. The rash may be followed by reddish bumps, swelling, skin redness, and large blisters in some cases. This is a temporary condition that will usually clear up in a week if no more contact is made with the irritant.</p>
    //                                     <p className="text-justify"><b>Wheat Allergy</b> – This kind of allergy has become quite common since the late 20th century and causes a number of symptoms in children. These include swelling, irritation or itchiness around the mouth and throat, a watery discharge from the eyes, constant itching, difficulty while breathing, a congested or blocked nose, diarrhea, and nausea. Vomiting and cramps may also be experienced.</p>
    //                                     <p className="text-justify"><b>Milk Allergy</b> – Milk allergies usually appear in children up to the age of three. Your child may be displaying symptoms of milk allergy if he/she is undergoing bouts of sneezing, vomiting, wheezing, is having difficulty in breathing, or has hives. Furthermore, if your child has an allergic reaction to milk, he or she could develop other symptoms such as diarrhea, loose stools containing blood, runny and watery nose and eyes, and itchy skin near the mouth area. Children who have been breast fed have a lesser chance of developing milk allergy.</p>
    //                                     <p className="text-justify"><b>Allergic Asthma</b> – Being afflicted with allergy-induced asthma is a condition that many children face as a result of exposure to certain allergens. Childhood allergic asthma usually has symptoms that include a frequent and persistent cough, experiencing congestion in the chest area as well as noticeable tightness and pain, shortness of breath, and difficulty during breathing.</p>
    //                                 </div>
    //                                 {/* <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                                                                             <h2>Sources:</h2>
    //                                                                                             <ul>
    //                                                                                                 <li><a href="http://allergies.about.com/od/childhoodallergies/a/atopicmarch.htm" target="_blank">http://allergies.about.com/od/childhoodallergies/a/atopicmarch.htm</a></li>
    //                                                                                                 <li><a href="http://www.medicinenet.com/skin_pictures_child_pictures_slideshow/article.htm" target="_blank">http://www.medicinenet.com/skin_pictures_child_pictures_slideshow/article.htm</a></li>
    //                                                                                                 <li><a href="http://www.mayoclinic.com/health/wheat-allergy/DS01002/DSECTION=symptoms" target="_blank">http://www.mayoclinic.com/health/wheat-allergy/DS01002/DSECTION=symptoms</a></li>
    //                                                                                                 <li><a href="http://news.bbc.co.uk/2/hi/health/455936.stm" target="_blank">http://news.bbc.co.uk/2/hi/health/455936.stm</a></li>
    //                                                                                                 <li><a href="http://www.mayoclinic.com/health/allergies-and-asthma/AA00045" target="_blank">http://www.mayoclinic.com/health/allergies-and-asthma/AA00045</a></li>
    //                                                                                                 <li><a href="http://www.mayoclinic.com/health/contact-dermatitis/DS00985/DSECTION=symptoms" target="_blank">http://www.mayoclinic.com/health/contact-dermatitis/DS00985/DSECTION=symptoms</a></li>
    //                                                                                             </ul>
    //                                                                                         </div> */}
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/10.png" alt="Recent" /><NavLink to="/blogs/cause-of-allergies">Cause of Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/11.png" alt="Recent" /><NavLink to="/blogs/coughing-and-allergies">Coughing and Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 8 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/12.png" alt="Recent" /><NavLink to="/blogs/fruit-allergies">Fruit Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 11 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "pollen-allergies") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Pollen Allergies | DHPFinder</title>
    //                 <meta name="description" content="As the seasons change, pollen allergies may flare up. If you suffer from this allergy, click here to read more about its symptoms and treatment." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/14.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>December, 18 2023</span></label>
    //                                     <p className="text-justify">Many people welcome seasonal changes for a variety of reasons, but these changes may also bring with a horde of unwanted pollen allergies. Pollen is typically released in the spring and fall by trees, flowering plants, weeds, and grasses for the purpose of germination. However, many people are sensitive to these tiny bits of plant DNA and have an allergic reaction to them.</p>
    //                                     <p className="text-justify">The medical term used for pollen allergies is seasonal allergic rhinitis. This may be commonly referred to as hay fever or rose fever, depending on the season a person develops it.</p>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Allergy to Pollen</h4>
    //                                     <p className="text-justify">Pollen is basically a small grainy substance that is developed by plants when they are going through their reproductive phase. The male parts of the plant release the pollen to fertilize the female parts, usually the flowers, so that the plant can bear fruits which contain the seeds to create new plants. Pollen is typically carried away by insects and birds that land on plants to eat fruit or suck nectar from the flowers. Pollen is also disturbed and blown away by wind which increases its concentration in the air. It is from here that it enters nasal passageways of people and triggers allergic reactions. Pollen count in the air is highest in early morning before 10 am. Common symptoms associated with pollen allergies include:</p>
    //                                     <ul>
    //                                         <li>1)Congestion of nasal passages</li>
    //                                         <li>2)Sneezing</li>
    //                                         <li>3)Watery but clear discharge from nose</li>
    //                                         <li>4)Watery eyes</li>
    //                                     </ul>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <p className="text-justify">The following medicines are most commonly employed for treating pollen allergies:</p>
    //                                     <ul>
    //                                         <li>1)Antihistamines</li>
    //                                         <li>2)Decongestants</li>
    //                                         <li>3)Topical nasal steroids</li>
    //                                         <li>4)Cromolyn sodium</li>
    //                                     </ul>
    //                                 </div>
    //                                 <h4>High Impact Regions with Allergies to Pollen</h4>
    //                                 <p className="text-justify">Pollen, mostly from dandelions, sunflowers, and roses, is identified as the usual culprit behind pollen allergies. Pollen allergies are most prominent in the south, especially in states such as Arizona, Texas, New Mexico, Colorado, parts of California, Kansas, Oklahoma, Nevada, and Utah.</p>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/10.png" alt="Recent" /><NavLink to="/blogs/cause-of-allergies">Cause of Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/11.png" alt="Recent" /><NavLink to="/blogs/coughing-and-allergies">Coughing and Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 8 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/12.png" alt="Recent" /><NavLink to="/blogs/fruit-allergies">Fruit Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 11 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "skin-allergy-treatment") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Skin Allergy Treatment | DHPFinder</title>
    //                 <meta name="description" content="Skin allergies are not only uncomfortable but may also lead to social alienation. Read on to see the various skin allergy treatment methods available." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/15.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>December, 21 2023</span></label>
    //                                     <h4>Skin Allergy Treatment</h4>
    //                                     <p className="text-justify">Skin allergies may drive you crazy with their flare-ups.  These are usually in the form of rashes; burning and peeling skin; and swelling in the hands, face, feet, and neck. Moreover, blisters, boils, and discoloration may lead towards social embarrassment and alienation in extreme cases; especially when a potentially harmless allergic reaction is considered contagious by other people. However, there is no cause for alarm as there are plenty of skin allergy treatment plans available which have been remarkably effective for people with similar complications.</p>
    //                                     <h4>Skin Allergies Treatment through Prevention</h4>
    //                                     <p className="text-justify">If you have a skin allergy, try to identify the substance that is most likely triggering it when you either consume it or come into contact with it. Some allergens may be various substances in detergents, cleaning solutions, industrial chemicals, or airborne irritants and pollutants that may be causing flare ups. Therefore, avoiding ingestion or contact with the allergen is very effective in minimizing the chances of your skin allergies to flare up.</p>
    //                                     <h4>Conventional Treatments for Skin Allergies</h4>
    //                                     <p className="text-justify">Doctors may prescribe various hydrocortisone and steroidal creams to alleviate the symptoms of eczema and dermatitis caused as a result of an allergy. Where they make the bothersome symptoms disappear in the short run, sometimes a prolonged use of steroid based treatments can result in more severe allergic reactions and even liver damage in some cases.</p>
    //                                     <p className="text-justify">Many people may also be given oral antihistamines to relieve the inflammation and itchiness caused by an allergic reaction. These are very effective and are widely prescribed for the treatment of hives although very severe flare ups may need to be controlled by corticosteroid lotions.</p>
    //                                     <h4>Natural and Home Skin Allergy Treatments</h4>
    //                                     <p className="text-justify">There are several alternative treatments for skin allergies that have been shown to be quite successful in various cases. However, as with all natural treatments, caution has to be exercised and the remedies should only be applied after acquiring sufficient information as well as consulting a licensed and qualified alternative health practitioner. Cold compresses are a common home treatment and may alleviate the discomfort caused by an allergic reaction in the skin. Besides this, some people may also recommend the use of certain herbs to alleviate the symptoms of an allergy. Most of these treatments are symptomatic which means they simply relieve uncomfortable symptoms of an allergy.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/10.png" alt="Recent" /><NavLink to="/blogs/cause-of-allergies">Cause of Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/11.png" alt="Recent" /><NavLink to="/blogs/coughing-and-allergies">Coughing and Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 8 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/12.png" alt="Recent" /><NavLink to="/blogs/fruit-allergies">Fruit Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 11 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "nasal-allergies") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Nasal Allergy Symptoms | DHPFinder</title>
    //                 <meta name="description" content="Identifying nasal allergy symptoms can help you distinguish an allergic reaction from a bout of flu, and find the right treatment. Read on to learn more." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/16.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>December, 23 2023</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Allergy to Pollen</h4>
    //                                     <p className="text-justify">Nasal allergy, or allergic rhinitis as it is medically known, is a condition caused by inhaling pollen or dust in individuals who are susceptible to an allergic reaction from these substances. While dust and pollen are harmless by themselves, the immune system of an allergic individual considers them harmful, and has a much stronger response to them. This causes classic nasal allergy symptoms, such as:</p>
    //                                     <ul>
    //                                         <li>Runny nose</li>
    //                                         <li>Watering of the eyes</li>
    //                                         <li>Nasal congestion</li>
    //                                         <li>Headaches</li>
    //                                         <li>Clogged ears and impaired sense of smell</li>
    //                                     </ul>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <p className="text-justify">While different individuals may exhibit the same nasal allergy symptoms, the causes of their allergy do not have to be the same. Some people are more allergic to pollen and experience seasonal allergies like hay fever in the spring. Others are allergic to cat dander and can have an allergic reaction by simply entering a room that the animal has been in. Left untreated, all the symptoms of nasal allergies can become a nuisance, preventing a person from carrying out his/her daily activities.</p>
    //                                     <h4>Treating Nasal Allergy</h4>
    //                                     <p className="text-justify">There are several options available to help alleviate the symptoms of a nasal allergy. Choosing from these treatment options depends on the type and severity of allergic symptoms. Doctors also factor in other medical conditions, like the presence of asthma, before prescribing these drugs. Broadly classified, these medicines fall into one or more of the following categories:</p>
    //                                     <ul>
    //                                         <li>Antihistamines</li>
    //                                         <li>Decongestants</li>
    //                                         <li>Corticosteroids</li>
    //                                     </ul>
    //                                 </div>
    //                                 <p className="text-justify">Antihistamines are essentially anti-inflammatory medicines. They work by counteracting the effects of histamine, the chemical in the body responsible for causing swelling. They are mostly available as pills taken orally, but can also be found in the form of nasal sprays for quicker and easier administration. While effective, they are also known to cause some drowsiness and are only useful for symptoms that are not persistent.</p>
    //                                 <p className="text-justify">Decongestants, on the other hand, target the stuffiness in your nose by removing the excess mucus. They are extremely useful in opening up your sinuses and help you regain your sense of smell, but are not advised for use over a long period of time. Corticosteroids are considered the best treatment for both short and long term nasal allergy symptoms. Moreover, nasal corticosteroids are safe for both adults and children.</p>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/10.png" alt="Recent" /><NavLink to="/blogs/cause-of-allergies">Cause of Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/11.png" alt="Recent" /><NavLink to="/blogs/coughing-and-allergies">Coughing and Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 8 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/12.png" alt="Recent" /><NavLink to="/blogs/fruit-allergies">Fruit Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 11 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "allergy-treatment") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Allergy Treatment | DHPFinder</title>
    //                 <meta name="description" content="" />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/17.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>December, 25 2023</span></label>
    //                                     <h4>Allergy Treatment</h4>
    //                                     <p className="text-justify">While there is no cure for allergies, the symptoms can be treated to ease the discomfort and provide relief to an affected person. Understanding an allergy is very important when choosing an effective allergy treatment.</p>
    //                                     <h4>Selecting a Treatment</h4>
    //                                     <p className="text-justify">Selecting allergy treatments demands a fair understanding of what is causing the allergy and how it is impacting the body. The most important things to consider when selecting treatment include the severity of an allergic reaction a person has to a particular allergen and the frequency with which a person develops these symptoms. Some other things to consider are a person’s known reactions to certain medicines, their age, presence of other conditions (like asthma), and their preference for different types of treatment.</p>
    //                                     <h4>Allergy Treatment Options</h4>
    //                                     <p className="text-justify">If you suffer from allergies, treatment may save you from a lot of discomfort. After a doctor has tested you for an allergy and taken into account your personal experience and preferences, a treatment path will be recommended for you by the doctor. This will focus on both prevention and medication. Prevention is important because it stops an allergic reaction from occurring in the first place. This includes techniques like avoiding a certain food you know causes an allergic reaction in your body, wearing a face mask when stepping outside in the spring if you are allergic to pollen and reducing mold in your surroundings, among other things. Although prevention may reduce the incidence of allergic reactions, exposure may still occur. In this case, an allergist may prescribe medication to a patient. These commonly include antihistamines and other anti-inflammatory drugs. Some people may also be prescribed nasal sprays or nasal washes to provide them with symptomatic relief from a congested breathing passage.</p>
    //                                     <p className="text-justify">If both preventive techniques and medication are ineffective, a person may be given allergy shots to counter the effects of an allergen on the body. This treatment option is known as immunotherapy. These shots reduce a person’s sensitivity to certain allergens that may have previously been causing severe reactions in a person. Purified allergen is injected in a person’s body in small amounts. This causes the body to develop immunity against it so that when a person is exposed to it, a reaction does not occur. Allergy shots are always given under medical supervision because of the risk of a severe reaction from these.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/10.png" alt="Recent" /><NavLink to="/blogs/cause-of-allergies">Cause of Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/11.png" alt="Recent" /><NavLink to="/blogs/coughing-and-allergies">Coughing and Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 8 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/12.png" alt="Recent" /><NavLink to="/blogs/fruit-allergies">Fruit Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 11 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "types-of-allergic-reactions") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Types of Allergic Reactions | DHPFinder</title>
    //                 <meta name="description" content="Different people may have a positive response to an allergy treatment plan. Read on about how to prevent allergies and how various treatment plans can help." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/18.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>December, 27 2023</span></label>
    //                                     <h4>Types of Allergic Reactions</h4>
    //                                     <p className="text-justify">When a person is exposed to an allergen, he/she may have different types of allergic reactions as a response. Most reactions to an allergen may cause mild symptoms, but these reactions could be severe and cause anaphylaxis or an anaphylactic shock. Allergy medication or allergy shots may be used to counter the effects of an allergy a person may have. However, some people may also experience certain allergy shot reactions. While a little redness or a rash may be a harmless reaction to an allergy shot, a person could develop anaphylaxis and immediate medical help would be required.</p>
    //                                     <p className="text-justify">An allergic reaction often occurs immediately after exposure to an allergen – within thirty minutes. While this is especially true of severe allergic reactions, an allergen hardly causes a reaction more than 24 hours after exposure. Some of the common reactions attributed to allergies are discussed below.</p>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Mild Allergic Reactions</h4>
    //                                     <p className="text-justify">Some of the symptoms associated with a mild allergic reaction include hives, rashes, a stuffy nose, watery eyes, and itching. These symptoms often go away when a person is no longer exposed to an allergen.</p>
    //                                     <h4>Moderate Allergic Reactions</h4>
    //                                     <p className="text-justify">People who have a moderate allergic reaction to something may display the following symptoms:</p>
    //                                     <ul>
    //                                         <li>Diarrhea</li>
    //                                         <li>Coughing</li>
    //                                         <li>Abdominal pain</li>
    //                                         <li>Anxiety</li>
    //                                         <li>Tightness in chest</li>
    //                                         <li>Nausea and/or vomiting</li>
    //                                         <li>Palpitations</li>
    //                                     </ul>
    //                                     <p className="text-justify">Reduced exposure to the allergen and an anti-inflammatory or allergy medication may be effective in reducing these symptoms.</p>
    //                                 </div>
    //                                 <h4>Severe Allergic Reaction: Anaphylaxis</h4>
    //                                 <p className="text-justify">The most severe allergic reaction is referred to as anaphylaxis or an anaphylactic shock. This often occurs very quickly after exposure to an allergen, and if not controlled immediately, it may lead to death. More often than not, the quicker a body reacts to an allergen, the more severe the symptoms will be that a person will experience.</p>
    //                                 <p className="text-justify">People who develop anaphylaxis have difficulty in breathing, have severe swelling in their throat which may make swallowing or speaking very difficult, and may experience a reduction in blood pressure to the point where they may lose consciousness. If a person is displaying signs and symptoms of an anaphylactic shock, they need immediate medical help. Since allergy shots may also cause a reaction in some people, people are advised to wait in a physician’s office for at least 30 minutes after receiving a shot. In this way, medical help is immediately available in case of an anaphylactic response to the shot.</p>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/10.png" alt="Recent" /><NavLink to="/blogs/cause-of-allergies">Cause of Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/11.png" alt="Recent" /><NavLink to="/blogs/coughing-and-allergies">Coughing and Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 8 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/12.png" alt="Recent" /><NavLink to="/blogs/fruit-allergies">Fruit Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 11 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "types-of-allergy") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Types of Allergy | DHPFinder</title>
    //                 <meta name="description" content="The most common types of allergy are from food, pollen, and dust. Several other materials may also cause allergic reactions though. Read on for more details." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/31.jpg" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>December, 29 2023</span></label>
    //                                     <h4>Types of Allergy</h4>
    //                                     <p className="text-justify">An allergy is a reaction by the body when it mistakes a harmless foreign object as a pathogen and produces antibodies. This causes inflammation which may cause symptoms like coughing, sneezing, hives, or even swelling. Any substance that causes an allergy is known as an ‘allergen’. Allergens can be substances in the food we eat, air we breathe, and also certain chemicals injected into our bodies such as medication. Different people experience different types of allergy from allergens. The severity of an allergic reaction also differs from person to person. </p>
    //                                     <h4>Common Allergies</h4>
    //                                     <p className="text-justify">The most common allergies are food and pollen allergies. Other types of allergies are due to insect bites, pets, and drugs. Different people may be allergic to different food items. Peanuts are perhaps the most talked about food that causes allergic reactions. Other food items commonly known for causing an allergic reaction include milk, eggs, wheat, fish, shell fish, and food additives. Food allergies are mainly attributed to the proteins present in a particular food item. While most of these allergies are experienced by children who may grow out of them, some people may develop a lifelong problem with certain foods.</p>
    //                                     <p className="text-justify">Pollen is also one of the most common allergens. A lot of people may be seen sneezing and suffering from what is known as ‘hay fever’ during the spring when the air pollen count is high. Pollen from grasses, ragweed, and trees is most well known for causing hay fever. Other allergens in the air include dust, animal dander, and mold.</p>
    //                                     <p className="text-justify">Allergies may also be caused by insect bites. Two insects that are notorious for causing an allergic reaction are wasps and fire ants. Their sting can cause pain, rashes, and swelling. Many people are also allergic to certain drugs and vaccinations. While vaccine allergies are not as common, they may cause a very severe allergic reaction if they do occur, which may even prove to be fatal if not effectively controlled.</p>
    //                                     <p className="text-justify">While these are some of the common types of allergies, some people may develop allergies to rare things as well. In case you have the sniffles, a headache, or any of the symptoms commonly associated with allergies, you may want to contact your physician immediately who can prescribe an allergy medication to you to help control your symptoms.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/10.png" alt="Recent" /><NavLink to="/blogs/cause-of-allergies">Cause of Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 6 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/11.png" alt="Recent" /><NavLink to="/blogs/coughing-and-allergies">Coughing and Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 8 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/12.png" alt="Recent" /><NavLink to="/blogs/fruit-allergies">Fruit Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 11 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "dairy-allergies-symptoms") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Dairy Allergies Symptoms | DHPFinder</title>
    //                 <meta name="description" content="Dairy allergies and symptoms - read on to get more information about what these allergies are and what symptoms you should be aware of." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>Dairy Allergies Symptoms</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Dairy Allergies Symptoms.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 02 2024</span></label>
    //                                     <h4>Dairy Allergies - Symptoms</h4>
    //                                     <p className="text-justify">Contrary to a popular belief, dairy allergies are different from dairy intolerance (or lactose intolerance).  While the latter is caused by a condition where the body does not produce the enzyme lactase, the former occurs when the body’s natural immune system attacks milk proteins as foreign antibodies resulting in an allergic reaction. The most common type of milk protein that may provoke an antibody response is casein.</p>
    //                                     <p className="text-justify">Common symptoms of dairy allergies are ear infections (dairy allergies are said to be one of the leading causes of persistent ear infections in children), sinusitis (i.e. inflammation of the sinuses), hives, skin rash, irritable bowel syndrome, indigestion, constipation or diarrhea, heartburn, severe indigestion, flatulence, joint pains (leading to arthritis), generally poor growth in children, iron deficiency and at times anemia, canker sores, attention deficit disorder, and a host of other problems. Acid reflux and persistent vomiting are also reported, and these are usually coupled with palpable bloating, unmanageable intestinal gas, and abdominal pain. A person with milk allergies could also experience lethargy and fatigue. </p>
    //                                     <p className="text-justify">A person with dairy allergies will usually exhibit more than one of the symptoms mentioned above. A patient’s history could show ear infections during childhood, sinusitis throughout his or her life, and irritable bowels. As the person grows older, other symptoms will develop including severe joint pain and lethargy. </p>
    //                                     <p className="text-justify">If a person exhibits any or all of these conditions, then a doctor may request a blood test to check for antibodies to milk and milk proteins.  This is important because it will also rule out the possibility of lactose or dairy intolerance. </p>
    //                                     <p className="text-justify">Completely removing dairy products from the person’s diet usually helps that person avoid their dairy allergies and the symptoms. However, this may be difficult because a person may not know which products contain dairy, since dairy could be listed under other names besides milk: a few of these are butter, casein, galactose, ghee, hydrolysates, and lacticol monohydrate.</p>
    //                                     <p className="text-justify">Some new research shows that placing milk proteins under the tongue of a younger patient can help dealing with dairy allergies. Similarly, foods baked with milk products that are given in gradual increments to young children have been cited as a possible way for children to outgrow their dairy allergies. In terms of symptoms management, drugs such as antihistamines may be prescribed by a physician. In the event that you have any symptoms that indicate a dairy allergy, contact your physician immediately for a consultation. </p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Dairy Allergy.png" alt="Recent" /><NavLink to="/blogs/dairy-allergy">Dairy Allergy</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Food Allergy Symptoms.png" alt="Recent" /><NavLink to="/blogs/food-allergy-symptoms">Food Allergies Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Food Allergy Cure.png" alt="Recent" /><NavLink to="/blogs/food-allergy-cure">Food Allergy Cure</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>

    //         </>
    //     )
    // } else if (parameter.parameter == "dairy-allergy") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Dairy Allergy | DHPFinder</title>
    //                 <meta name="description" content="A dairy allergy is a common type of food allergy, and can cause various symptoms in susceptible persons when they are exposed to dairy products. Read more here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>Dairy Allergy</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Dairy Allergy.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 02 2024</span></label>
    //                                     <h4>Dairy Allergy</h4>
    //                                     <p className="text-justify">An allergic reaction to milk and other dairy products is triggered by certain proteins in milk from animals such as buffalos, goats, sheep, and cows. If you have a dairy allergy, your immune system will react to these milk proteins as if they are dangerous pathogens or toxic substances that need to be eliminated. The body’s immune response is to release histamine and take other actions to get rid of the invasive chemicals. However, these complicated reactions in your body may come across as uncomfortable and undesirable symptoms of an allergy.</p>
    //                                     <p className="text-justify">When trying to understand the various aspects of milk allergy, it is important that you are able to differentiate between it and milk intolerance. Various symptoms of both may be similar but symptoms of intolerance are usually limited to digestive problems. The difference is that an allergy to milk causes your immune system to produce antibodies against the potentially harmless milk protein, intolerance occurs when the body lacks the enzyme lactase to digest milk properly. </p>
    //                                     <h4>Dairy Allergies</h4>
    //                                     <p className="text-justify">Various forms of dairy items such as milk, cheese, butter, condensed milk, and other baked goods containing dairy can cause a person to display symptoms of dairy allergies. These symptoms could be stomach cramps, indigestion, nausea, diarrhea, loose stools that may have signs of blood, wheezing, coughing, a runny nose, hives, itchy skin rash, and in extreme cases, anaphylaxis. Anaphylaxis may become fatal when your airways become constricted due to swelling in the throat. Additionally, an anaphylactic shock may also cause your blood pressure to drop sharply.</p>
    //                                     <h4>Multiple Food Allergies</h4>
    //                                     <p className="text-justify">If a person is allergic to different types of foods at the same time, then this is referred to as a multiple food allergy. The combination of two or more food allergies is more common in children, so parents need to be vigilant if their child is showing symptoms of these allergies. Two food allergies that a child usually has at the same time are a dairy allergy and an egg allergy. Symptoms such as hives, red and itchy skin rashes, vomiting, and indigestion are all typical of an egg allergy. If your child has an allergy to these, then avoiding products containing eggs and dairy is the best way to prevent him/her from having an allergic reaction.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Food Allergy Symptoms.png" alt="Recent" /><NavLink to="/blogs/food-allergy-symptoms">Food Allergies Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Food Allergy Cure.png" alt="Recent" /><NavLink to="/blogs/food-allergy-cure">Food Allergy Cure</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Alcohol Allergy.png" alt="Recent" /><NavLink to="/blogs/alcohol-allergy">Alcohol Allergy</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "food-allergy-symptoms") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Food Allergy Symptoms | DHPFinder</title>
    //                 <meta name="description" content="Food allergies and their symptoms can range from mild to life threatening; however, these can be controlled. For more information on these, visit our website." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>Food Allergy Symptoms</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Food Allergy Symptoms.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 06 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Food Allergies</h4>
    //                                     <p className="text-justify">Food allergies occur when the body’s immune system overreacts to certain foods, and the ensuing symptoms cause discomfort and uneasiness in a person. These symptoms can range from being mildly uncomfortable to life threatening. Generally, symptoms may start to appear within a few minutes to a few hours after a person has eaten a particular food that the person is allergic to.</p>
    //                                     <h5>Food Allergies: Symptoms</h5>
    //                                     <p className="text-justify">Common food allergy symptoms are:</p>
    //                                     <ul>
    //                                         <li>1)Swelling of the lips, face, and hands</li>
    //                                         <li>2)Dilation of blood vessels leading to red marks on the skin</li>
    //                                         <li>3)Tingling feeling in the mouth and the throat</li>
    //                                         <li>4)Hives and body rash</li>
    //                                         <li>5)Eczema</li>
    //                                         <li>6)Fainting spells</li>
    //                                         <li>7)Nasal and respiratory congestion</li>
    //                                         <li>8)Narrowing of airways and passages - Respiratory stress due to lack of oxygen - leading to huge gasps for air</li>
    //                                         <li>9)Drop in blood pressure</li>
    //                                         <li>10)Wheezing</li>
    //                                         <li>11)Severe abdominal pain</li>
    //                                         <li>12)Stomach camps</li>
    //                                         <li>13)Diarrhea</li>
    //                                         <li>14)Nausea</li>
    //                                         <li>15)Vomiting </li>
    //                                         <li>16)Lightheadedness</li>
    //                                         <li>17)Dizziness</li>
    //                                     </ul>
    //                                     <p className="text-justify">Anaphylactic shock, in extreme cases</p>
    //                                     <p className="text-justify">The foods that people are commonly allergic to are nuts, eggs, wheat, milk, and certain kinds of seafood. Although a person may also develop an allergy to fruits or vegetables, some fruits are known to cause more allergies than others. These include apples, hazelnuts, potatoes, bananas, tomatoes, anise and fennel seeds, parsley, coriander, kiwi fruit, honey dew, carrots, watermelons, peaches, strawberries, and cherries.</p>
    //                                     <p className="text-justify">Food allergies are very serious conditions that require immediate and effective help. Many people with food allergies carry auto-injectors with them, which release epinephrine into the blood stream to counter the antibodies.  If you experience a severe allergic reaction, but do not have a treatment option for immediate effect, then call 911 for emergency help.</p>
    //                                     <p className="text-justify">People with food allergies usually have to live with them for life, although some people may outgrow an allergy to foods that they were allergic to as a child. Since this is very rare, people with these allergies rely on effective management and precautions. Medicinal management may include antihistamines, epinephrine, or other drugs. Complete avoidance of foods that may contain allergens is the only way to steer clear of an allergic reaction. While there is continuous research on how allergies may be cured, so far only avoidance seems to be the only way to not have an allergic reaction.  If you or anyone you know suffers from any allergies or has exhibited any of the symptoms above, it is a good idea to see a doctor and get it checked out.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Alcohol Allergy.png" alt="Recent" /><NavLink to="/blogs/alcohol-allergy">Alcohol Allergy</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Metal Allergy Symptoms.png" alt="Recent" /><NavLink to="/blogs/metal-allergy-symptoms">Metal Allergy Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Metal Allergy.png" alt="Recent" /><NavLink to="/blogs/metal-allergy">Metal Allergy</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "food-allergy-cure") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Food Allergy Cure | DHPFinder</title>
    //                 <meta name="description" content="While there may be no food allergy cure, there are food allergy treatments that can help control the symptoms. Click here for more information." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>Food Allergy Cure</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Food Allergy Cure.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 02 2024</span></label>
    //                                     <h4>Food Allergy Cure</h4>
    //                                     <p className="text-justify">Food allergies can range from being daily annoyances like hives and skin rashes to life-threatening conditions such as narrowing of air passages and respiratory stress. While some people may become less susceptible as they grow older, most have to live a regimented life of avoidance. This is because there is no food allergy cure, but there is experimentation with techniques on how to develop tolerance and overcome food allergies. </p>
    //                                     <h4>Severe Food Allergy Reactions</h4>
    //                                     <p className="text-justify">If you have a food allergy, the best thing to do is to carry various antihistamines and auto-injectors with you that can provide symptomatic relief. In cases of severe allergic reactions, a commonly prescribed emergency food allergy treatment is the epinephrine auto-injector. This is a device with a concealed needle which injects adrenaline into the blood stream, in most cases through the thigh. In the event of an anaphylactic emergency or shock, you should be able to inject yourself or have someone around to inject you with the device. This is a single dose medication which helps normalize the immune system and clear airways, preventing an emergency situation from getting worse.  However, there may be some side effects as well, such as ventricular tachycardia (dangerously rapid heartbeat).</p>
    //                                     <h4>Experimental Food Allergy Cures and Treatments</h4>
    //                                     <p className="text-justify">There have been numerous attempts to find a food allergy cure that would allow people to eat foods they once could not. Immunotherapy has proven to be largely futile when dealing with food allergies. Still there are some positive results that give hope. </p>
    //                                     <p className="text-justify">One promising food allergy treatment is the Food Allergy Diet Treatment. This is also called oral immunotherapy. This treatment attempts to expose the subject to the allergens in small doses (placed at times under the tongue), and gradually increasing the dose; thereby, creating tolerance over a certain period of time.</p>
    //                                     <p className="text-justify">The important thing to realize is that any allergy can potentially be an emergency. Contact your doctor immediately if you suspect that you or a loved one may suffer from a food allergy. A doctor will be able to help you choose the right food allergy treatment and food allergy diet.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Alcohol Allergy.png" alt="Recent" /><NavLink to="/blogs/alcohol-allergy">Alcohol Allergy</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Metal Allergy Symptoms.png" alt="Recent" /><NavLink to="/blogs/metal-allergy-symptoms">Metal Allergy Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Metal Allergy.png" alt="Recent" /><NavLink to="/blogs/metal-allergy">Metal Allergy</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "alcohol-allergy") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Alcohol Allergy | DHPFinder</title>
    //                 <meta name="description" content="An alcohol allergy is very uncommon but may occur in some people. Read on for more information about alcohol allergies and alcohol intolerance here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>Alcohol Allergy</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Alcohol Allergy.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 02 2024</span></label>
    //                                     <h4>Alcohol Allergy</h4>
    //                                     <p className="text-justify">An alcohol allergy is very uncommon although many people may have alcohol intolerance. Some symptoms of intolerance to certain foods are similar to those of an allergy. However, the difference between the two is that while an allergy is an overreaction by the immune system caused by usually harmless substances, alcohol intolerance is usually difficulty in digesting alcohol because of the absence of a digestive chemical in a person. Another factor is that some people may be allergic to certain substances that may be present in an alcoholic beverage. Generally, symptoms of intolerance to alcohol are less severe and mostly related to the digestive system. On the other hand, if a person has been diagnosed with an allergy to alcohol, or allergy to another component in an alcohol beverage, the symptoms may be more severe and even fatal in some instances.</p>
    //                                     <h4>Allergies to Alcohol Drink Components</h4>
    //                                     <p className="text-justify">Some people may have an allergic reaction after consuming an alcoholic drink, not because of the alcohol in it, but because of other ingredients present in the drink. Mostly it is the substance the drink is based on, such as fruit like grapes, coconuts, apples, and oranges or grains like malt; although allergens from fruit and plants are usually destroyed during the process of making alcohol.</p>
    //                                     <h4>True Alcohol Allergy</h4>
    //                                     <p className="text-justify">Although a true alcohol allergy is very rare, the yeasts present in alcoholic drinks may trigger an allergic reaction in some people; but levels of yeast are very low in drinks.</p>
    //                                     <h4>Alcohol and Allergy to Other Foods</h4>
    //                                     <p className="text-justify">Alcohol may increase the likelihood of a person to have an intense allergic reaction to other foods. This is because it increases the permeability of the gut, which helps more food particles to enter the blood stream at a given time; therefore, making the reaction stronger because of the increased concentration of allergens in the blood.</p>
    //                                     <h4>Managing Alcohol Allergies</h4>
    //                                     <p className="text-justify">If you have a true allergy to alcohol, even a small amount may cause a very severe reaction. In this case, you should completely avoid alcohol. Read labels and consult your doctor to discuss various sources of alcohol that are usually not given much importance such as food marinades, liquor based cakes, and even tomato puree.</p>
    //                                     <p className="text-justify">If only a certain alcoholic beverage causes a reaction in you, then you may be allergic to a component of the drink such as the fruit it is made from. In this case, avoid drinks based on the same fruit or grain.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Metal Allergy Symptoms.png" alt="Recent" /><NavLink to="/blogs/metal-allergy-symptoms">Metal Allergy Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Metal Allergy.png" alt="Recent" /><NavLink to="/blogs/metal-allergy">Metal Allergy</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Seafood Allergies.png" alt="Recent" /><NavLink to="/blogs/seafood-allergies">Seafood Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "metal-allergy-symptoms") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Metal Allergy Symptoms | DHPFinder</title>
    //                 <meta name="description" content="Metal allergy symptoms are mostly visible as skin problems where the metal touched the skin. Read on for more symptoms of allergy to metals, particularly nickel." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>Metal Allergy Symptoms</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Metal Allergy Symptoms.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 06 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Metal Allergy</h4>
    //                                     <p className="text-justify">Metal allergies often manifest themselves as skin rashes and itching, although other symptoms may also be caused by exposure to metal. If a person has a metal allergy, then direct skin contact with a certain metal may cause that person to have metal allergy symptoms. One of the most common metals known for its potential in causing an allergic reaction is nickel.</p>
    //                                     <h4>Nickel Exposure</h4>
    //                                     <p className="text-justify">Nickel is commonly found in jewelry, so nickel is exposed to a large number of people, particularly women who use jewelry items more. Piercings have been observed to increase the risk of developing an allergic reaction to nickel and even other metals. This exposure may cause a person to develop nickel allergy symptoms and other metal allergy symptoms. Some other things through which a person may come into contact with nickel are metal clasps and zippers on clothes or bags, hair pins, spectacle frames, tattoo ink, utensils used in the kitchen, dental fillings, and artificial implants in the body.</p>
    //                                     <h4>Metal Allergy Symptoms</h4>
    //                                     <p className="text-justify">Metal allergy symptoms develop within 12 to 48 hours after exposure to the metal. A common indicator of an allergy to metal is the development of contact dermatitis. To differentiate it from its development from other causes, this is referred to as ‘allergic contact dermatitis’ when it occurs because of exposure to nickel or other metals. Some signs and symptoms associated with allergic contact dermatitis are:</p>
    //                                     <ul>
    //                                         <li>1)Bumps or rashes on the skin, particularly on the site that came into contact with the allergen</li>
    //                                         <li>2)Itching</li>
    //                                         <li>3)Changes in skin color such as redness</li>
    //                                         <li>4)Dry patches on the skin similar to burns</li>
    //                                         <li>5)Blisters and pus in extreme cases</li>
    //                                     </ul>
    //                                     <p className="text-justify">Symptoms for all metal allergies are somewhat similar. Some less common symptoms of a metal allergy include digestive problems, muscle aches, and chronic fatigue.</p>
    //                                     <h4>Professional Help</h4>
    //                                     <p className="text-justify">If you feel you are allergic to a metal or if you develop a rash every time you come in contact with a particular metal, contact a physician. An allergist may perform a patch test on you to confirm your allergy. When it is determined that you do have an allergy, you may be prescribed oral or topical medicines to provide relief from the symptoms. If your skin becomes infected following an allergic reaction, you should immediately see a professional healthcare provider for further treatment.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Alcohol Allergy.png" alt="Recent" /><NavLink to="/blogs/alcohol-allergy">Alcohol Allergy</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Food Allergy Symptoms.png" alt="Recent" /><NavLink to="/blogs/food-allergy-symptoms">Food Allergy Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Metal Allergy.png" alt="Recent" /><NavLink to="/blogs/metal-allergy">Metal Allergy</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "metal-allergy") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Metal Allergy | DHPFinder</title>
    //                 <meta name="description" content="A person may develop a metal allergy to a number of metals; nickel being one of the more common ones. Read more about these allergies and their management here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>Metal Allergy</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Metal Allergy.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 02 2024</span></label>
    //                                     <h4>Metal Allergy</h4>
    //                                     <p className="text-justify">A metal allergy is an inconvenient problem because of the amount of metal we come into contact with every day. A large percentage of the American population is allergic to one metal or another. Some metals are more notorious for causing allergic reactions in people than others. These include nickel and silicone; the former being more common than the latter. Being allergic to one metal may put you at an increased risk of developing allergies to other metals. A family history of metal allergies may also make you more susceptible to it.</p>
    //                                     <h4>Nickel Allergy</h4>
    //                                     <p className="text-justify">Nickel is found in many items like jewelry, and up to 15% of Americans are allergic to this metal; women more than men. People are also more at risk of developing this allergy if they are using jewelry that contains nickel for their body piercings. The allergic reaction is called allergic contact dermatitis, which is an itchy rash that develops when the skin comes into contact with a substance containing nickel. Besides jewelry, nickel may also be contained in coins, zippers, and even glasses frames. Some people may also have an allergic reaction from having a stent implant or ingesting food items rich in nickel such as potatoes grown in soil with a high nickel concentration or food cooked with utensils or in pans with nickel.</p>
    //                                     <h4>Silicone Allergy</h4>
    //                                     <p className="text-justify">Silicone allergies are not as common as those caused by nickel. Silicone is found in breast and cochlear implants and various household items like polishes, soaps, contact lenses, and processed foods. However, the FDA has recently established that breast implants do not cause an allergic reaction because pure silicon is used in it which is an inert substance.</p>
    //                                     <h4>Managing Metal Allergies</h4>
    //                                     <p className="text-justify">If you have or suspect allergy to a metal, it is best to see an allergist to get formally tested for it. Once an allergy has been diagnosed, individuals are encouraged to carry an allergy card at all times. They should also update their allergy related information in their medical records. If a person with a metal allergy has to undergo a surgery, particularly one involving metal implants, it is very important for them to discuss their allergies during their pre-surgical assessment.</p>
    //                                     <p className="text-justify">In case of a flare-up, various medications may be given such as corticosteroid or nonsteroidal creams for topical application or oral doses of corticosteroids or antihistamines. While these will not cure allergies, these will help in managing the symptoms of an allergic reaction.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Seafood Allergies.png" alt="Recent" /><NavLink to="/blogs/seafood-allergies">Seafood Allergies</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Seafood Allergy Symptoms.png" alt="Recent" /><NavLink to="/blogs/seafood-allergy-symptoms">Seafood Allergy Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Alcohol Allergy.png" alt="Recent" /><NavLink to="/blogs/alcohol-allergy">Alcohol Allergy</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "seafood-allergies") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Seafood Allergies | DHPFinder</title>
    //                 <meta name="description" content="Seafood allergies are very common. If you feel you may have allergy to a certain type of seafood, read on for more information and how to manage it." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>Seafood Allergies</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Seafood Allergies.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 06 2024</span></label>
    //                                     <h4>Seafood Allergies</h4>
    //                                     <p className="text-justify">Seafood allergies are very common and are lifelong conditions. It is one of the allergies most known for causing an anaphylactic reaction, which is the most severe form of allergic reactions. Seafood can include a variety of things like finned fish such as salmon and tuna; and shellfish such as lobsters, crabs, prawns, shrimp, and oysters. Often people who have an allergy to one class of seafood, such as a shellfish allergy, will be able to safely consume other types of seafood such as tuna. On the other hand, if a person is allergic to one kind of seafood, they may be more susceptible to developing an allergy to other seafood of the same kind. An example of this is salmon – if a person is allergic to it, that person may also be allergic to other finned fish.</p>
    //                                     <p className="text-justify">The extent of sensitivity a person exhibits to a seafood differs for everyone. While someone may only develop a slight rash after eating some seafood, another person may display seafood allergy symptoms simply by inhaling vapors released when cooking seafood. This is because seafood emits small proteins in the vapor when it is being cooked, which may cause an allergic reaction in the lungs and nasal passages.</p>
    //                                     <p className="text-justify">Contamination with seafood may also cause an allergic reaction in some people. This may occur when their food is rolled in the same batter used for seafood, cooked in the same oil, or barbequed on a grill previously used for seafood. Some food items, otherwise harmless, may also have traces of seafood in them mostly because of such contamination. These include salads such as a Caesar salad, pasta sauces, chicken nuggets, etc.</p>
    //                                     <h4>Living with Seafood Allergies</h4>
    //                                     <p className="text-justify">If you feel that you have a seafood allergy, it is best to consult with a doctor and be tested for it. Since symptoms of seafood allergies may at times be similar to those caused by a toxic reaction, a test will confirm what is causing your reaction. It will also help you manage your allergy better.</p>
    //                                     <p className="text-justify">The best thing to do if you have an allergy such as a fish allergy is to avoid the fish you are allergic to. Stay away from places where it is prepared and read labels for any hidden ingredients in your food. Your doctor may also suggest oral antihistamines or other medicines that may provide relief in case of a flare-up.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Food Allergy Cure.png" alt="Recent" /><NavLink to="/blogs/food-allergy-cure">Food Allergy Cure</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Food Allergy Symptoms.png" alt="Recent" /><NavLink to="/blogs/food-allergy-symptoms">Food Allergies Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 06 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Alcohol Allergy.png" alt="Recent" /><NavLink to="/blogs/alcohol-allergy">Alcohol Allergy</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "seafood-allergy-symptoms") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Seafood Allergy Symptoms | DHPFinder</title>
    //                 <meta name="description" content="Seafood allergy symptoms range from mild to severe. A severe reaction may be life-threatening if it is not controlled in time. Read on for these symptoms here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>Seafood Allergy Symptoms</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Seafood Allergy Symptoms.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 06 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Seafood Allergy Symptoms</h4>
    //                                     <p className="text-justify">If a person is allergic to seafood and accidentally consumes seafood, then the person may experience mild to severe seafood allergy symptoms. Mild reactions could be a slightly itchy throat, and severe reactions could be life-threatening. A common type of seafood that many people are allergic to is shellfish. The term ‘shellfish’ is collectively used to refer to crabs, lobsters, shrimp, prawns, octopus, and oysters among others. Some people are also allergic to fish, and the types of fish may include salmon, tuna, shark, and other varieties of fish.</p>
    //                                     <p className="text-justify">If a person is allergic to shellfish, he/she may experience the following shellfish allergy symptoms:</p>
    //                                     <ul>
    //                                         <li>1)Tingling in the mouth</li>
    //                                         <li>2)Fainting, lightheadedness, or dizziness</li>
    //                                         <li>3)Vomiting, nausea, diarrhea, or abdominal pain</li>
    //                                         <li>4)Trouble breathing, nasal congestion, or wheezing</li>
    //                                         <li>5)Swelling of the throat, tongue, face, lips, or other parts of the body</li>
    //                                         <li>6)Itching, hives, or eczema</li>
    //                                     </ul>
    //                                     <p className="text-justify">A more severe and in some cases life-threatening allergic reaction to shellfish is called anaphylaxis (or an anaphylactic shock). Some of the symptoms associated with anaphylaxis are:</p>
    //                                     <ul>
    //                                         <li>1)Loss of consciousness, lightheadedness, or dizziness</li>
    //                                         <li>2)Rapid pulse</li>
    //                                         <li>3)Shock leading to a severe drop in the blood pressure</li>
    //                                         <li>4)Difficulty in breathing due to a swelling in the throat</li>
    //                                     </ul>
    //                                     <p className="text-justify">Shrimp is a shellfish that is notorious for causing an allergic reaction in a number of people. Some of the shrimp allergy symptoms include:</p>
    //                                     <ul>
    //                                         <li>1)Stomach complaints like nausea, vomiting, diarrhea, or abdominal pain</li>
    //                                         <li>2)Rapid heartbeat, fainting, lightheadedness, or dizziness</li>
    //                                         <li>3)Watery eyes, swelling of throat, tongue, lips, and mouth tingling</li>
    //                                         <li>4)Respiratory conditions like shortness of breath, congestion, runny nose, or wheezing</li>
    //                                         <li>5)Skin conditions like eczema, hives, rash, or itching</li>
    //                                     </ul>
    //                                     <p className="text-justify">Fish allergy symptoms are similar to these symptoms too. While this is not as common as shell fish allergies, it could be just as severe in extreme cases.</p>
    //                                     <p className="text-justify">Any of these symptoms may show up minutes after eating seafood or may take a few hours to appear. However, the important point to remember is that you should follow proper procedure if you are exposed to seafood, whether it be taking medication for mild reactions or seeking medical attention for more severe reactions. If you come across someone who goes into an anaphylactic shock after exposure to seafood, immediately call 911.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Alcohol Allergy.png" alt="Recent" /><NavLink to="/blogs/alcohol-allergy">Alcohol Allergy</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Metal Allergy Symptoms.png" alt="Recent" /><NavLink to="/blogs/metal-allergy-symptoms">Metal Allergy Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Metal Allergy.png" alt="Recent" /><NavLink to="/blogs/metal-allergy">Metal Allergy</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> March, 02 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "bladder-cancer-symptoms") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Bladder Cancer Symptoms | DHPFinder</title>
    //                 <meta name="description" content="Knowing what bladder cancer symptoms are, like changes in bladder habit and hematuria, can help in early detection of this condition. Click here for more details." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/19.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 1 2024</span></label>
    //                                     <h4>Bladder Cancer Symptoms</h4>
    //                                     <p className="text-justify">Bladder cancer refers to an abnormal growth of cells in the bladder that cluster and form a mass called a tumor. This tumor can invade nearby tissues and organs. Bladder cancer has the potential to affect the whole urinary system, including kidneys, if treatment is delayed.</p>
    //                                     <p className="text-justify">Successful treatment of bladder cancer depends upon early detection. Screening tests like urine tests or cystoscopy can be used to detect the presence of this condition even when there are no obvious signs and symptoms. These screening tests are recommended for people who are at a very high risk of developing bladder cancer. Some of the risk factors include smoking, chronic bladder infections, bladder birth defects, and a family history of bladder cancer. The chances of getting bladder cancer increase with age. Experts have identified higher rates of bladder cancer among Caucasians and African Americans. Gender also plays a role as men are found to be twice as likely as women to develop bladder cancer.</p>
    //                                     <p className="text-justify">While bladder cancer symptoms in men are more or less the same as bladder cancer symptoms in women, men are more susceptible to getting the cancer. It is also detected earlier in men; this means that a prognosis for women is bleaker since diagnosis may be made too late. African American women show the poorest treatment outcomes in all groups.</p>
    //                                     <p className="text-justify">Hematuria and changes in bladder habit are the two most common bladder cancer symptoms. Hematuria, blood in the urine, is one of the first major signs of bladder cancer. If this happens, the urine will change to pink, pale yellow, or dark red in color. This bleeding is painless and in most cases short-lived. In some cases, the blood is not enough to change the appearance of urine.</p>
    //                                     <p className="text-justify">Changes in a person’s bladder habit can also be a sign of bladder cancer. Prominent among these are a more than unusual need to urinate, feeling pain or a burning sensation while urinating, need to strain while emptying the bladder, and an urgent need to empty the bladder even when it is not full. When bladder cancer becomes larger, it can cause symptoms like lower back pain and an inability to urinate.</p>
    //                                     <p className="text-justify">Certain infections, benign tumors, or stones in the kidney or bladder can also cause these symptoms. Blood thinning medications like aspirin can cause bleeding too.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/20.png" alt="Recent" /><NavLink to="/blogs/adrenal-cancer">Adrenal Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 4 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/21.png" alt="Recent" /><NavLink to="/blogs/advanced-prostate-cancer">Advanced Prostate Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 8 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/22.png" alt="Recent" /><NavLink to="/blogs/anal-cancer">Anal Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 8 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "adrenal-cancer") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Adrenal Cancer | DHPFinder</title>
    //                 <meta name="description" content="Adrenal cancer can be difficult. Understanding the causes, symptoms, and treatment options could help you effectively manage this condition. Learn more here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/20.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 4 2024</span></label>
    //                                     <h4>Adrenal Cancer</h4>
    //                                     <p className="text-justify">The adrenal glands are small, hormone-secreting glands, and are located on top of the kidneys. These glands produce a number of important hormones in the body which regulate metabolism and blood pressure, among many other functions. They also produce precursors for male and female sex hormones.</p>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <p className="text-justify">Adrenal cancer occurs when the cells in this gland start to divide uncontrollably, mostly due to a DNA mutation. This growth – referred to as a tumor – can be either malignant or benign, and usually occurs in the outer region of the adrenal gland known as the cortex. Benign tumors are also known as adenomas, and they usually occur in only one of the adrenal glands. These usually measure less than 2 inches in size. Most individuals with adenomas do not exhibit any symptoms, and may even be unaware of their presence unless they take a CT scan of the abdomen. Malignant tumors are often diagnosed on the basis of certain symptoms which arise due to the excess hormones produced by the cancerous growth. These symptoms of adrenal cancer include, but are not limited to:</p>
    //                                     <ul>
    //                                         <li>1)Water retention and bloating</li>
    //                                         <li>2)Excess facial or body hair growth</li>
    //                                         <li>3)High blood pressure</li>
    //                                         <li>4)Easy bruising</li>
    //                                     </ul>
    //                                     <p className="text-justify">Since the adrenal glands influence a variety of body functions, each person may experience different symptoms. This can make diagnosing adrenal gland cancer a difficult procedure. The exact causes of adrenal cancer are not known; although, it is speculated that there could be a genetic factor. Individuals diagnosed with genetic syndromes, like Li-Fraumeni or Beckwith-Wiedemann, are at a higher risk of developing the condition. Exposure to a high-fat diet and carcinogenic substances can also put you at greater risk, but the exact mechanism of action for these substances is not fully understood.</p>
    //                                     <p className="text-justify">Diagnosing the condition involves thoroughly exploring all the symptoms and eliminating the possibility of any other disease causing them. Doctors will also use abdominal CT scans to detect the presence of any abnormal growth. If the tumor is producing excess hormones, surgery may be used to remove the growth. Otherwise, chemotherapy and treatment with drugs which prevent excess hormone production may be used.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/19.png" alt="Recent" /><NavLink to="/blogs/bladder-cancer-symptoms">Bladder Cancer Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 1 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/21.png" alt="Recent" /><NavLink to="/blogs/advanced-prostate-cancer">Advanced Prostate Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 8 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/22.png" alt="Recent" /><NavLink to="/blogs/anal-cancer">Anal Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 8 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "advanced-prostate-cancer") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Advanced Prostate Cancer | DHPFinder</title>
    //                 <meta name="description" content="Advanced prostate cancer is a rare disease, and can have a severe impact on a person’s life. Learn about its causes, symptoms, and treatment options here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/21.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 8 2024</span></label>
    //                                     <h4>Advanced Prostate Cancer</h4>
    //                                     <p className="text-justify">The prostate is a small gland in males, responsible for producing fluids which nourish and transport the sperm. As with all cells in the body, it is possible for mutations to occur in the prostate gland cells. This causes the cells to divide rapidly and uncontrollably, resulting in cancer. Due to advances in modern screening methods, it is possible to detect prostate cancer at an early stage; however, five to ten percent of cases progress into advanced prostate cancer, which can cause severe health complications.</p>
    //                                     <p className="text-justify">Symptoms of advanced prostate cancer are more noticeable as compared to the initial stages of the cancer. The individual may experience swelling in the legs and discomfort in the pelvic area, as the prostate gland grows in size. As the enlarged gland presses against the bladder, the individual may experience trouble urinating or experience decreased force in the urine stream. In some cases, the individual may also find blood in urine or semen, and if this is the case, a doctor should be consulted immediately.</p>
    //                                     <p className="text-justify">While the causes for advanced prostate cancer are not known, certain factors do place individuals at higher risk than others. The risk of prostate cancer increases with age and it is more common in males older than 65 years of age. This is especially true for any person who has a family history of prostate cancer, despite the fact that the genetic link is not fully understood. Prostate cancer has also been found to be more prevalent in African Americans, though the reasons for this unusual feature are not entirely understood.</p>
    //                                     <p className="text-justify">Since advanced prostate cancer is not well understood yet, a cure does not exist. However, advances in modern medicine are making it easier for individuals to slow down the progression of the cancer, manage symptoms, and maintain their quality of life the best they can. Common therapies include treatment with hormones and medication which prevents the tumors from tampering with the body’s natural balance. Chemotherapy may also be effective, though the side effects mean only some people can go for this route of treatment. Consulting with an oncologist can help you determine the best course of treatment based on the progression of your condition.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/19.png" alt="Recent" /><NavLink to="/blogs/bladder-cancer-symptoms">Bladder Cancer Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 1 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/20.png" alt="Recent" /><NavLink to="/blogs/adrenal-cancer">Adrenal Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 4 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/22.png" alt="Recent" /><NavLink to="/blogs/anal-cancer">Anal Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 8 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "anal-cancer") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Anal cancer | DHPFinder</title>
    //                 <meta name="description" content="Anal cancer is a rare form of cancer affecting the anal canal, which is the final portion of the gastrointestinal tract. Understand this condition better here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/22.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 8 2024</span></label>
    //                                     <h4>Anal Cancer</h4>
    //                                     <p className="text-justify">According to the American Cancer Society, there will roughly be about 7000 new cases of anal cancer in 2013, with a higher prevalence in women than men. But what exactly causes this rare form of cancer, and are there any effective diagnostic methods and treatment options available?</p>
    //                                     <h4>Facts about Anal Cancer</h4>
    //                                     <p className="text-justify">The anus is located at the very end of the gastrointestinal tract, and is the outer region of the anal canal, which connects the rectum to the outside of the body and allows the body to expel stool. Cancers tend to occur most commonly in the squamous cells lining the anal canal. Other forms of anal cancer known as adenocarcinomas can also occur in the transitional zone, which is the region where the anal canal meets the rectum. Cells in this area are responsible for producing mucus to allow for smooth movement of stool through the rectum.</p>
    //                                     <p className="text-justify">While it is accepted that mutations to the DNA in cells trigger cancerous growth, the causes of mutations are not fully understood. Experts have found that 80 percent of patients with anus cancer tend to have a strain of the human papilloma virus (HPV) infecting them in the region, suggesting a strong link between the virus and mutations. An independent study also concluded that individuals engaging in anal intercourse are at a much higher risk than the general population at developing this cancer.</p>
    //                                     <p className="text-justify">Symptoms of this type of cancer include rectal bleeding, pain in the anal area, and possible changes to bowel movements. Some individuals may also experience problems controlling bowel movements, which could get progressively worse. Women may experience bloating or lower back pain if the tumor applies pressure on the vagina. If a person experiences any of these symptoms, seeing your doctor is highly recommended. A rectal examination and a biopsy are two procedures used to help make a diagnosis. A CT scan or MRI can further help to determine the size of the tumor.</p>
    //                                     <p className="text-justify">Based on the progression of symptoms, doctors may use surgery to remove the cancerous growth, or use chemotherapy and radiotherapy to slow down the growth and shrink it.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/19.png" alt="Recent" /><NavLink to="/blogs/bladder-cancer-symptoms">Bladder Cancer Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 1 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/20.png" alt="Recent" /><NavLink to="/blogs/adrenal-cancer">Adrenal Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 4 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/21.png" alt="Recent" /><NavLink to="/blogs/advanced-prostate-cancer">Advanced Prostate Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 8 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "anal-cancer-symptoms") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Anal Cancer Symptoms | DHPFinder</title>
    //                 <meta name="description" content="Anal cancer is a rare form of cancer; understanding anal cancer symptoms can help in early identification and in seeking timely treatment. Find out more here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/23.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 8 2024</span></label>
    //                                     <h4>Anal Cancer Symptoms</h4>
    //                                     <p className="text-justify">Anal cancer is a form of cancer affecting the last portion of the gastrointestinal tract, known as the anus. It is a very rare occurrence though. Because of its low prevalence, many people are completely unaware of the signs of anal cancer, often attributing them to another disease. However, being familiar with the symptoms of anal cancer is a key step in preventing the disease from severely affecting your health.</p>
    //                                     <p className="text-justify">The primary anal cancer symptoms include itching or pain in the anal area. Many people discount these symptoms because they think these are a result of dryness, constipation, or some other infection. Over time, the severity of symptoms will increase, and can seriously impact an individual’s quality of life. Some anal cancers begin in the region of the anus that produces mucus for smooth bowel movements. The tumorous growth may cause excess mucus production and a discharge from the anus. In more extreme cases, rectal bleeding may also occur.</p>
    //                                     <p className="text-justify">Women experience all of the above anal cancer symptoms, and may also experience pain in the lower back. This is because the tumor may exert pressure on the vagina and the nerves in the region. Bloating is another symptoms associated with anal cancer in both men and women.</p>
    //                                     <p className="text-justify">If a person experiences any of the symptoms mentioned above, visiting a doctor immediately should become top priority. The doctor will conduct a basic rectal examination to check for lumps, swelling, and mucus discharge. A biopsy may also be conducted to examine the tissue for cancerous properties. If the results indicate the presence of a tumor, a CT scan or MRI will be used to determine the size of the growth and the progression of the cancer. Depending on the stage, doctors may recommend different treatment options.</p>
    //                                     <p className="text-justify">The first option is to surgically remove the outgrowth and leave the rest of the tissue intact. This is generally the route doctors take if the cancer is detected in its early stages. In more advanced cases, surgery may be used to remove the anus, rectum, and certain sections of the bowel.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/19.png" alt="Recent" /><NavLink to="/blogs/bladder-cancer-symptoms">Bladder Cancer Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 1 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/20.png" alt="Recent" /><NavLink to="/blogs/adrenal-cancer">Adrenal Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 4 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/21.png" alt="Recent" /><NavLink to="/blogs/advanced-prostate-cancer">Advanced Prostate Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 8 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "anaplastic-thyroid-cancer") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Anaplastic Thyroid Cancer | DHPFinder</title>
    //                 <meta name="description" content="Anaplastic thyroid cancer is a cancer of the thyroid gland. It is incurable although rare. Read on to learn more about its symptoms and diagnosis." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/24.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 8 2024</span></label>
    //                                     <h4>Anaplastic Thyroid Cancer</h4>
    //                                     <p className="text-justify">Anaplastic thyroid cancer is a cancer of the thyroid gland. It is a rare type of cancer (about 1 percent of all thyroid cancers; approximately 300 new cases are diagnosed in the United States each year), but it is aggressive. The outlook for those diagnosed with the cancer is not good, and doctors usually give no more than six months to people diagnosed with anaplastic thyroid cancer. Treatment options like chemotherapy and radiation therapy may alleviate the symptoms, but these treatment options are not very effective.</p>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <p className="text-justify">Some of the reported anaplastic thyroid cancer symptoms include:</p>
    //                                     <ul>
    //                                         <li>1)Coughing</li>
    //                                         <li>2)Coughing up blood</li>
    //                                         <li>3)Rapidly growing lump in lower neck</li>
    //                                         <li>4)Loud breathing</li>
    //                                         <li>5)Changing voice or hoarseness</li>
    //                                         <li>6)Difficulty swallowing</li>
    //                                     </ul>
    //                                     <p className="text-justify">The causes of anaplastic thyroid cancer are unknown, so it may not be preventable. What is known is that it generally affects people over 60 years of age and does not appear to be hereditary. While there is no conclusive evidence to prove that anaplastic thyroid cancer results from a mutation in another form of thyroid cancer, some researchers do believe in this hypothesis. Current research on the cancer may show that it results from a chain of genetic mutations; what is less clear is what starts these mutations.</p>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <p className="text-justify">If the doctors suspect someone to be suffering from anaplastic thyroid cancer, they may advise him/her to undergo:</p>
    //                                     <ul>
    //                                         <li>1)CT scan or MRI</li>
    //                                         <li>2)Thyroid biopsy</li>
    //                                         <li>3)Thyroid exam</li>
    //                                         <li>4)Physical exam</li>
    //                                     </ul>
    //                                     <p className="text-justify">While thyroid function blood tests are almost always normal, a physical examination is likely to show a growth in the throat. Once the cancer has been diagnosed, doctors may start off with various treatment options (a combination of chemotherapy and radiation). Surgery, however, cannot cure anaplastic thyroid cancer, and complete removal of the thyroid cancer does not improve prognosis or prolong a person’s life. Surgery may be needed to insert a tube in the throat to help with breathing or in the stomach to help with eating.</p>
    //                                     <p className="text-justify">The survival rates for this type of cancer are not good, but that should not stop people from joining a support group of people diagnosed with this cancer. This may help in easing the stress of the illness and learning coping strategies.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/19.png" alt="Recent" /><NavLink to="/blogs/bladder-cancer-symptoms">Bladder Cancer Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 1 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/20.png" alt="Recent" /><NavLink to="/blogs/adrenal-cancer">Adrenal Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 4 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/21.png" alt="Recent" /><NavLink to="/blogs/advanced-prostate-cancer">Advanced Prostate Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 8 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "bile-duct-cancer") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Bile Duct Cancer | DHPFinder</title>
    //                 <meta name="description" content="Bile duct cancer is a type of cancer that forms in bile ducts. It is a rare type of cancer, but it is an aggressive form of cancer which progresses quickly." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/25.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 8 2024</span></label>
    //                                     <h4>Bile Duct Cancer</h4>
    //                                     <p className="text-justify">Bile duct cancer is also called cholangiocarcinoma. Bile ducts are slender tubes that carry bile, a digestive fluid, through the liver. Bile duct cancer is a cancer that forms in these slender tubes. Cancer of the bile duct is a rare type of cancer, but it is an aggressive form of cancer which progresses quickly and may be difficult to treat.</p>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <p className="text-justify">Some of the reported bile duct cancer symptoms are:</p>
    //                                     <ul>
    //                                         <li>1)Jaundice</li>
    //                                         <li>2)Itchy skin</li>
    //                                         <li>3)Weakness</li>
    //                                         <li>4)Fatigue</li>
    //                                         <li>5)Weight loss</li>
    //                                         <li>6)Fever</li>
    //                                         <li>7)Abdominal pain</li>
    //                                         <li>8)Poor appetite</li>
    //                                         <li>9)Abnormal liver tests</li>
    //                                         <li>10)White-colored stools</li>
    //                                     </ul>
    //                                     <p className="text-justify">It is worth noting that the symptoms may not show until the flow of bile from the liver is blocked.</p>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <p className="text-justify">While the symptoms of the cancer may be known, its causes are not, which could mean that bile duct cancer may not be preventable. There does seem to be some type of link between developing this type of cancer and substances that inflame or irritate the bile ducts. Some of the risk factors associated with the cancer are known however and are as following:</p>
    //                                     <ul>
    //                                         <li>1)Increasing age – the cancer can develop at any age, but in a majority of cases, it is diagnosed in people over 65 years of age.</li>
    //                                         <li>2)Abnormal bile ducts – people born with abnormalities of the bile ducts are more likely to develop bile duct cancer.</li>
    //                                         <li>3)Bile duct stones – similar to but smaller than gallstones; causes inflammation which increases the risk of developing this type of cancer</li>
    //                                         <li>4)Inflammatory conditions – people with chronic inflammatory bowel condition called ulcerative colitis are at an increased risk of developing bile duct cancer. People with primary sclerosing cholangitis are also more likely to develop this type of cancer.</li>
    //                                     </ul>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <p className="text-justify">As mentioned earlier, the cancer is rare and difficult to treat because the cancer is already at an advanced stage when its symptoms start to show. Each year, approximately 2,000 to 3,000 people in the United States develop this cancer. Bile duct cancer prognosis will heavily depend upon two things: the location of the cancer and its stage. People diagnosed with this type of cancer cannot be cured; therefore, bile duct cancer treatment options are focused towards alleviating the symptoms of the cancer. Treatment options may include:</p>
    //                                     <ul>
    //                                         <li>1)Surgery</li>
    //                                         <li>2)Liver transplant</li>
    //                                         <li>3)Biliary drainage</li>
    //                                         <li>4)Photodynamic therapy</li>
    //                                         <li>5)Radiation therapy</li>
    //                                         <li>6)Chemotherapy</li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/19.png" alt="Recent" /><NavLink to="/blogs/bladder-cancer-symptoms">Bladder Cancer Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 1 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/20.png" alt="Recent" /><NavLink to="/blogs/adrenal-cancer">Adrenal Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 4 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/21.png" alt="Recent" /><NavLink to="/blogs/advanced-prostate-cancer">Advanced Prostate Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 8 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "bladder-cancer-survivals") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Bladder Cancer Survival Rates | DHPFinder</title>
    //                 <meta name="description" content="More than 500,000 people are bladder cancer survivors in the United States. Bladder cancer survival rates are by and large encouraging. Early detection is the key." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/26.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 8 2024</span></label>
    //                                     <h4>Bladder cancer survival rates</h4>
    //                                     <p className="text-justify">There are more than 500,000 bladder cancer survivors in the United States. Bladder cancer survival rates are by and large encouraging. Like other cancers, bladder cancer is a serious medical condition which if left untreated can prove to be fatal. However, unlike many other types of cancers, bladder cancer, in a majority of the cases, can be diagnosed at an early stage, which may lead to better treatment chances. Therefore, a large number of people diagnosed with it have a good chance of surviving. Bladder cancer, however, has the tendency of recurring, and those who have had a successful treatment may have to continue seeing their doctor for checkups.</p>
    //                                     <p className="text-justify">According to the latest estimates by the American Cancer Society, over 72,000 new cases of bladder cancer will be diagnosed in the United States in 2013. Most of these diagnoses will be men (whites more than blacks are likely to be diagnosed with bladder cancer). The American Cancer Society also estimates a little over 15,000 will die due to bladder cancer in 2013. The bladder cancer survival rate has been encouraging in women, since fewer women have been diagnosed with the cancer and died of it in recent years. In men, however, rates of diagnosis and mortality have remained the same.</p>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <p className="text-justify">Since bladder cancer survival chances are so promising, it is important to look for its symptoms as early diagnosis is the key for treatment. People over the age of 55 years (9 out of 10 people diagnosed with bladder cancer are over this age) should look for the following signs and symptoms of bladder cancer:</p>
    //                                     <ul>
    //                                         <li>1)Blood in urine – it may or may not be visible with the naked eye</li>
    //                                         <li>2)Pelvic pain</li>
    //                                         <li>3)Back pain</li>
    //                                         <li>4)Painful urination</li>
    //                                         <li>5)Frequent urination</li>
    //                                     </ul>
    //                                     <p className="text-justify">This cancer develops more in people who smoke, have been exposed to toxic chemicals and radiation, and have had a parasitic infection. Therefore, by eliminating these risk factors, a person may be able to prevent bladder cancer from developing.</p>
    //                                     <p className="text-justify">Once diagnosed, bladder cancer is treated through radiation therapy, chemotherapy, biological therapy (immunotherapy), and surgery. The effectiveness of these treatment options will depend upon how advanced the cancer is, its type, a patient’s general health, and treatment preferences.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/19.png" alt="Recent" /><NavLink to="/blogs/bladder-cancer-symptoms">Bladder Cancer Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 1 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/20.png" alt="Recent" /><NavLink to="/blogs/adrenal-cancer">Adrenal Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 4 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/21.png" alt="Recent" /><NavLink to="/blogs/advanced-prostate-cancer">Advanced Prostate Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 8 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "bladder-cancer-treatment") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Bladder Cancer Treatment | DHPFinder</title>
    //                 <meta name="description" content="Bladder cancer treatment may be successful if diagnosed in the initial stages. Read on for more information about treatment options." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/27.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 8 2024</span></label>
    //                                     <h4>Bladder Cancer Treatment</h4>
    //                                     <p className="text-justify">If you have been diagnosed with bladder cancer and are worried about your prognosis, then you should know that bladder cancer treatment has been shown to be successful. In fact, since it is generally diagnosed in its initial stages, treatment has a better chance of success than many other types of cancer. However, the cancer has a tendency to recur; therefore, people that have been successfully treated, may have to continue seeing their doctor on a regular basis, even years after treatment.</p>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <p className="text-justify">The following options are types of bladder cancer treatments doctors will use:</p>
    //                                     <ul>
    //                                         <li>1)Surgery</li>
    //                                         <li>2)Radiation therapy</li>
    //                                         <li>3)Chemotherapy</li>
    //                                         <li>4)Biological therapy (Immunotherapy)</li>
    //                                     </ul>
    //                                     <p className="text-justify">The effectiveness of these treatments will depend upon the patient's overall health, the stage of his/her cancer, and treatment preferences. For example, if the patient's bladder cancer is in its initial stages (it is small and has not spread), his/her doctor may recommend bladder cancer surgery. Transurethral resection (TUR) is a form of surgery which is generally performed to remove bladder cancers from the inner layers of the bladder. A small wire loop is passed through a cystoscope and into the bladder. Then the cancer cells are burned away with an electric current. In some cases, a high-energy laser may be used instead of an electric current. Treatment for bladder cancer may also include segmental cystectomy or partial cystectomy in which only the affected portion of the bladder is removed.</p>
    //                                     <p className="text-justify">If the cancer is in a more advanced stage (invaded the deeper layers of the bladder wall), then the doctors may recommend removing the whole bladder along with surrounding lymph nodes. This is called radical cystectomy. In men, this surgical procedure includes the removal of prostate and seminal vesicles; and in women, the procedure involves removal of ovaries, uterus, and part of the vagina.</p>
    //                                     <p className="text-justify">Bladder cancer surgery is not without its side effects. After TUR, the patient may experience bloody or painful urination for a few days. Segmental cystectomy patients may experience infection and bleeding. Frequent urination may be another side effect. In addition to infection and bleeding, male patients who undergo radical cystectomy may experience erectile dysfunction (this is not inevitable though). Women may experience infertility and premature menopause.  Patients who undergo radical cystectomy will also need to work with their doctors for the removal of urine.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/19.png" alt="Recent" /><NavLink to="/blogs/bladder-cancer-symptoms">Bladder Cancer Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 1 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/20.png" alt="Recent" /><NavLink to="/blogs/adrenal-cancer">Adrenal Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 4 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/21.png" alt="Recent" /><NavLink to="/blogs/advanced-prostate-cancer">Advanced Prostate Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 8 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "blood-cancer") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Blood Cancer | DHPFinder</title>
    //                 <meta name="description" content="Blood cancer, also known as hematologic cancer, is a cancer that begins in the blood-forming tissues. Read more about its types and risk factors here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/28.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 8 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Blood Cancer</h4>
    //                                     <p className="text-justify">Blood cancer, also known as hematologic cancer, is a cancer that begins in the blood-forming tissues, like the bone marrow. It may also begin in the cells of the immune system. There are three main types of blood cancer:</p>
    //                                     <ul>
    //                                         <li>1)Leukemia</li>
    //                                         <li>2)Lymphoma</li>
    //                                         <li>3)Multiple myeloma</li>
    //                                     </ul>
    //                                     <p className="text-justify">Each year, more than 100,000 people are diagnosed with blood cancers resulting in more than 50 percent mortality. Leukemia cancer remains one of the leading causes of death in children and teens less than 20 years of age. </p>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Leukemia</h4>
    //                                     <p className="text-justify">Leukemia is a cancer of the bone marrow and blood. There are two main types of leukemia: lymphocytic leukemia and myelogenous leukemia. Leukemia cancer is either acute or chronic. The former progresses rapidly while the latter progresses slowly. The exact causes of the cancer are still not known. However, some of the risk factors associated with the development of leukemia cancer include:</p>
    //                                     <ul>
    //                                         <li>1)A family history of leukemia</li>
    //                                         <li>2)Smoking </li>
    //                                         <li>3)Exposure to certain toxic chemicals like benzene</li>
    //                                         <li>4)Exposure to high levels of radiation </li>
    //                                         <li>5)Certain blood disorders </li>
    //                                         <li>6)Genetic disorders </li>
    //                                         <li>7)Previous cancer treatment </li>
    //                                     </ul>
    //                                     <p className="text-justify">The cancer may be treatable, but will depend upon the person's age, health, and at which stage the cancer was diagnosed.</p>
    //                                     <h4>Lymphoma</h4>
    //                                     <p className="text-justify">Lymphoma generally starts in the lymph nodes. The two types of lymphoma are the Hodgkin lymphoma and the non-Hodgkin lymphoma. In the former, cancer spreads in an orderly manner from one group of lymph nodes to another, while in the latter type, the cancer spreads randomly. The causes of this cancer are also unknown. Hodgkin's and non-Hodgkin's lymphoma may be treatable, but will depend on your health, the stage the cancer is in, and what type of treatment you prefer.</p>
    //                                     <h4>Multiple Myeloma</h4>
    //                                     <p className="text-justify">Multiple myeloma is a cancer of the plasma cells. The exact causes of this cancer are unknown; although, people 67 years of age or older are at the highest risk of developing this type of cancer. The cancer is more common in men than women, and African Americans are more likely than whites to be diagnosed with the cancer. There is no cure for this type of blood cancer.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/19.png" alt="Recent" /><NavLink to="/blogs/bladder-cancer-symptoms">Bladder Cancer Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 1 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/20.png" alt="Recent" /><NavLink to="/blogs/adrenal-cancer">Adrenal Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 4 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/21.png" alt="Recent" /><NavLink to="/blogs/advanced-prostate-cancer">Advanced Prostate Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 8 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "depression") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Depression | DHPFinder</title>
    //                 <meta name="description" content="Depression is often discounted as temporary and mild feelings due to a bout of feeling blue or sad. Read on for the different types of depression and their triggers." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Depression.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 15 2024</span></label>
    //                                     <p className="text-justify">Depression is a condition that can be tricky to diagnose because it manifests itself in different ways. Individuals suffering from depression may not recognize their signs and symptoms, so they may discount their feelings of fatigue and loss of interest to just another round of feeling sad. If left unchecked, it can have a severely debilitating effect on an individual’s life; affecting their ability to conduct everyday chores and maintain healthy relationships with family, friends, and colleagues at work.</p>
    //                                     <p className="text-justify">An increasing number of Americans experience some symptoms that may be classified as depression. Contrary to popular belief, the mental illness is not just characterized by being unenergetic or sad for extended periods of time. Bipolar depression, for instance, has both manic and depressive episodes. A person will feel very elated and happy during manic episodes, but these feelings could easily change to feelings of worthlessness, sadness, and hopelessness during depressive episodes.</p>
    //                                     <p className="text-justify">Different forms of depression may have different triggers. Unlike most other types of depression, biological depression is caused by chemical imbalances in the body in the absence of any external trigger. Women are more susceptible to experiencing some form of mental illness, and are particularly vulnerable immediately after menopause because of the drastic changes in the body’s hormone levels. Different forms of the condition also vary in the duration for which they persist. Some types are episodic and are experienced infrequently. Others are more persistent, lasting for over two years at a time. Based on the type an individual is diagnosed with, the method of treatment also varies.</p>
    //                                     <p className="text-justify">Due to advances in diagnostic procedures and evaluation methods, it is now possible to diagnose depression in an individual at an earlier stage. Several tests, like the Beck Depression Inventory, can be used to determine whether the physical and emotional state of an individual can be attributed to some form of the illness. Because of this early detection, the efficacy of conventional treatment methods has also improved significantly.</p>
    //                                     <p className="text-justify">Increasing your awareness of this condition is the first step towards helping yourself or other individuals recover. It is also helpful to identify triggers that may cause depression in you, and then consciously avoiding contact with them.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Diabetes.png" alt="Recent" /><NavLink to="/blogs/diabetes">Diabetes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 16 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Psoriasis.png" alt="Recent" /><NavLink to="/blogs/psoriasis">Psoriasis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 17 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/ADHD.png" alt="Recent" /><NavLink to="/blogs/adhd">ADHD</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 20 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "diabetes") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Diabetes | DHPFinder</title>
    //                 <meta name="description" content="The onset of diabetes may happen because of a number of reasons. Read more here about the different types of diabetes and how to minimize the risk of developing it." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Diabetes.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 16 2024</span></label>
    //                                     <p className="text-justify">An alarmingly large number of people suffer from some form of diabetes. Understanding the causes behind diabetes is extremely important to help you minimize your risk of developing it. A family history of diabetes may increase your chances of developing this disease. Other than that, people who are overweight or who lead sedentary lifestyles are at a higher risk of developing diabetes.</p>
    //                                     <p className="text-justify">Diabetes mellitus (DM) comes in two forms. Type-1 DM is characterized by an inability of the body to produce insulin, due to damage in the pancreas. Insulin is pivotal in maintaining blood sugar levels in the body. Type-2 diabetes occurs when the body loses its ability to respond to normal insulin production. A third, less frequent type of the condition is called gestational diabetes, which occurs in pregnant women. During pregnancy, if a woman develops abnormally high blood glucose levels, it can influence the onset of type-2 DM.</p>
    //                                     <p className="text-justify">While there is no known cure for the disease, it is possible to keep it under control by following a strict diet plan, and avoiding all the potential risk factors which can aggravate the condition. For instance, reducing your carbohydrate intake is a good place to start. Including light exercise in your daily routine is also an effective measure to reduce blood sugar levels and keep your body healthy.</p>
    //                                     <p className="text-justify">If left untreated or undiagnosed, the high blood glucose levels in the body can make an individual vulnerable to other health complications. A person with either form of diabetes is at a higher risk for cardiovascular diseases and high blood pressure. This is because the excess carbohydrates in the body accumulate in the arterial walls, reducing blood flow and adding strain on the heart.</p>
    //                                     <p className="text-justify">Understanding the disease as comprehensively as possible can help you recognize the symptoms of diabetes and its onset in an individual. It is highly recommended that you visit your doctor immediately and get tested if you feel you are at risk, so that you may get timely treatment and prevent the disease from getting worse. Women in their third trimester of pregnancy should get tested and take precautionary measures to avoid the onset of gestational diabetes.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Depression.png" alt="Recent" /><NavLink to="/blogs/depression">Depression</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Psoriasis.png" alt="Recent" /><NavLink to="/blogs/psoriasis">Psoriasis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 17 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/ADHD.png" alt="Recent" /><NavLink to="/blogs/adhd">ADHD</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 20 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "psoriasis") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Psoriasis | DHPFinder</title>
    //                 <meta name="description" content="Psoriasis is a skin disease which may be triggered by a number of reasons. It may also lead to the development of psoriatic arthritis. Read more about this here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Psoriasis.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 17 2024</span></label>
    //                                     <p className="text-justify">Psoriasis is a fairly common skin condition, and can become quite a nuisance if left untreated. Normally characterized by scaly, red patches, there may be cases where psoriasis can cover large portions of your skin.</p>
    //                                     <p className="text-justify">Not many people are aware of this, but there are several variations of psoriasis. These vary based on the region of the body they impact and the severity of the symptoms. For instance, some forms of the condition affect your fingernails and toenails, which cause abnormal growth and make them increasingly brittle. Other variants are more common around the armpits or groin regions. Some also target the soft tissue in your mouth, and can be very painful if timely treatment is not given to the individual. In extreme cases, it is possible for the skin condition to trigger symptoms of arthritis (known as psoriatic arthritis), and influence the onset of conjunctivitis in the individual.</p>
    //                                     <p className="text-justify">In addition to the different types of psoriasis, some individuals are more vulnerable to contracting it than others. For instance, individuals who are overweight or those who sweat more, have greater chances of contracting inverse psoriasis. It is, therefore, very important to understand the risk factors, and also learn how to minimize them in order to avoid symptoms of psoriasis.</p>
    //                                     <p className="text-justify">Several factors can prompt the onset of psoriasis in an individual. Smoking, stress, and heavy alcohol consumption are only a few of the commonly known triggers. Lithium, a medicine commonly used to treat depression or mood disorders, can also cause the onset of this condition. Each of these factors can aggravate psoriasis to different extents, and these will determine which route of treatment you will have to take.</p>
    //                                     <p className="text-justify">Some forms of psoriasis treatment aim to disrupt the spread of the disease, while others work more aggressively to counter the symptoms and eliminate the disease from your body. Topical steroids will work on the skin to help reduce the spread of patches, while other medication needs to be taken orally to target the immune system’s ability to respond to the disease.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Depression.png" alt="Recent" /><NavLink to="/blogs/depression">Depression</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Diabetes.png" alt="Recent" /><NavLink to="/blogs/diabetes">Diabetes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 16 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/ADHD.png" alt="Recent" /><NavLink to="/blogs/adhd">ADHD</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 20 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "adhd") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>ADHD | DHPFinder</title>
    //                 <meta name="description" content="Symptoms of attention deficit-hyperactivity disorder (ADHD) may make it difficult for a child to lead a normal life. Read on for more details about the disorder." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/ADHD.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 20 2024</span></label>
    //                                     <h3>What is ADHD?</h3>
    //                                     <p className="text-justify">Attention deficit-hyperactivity disorder (ADHD), also referred to as hyperkinetic disorder at times, is a chronic disorder that is quite common in children. Children who have ADHD show an inability to pay attention, and may be impulsive or hyperactive to the extent that it starts to interfere with their normal functioning.</p>
    //                                     <h3>Causes of ADHD</h3>
    //                                     <p className="text-justify">The exact causes of the disorder cannot be pinpointed. It may be caused by a combination of factors. It is generally said to be associated with heredity, the central nervous system, and certain chemicals in the brain. Environmental factors may also increase the risk of a child developing the disorder. Use of alcohol during pregnancy, premature birth, and head injuries have also been speculated as triggers of the disorder.</p>
    //                                     <h3>Coping with ADHD</h3>
    //                                     <p className="text-justify">The inability to hold attention and focus on day to day activities can be quite daunting for a child with ADHD. Hyperactivity and impulsive behavior, characteristic of children with ADHD, may also cause learning difficulties and anxiety. Such children also tend to have more accidents and injuries. The child may even start to feel depressed and develop low-self esteem.</p>
    //                                     <p className="text-justify">Moreover, with ADHD, children may develop poor social skills which could put them at risk of developing adjustment problems later on. Seeking professional help is important so that the child can learn to effectively cope with ADHD.</p>
    //                                     <h3>Treatment</h3>
    //                                     <p className="text-justify">ADHD cannot be cured, but treatment can relieve some symptoms. It is a disorder that requires management of symptoms, and with efficient supervision, the child’s condition can improve.  Therefore, it is always recommended to seek professional help as soon as possible.</p>
    //                                     <p className="text-justify">The treatment of ADHD may be a combination of medications and therapies. A healthcare practitioner can best guide you on which combination works for the child. Therapies such as social skills training and behavior therapy may help children with ADHD, and may teach them how to overcome many obstacles.</p>
    //                                     <p className="text-justify">Family support is of great significance when it comes to helping a child with ADHD.  The child may need extra attention and affection, so showing appreciation for small things that a child may accomplish can greatly boost their self confidence. It is also very important to be patient with the child. With your help, the child can look forward to a better future.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Depression.png" alt="Recent" /><NavLink to="/blogs/depression">Depression</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Diabetes.png" alt="Recent" /><NavLink to="/blogs/diabetes">Diabetes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 16 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Psoriasis.png" alt="Recent" /><NavLink to="/blogs/psoriasis">Psoriasis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 17 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "rheumatoid-arthritis") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Rheumatoid Arthritis | DHPFinder</title>
    //                 <meta name="description" content="Rheumatoid arthritis (RA) is a chronic disease that affects the joints, which causes swelling and stiffness. For more on this type of arthritis, please read on." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Rheumatoid Arthritis.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 25 2024</span></label>
    //                                     <h3>What is Rheumatoid Arthritis?</h3>
    //                                     <p className="text-justify">Rheumatoid arthritis, also referred to as RA, is a type of arthritis that affects the joints; mostly in the hands and feet. In addition to causing inflammation of joints, it can also affect the surrounding tissues and other organs. Rheumatoid arthritis is more common in women than men, and can start to develop in individuals as young as 25 years old.</p>
    //                                     <p className="text-justify">It is an autoimmune disease, which means that the body’s own immune system mistakenly attacks the joint tissues. The immune cells release inflammation-causing chemicals in response that may damage the cartilage, leading to rheumatoid arthritis.</p>
    //                                     <h3>Symptoms of RA</h3>
    //                                     <p className="text-justify">The symptoms of RA include swelling, redness, or pain in joints that may make movement difficult.  At times, it can also cause fatigue, high temperature, and loss of appetite.</p>
    //                                     <h3>Causes of RA</h3>
    //                                     <p className="text-justify">Like many diseases, the causes of RA cannot be singled out. A family history of the disease may increase its risk. Apart from genes; environment, lifestyle, and hormones may also play a role in triggering the disease.</p>
    //                                     <h3>Diagnosis and Treatment</h3>
    //                                     <p className="text-justify">Rheumatoid arthritis is a chronic disease which can be treated and controlled but not cured. Over the years, treatment for RA has improved considerably, and can lessen the symptoms to help a person function normally. If not taken care of adequately, this disease can progress and worsen with time. It has been shown that early detection and aggressive treatment may put the disease in remission.</p>
    //                                     <p className="text-justify">The sooner it is diagnosed, the better the chances to control it. Therefore, it is best to seek help from an expert, especially a rheumatologist, immediately. To diagnose it, a rheumatologist may take a patient’s medical history and look for visible symptoms like stiffness and swelling in the joints. Blood tests and x-rays may be taken to evaluate the extent of the disease.</p>
    //                                     <p className="text-justify">The treatment a rheumatologist may give can be a combination of medication and therapies. Doctors generally suggest exercises and physical therapy as part of the treatment because it can help strengthen the affected muscles too. Surgery may be advised by a healthcare professional only if the damage done cannot be controlled by other treatment options.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Depression.png" alt="Recent" /><NavLink to="/blogs/depression">Depression</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Diabetes.png" alt="Recent" /><NavLink to="/blogs/diabetes">Diabetes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 16 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Psoriasis.png" alt="Recent" /><NavLink to="/blogs/psoriasis">Psoriasis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 17 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "cholesterol") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Cholesterol | DHPFinder</title>
    //                 <meta name="description" content="Cholesterol is essential for your body. It is the bad cholesterol (LDL) which must be avoided as it can cause heart diseases and stroke. Read more here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Cholesterol.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>February, 2 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Cholesterol</h4>
    //                                     <p className="text-justify">The term “cholesterol” usually makes your alarm bells start to ring, but it is important to know that cholesterol is not always bad. Our body needs cholesterol to build healthy cells. It is, however, high “bad” cholesterol (LDL) which increases the likelihood of developing a heart disease or stroke.</p>
    //                                     <p className="text-justify">Those with high LDL cholesterol level may develop fatty deposits in their blood vessels. The fatty deposits narrow the arteries, making it difficult for blood to flow. As a result, the heart may not get enough oxygen-rich blood, increasing the risk of a heart attack. Moreover, if the brain does not get its share of blood, a person may develop stroke.</p>
    //                                     <p className="text-justify">Despite the fact that high LDL cholesterol may be hereditary, the good news is that it is possible to reduce the likelihood of developing it. A healthy lifestyle, consisting of a healthy diet, exercise, and prescribed medication can help in the reduction of high LDL cholesterol.</p>
    //                                     <p className="text-justify">Your heart-healthy diet should include the following:</p>
    //                                     <ul>
    //                                         <li>1)Foods with added plant sterols or stanols - they help in blocking cholesterol absorption. Yogurt drinks, orange juice, and margarine with added plant sterols are good for you.</li>
    //                                         <li>2)Olive oil - is good for lowering your LDL cholesterol, while leaving your “good” cholesterol (HDL) untouched.</li>
    //                                         <li>3)Nuts - the use of almonds, walnuts, and other nuts can reduce high LDL cholesterol.</li>
    //                                         <li>4)Fish - the American Heart Association recommends that you eat at least two servings of fish (halibut, salmon, albacore tuna, sardines, herring, lake trout or mackerel) a week.</li>
    //                                         <li>5)High-fiber foods - oat bran and oatmeal are good for reducing your LDL. </li>
    //                                     </ul>
    //                                     <p className="text-justify">Regular exercise is good for maintaining healthy cholesterol levels. If your doctor agrees, 30 to 60 minutes of exercise each day for most days of the week may be good for overall health. Heavy exercise may not be advisable; however, swimming, bicycling, and brisk walks may be suitable.</p>
    //                                     <p className="text-justify">In addition, people who smoke are at a higher risk of developing cholesterol problems. Quitting smoking today will go a long way in helping boost your HDL levels. Those who have not smoked in the last 15 years have the same chances of developing a heart disease as those who have never smoked.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Depression.png" alt="Recent" /><NavLink to="/blogs/depression">Depression</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Diabetes.png" alt="Recent" /><NavLink to="/blogs/diabetes">Diabetes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 16 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Psoriasis.png" alt="Recent" /><NavLink to="/blogs/psoriasis">Psoriasis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 17 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "hiv") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>HIV | DHPFinder</title>
    //                 <meta name="description" content="HIV infection does not discriminate against age or sex. There is no cure for the viral infection although preventive measures may prevent initial infection." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/HIV.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>February, 2 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>HIV</h4>
    //                                     <p className="text-justify">According to the CDC, there are more than one million people living with the human immunodeficiency virus (HIV) in the United States. Someone gets infected with HIV in the United States every 9.5 minutes. The most disturbing fact is that one in five people infected with HIV do not know that they are infected. Therefore, it is important to understand the risk factors, symptoms, and treatment options for HIV infection.</p>
    //                                     <p className="text-justify">In general, HIV is not an age, sex, sexual orientation, or race-specific virus. It can affect anyone at any age. The likelihood of HIV infection increases with the presence of the following factors:</p>
    //                                     <ul>
    //                                         <li>1)Unprotected sex - the risk of infection further increases in those with multiple sexual partners</li>
    //                                         <li>2)Those with sexually transmitted infections are more likely to get HIV infection</li>
    //                                         <li>3)Those who share syringes and needles to use intravenous drugs are at a greater risk of HIV infection</li>
    //                                         <li>4)Uncircumcised men are at a greater risk of HIV infection that circumcised men</li>
    //                                     </ul>
    //                                     <p className="text-justify">Some of the many symptoms of HIV may not appear until up to 10 years after infection. During the time between infection and appearance of symptoms, an infected person is still capable of passing the virus onto others. HIV symptoms are essentially symptoms of others diseases because it compromises the immune system. Swollen lymph glands, sore throat, rashes, muscle aches or stiffness, yeast infection, mouth sores, headache, fever, fatigue, and diarrhea are some of the many symptoms of the human immunodeficiency virus infection.</p>
    //                                     <p className="text-justify">There is no permanent treatment or cure for HIV infection. However, through medications, the virus can be controlled. HIV treatment through drugs is difficult and demands a disciplined daily regimen. Multiple pills are taken at different times of the day, for the rest of the person’s life. The treatment is not without its side effects like:</p>
    //                                     <ul>
    //                                         <li>1)Bone death</li>
    //                                         <li>2)Weakened bones</li>
    //                                         <li>3)Skin rash</li>
    //                                         <li>4)Shortness of breath</li>
    //                                         <li>5)Abnormal heartbeats</li>
    //                                         <li>6)Nausea, diarrhea or vomiting</li>
    //                                     </ul>
    //                                     <p className="text-justify">Although HIV infection may not be permanently treatable, by avoiding the abovementioned risk factors, you can protect yourself from being infected with HIV.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Depression.png" alt="Recent" /><NavLink to="/blogs/depression">Depression</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Diabetes.png" alt="Recent" /><NavLink to="/blogs/diabetes">Diabetes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 16 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Psoriasis.png" alt="Recent" /><NavLink to="/blogs/psoriasis">Psoriasis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 17 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "arthritis") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Arthritis | DHPFinder</title>
    //                 <meta name="description" content="Arthritis is a serious condition affecting the joints and skeletal muscles in the body. To learn more about arthritis and its various kinds, click here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Arthritis.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 27 2024</span></label>
    //                                     <p className="text-justify">Arthritis is one of the leading causes of disabilities among Americans, with an estimated 50 million people suffering from this disability.  It causes immobility and severe pain in joints in the body such as finger joints, hips, shoulders, or knees. </p>
    //                                     <p className="text-justify">This debilitating condition prevents millions of people from enjoying a healthy and active lifestyle, including their sex lives. Contrary to myths about this ailment, this is not just a case of aches and pains, but is a complex disorder of skeletal joints and muscles. Also, it is not just a problem for the elderly. In fact, more than 65% of the patients suffering from arthritis are below the age of 65. Arthritis is a very serious problem, and it puts a large economic burden on the health care system. More than 44 million outpatient visits in the US are due to arthritis, and close to a million are hospitalized as a result.</p>
    //                                     <h3>What is Arthritis?</h3>
    //                                     <p className="text-justify">Arthritis is the inflammation of joints. While there are many kinds of arthritis, over a hundred different kinds, the most common kind is osteoarthritis. This is a degenerative condition which is the result of sustained wear and tear on the joints, either due to trauma or because of aging. Arthritis may also develop because of several other factors. Joint infections can lead to septic arthritis.  Onset of rheumatic disease can lead to rheumatoid arthritis. This is an autoimmune disease in which excess synovial fluid is produced along with the development of fibrous tissue, as a consequence of an inflammatory response by the body. A chronic skin condition called psoriasis can lead to psoriatic arthritis. Adolescents are usually affected by a kind of arthritis called juvenile arthritis which is also the result of an autoimmune disorder.  Lupus is another autoimmune disease which affects the joints.</p>
    //                                     <p className="text-justify">If you experience severe joint pains, it may be time to consult a physician about arthritis. There is medication and treatment which can help delay the degenerative changes caused by arthritis. There are, however, no short fixes, and this requires a commitment to healthy and cautious living to manage the damage already done and prevent further harm.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Depression.png" alt="Recent" /><NavLink to="/blogs/depression">Depression</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Diabetes.png" alt="Recent" /><NavLink to="/blogs/diabetes">Diabetes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 16 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Psoriasis.png" alt="Recent" /><NavLink to="/blogs/psoriasis">Psoriasis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 17 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "acne") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Acne | DHPFinder</title>
    //                 <meta name="description" content="Acne is a skin condition caused by follicle pores getting clogged, usually as a result of hormonal changes. To learn more about acne and its treatment, click here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Acne.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 29 2024</span></label>
    //                                     <p className="text-justify">Acne Vulgaris, or simply acne, is a skin condition which results in pimples, papules, nodules, cysts, white heads, and black heads.  It usually occurs most commonly on the face, upper back, and the chest, since these areas have the highest density of hair follicles.</p>
    //                                     <p className="text-justify">The follicles can get clogged by excess skin oil or dead skill cells causing the outward manifestation of acne.  White heads and black heads, also called comedones, are generally non-inflammatory lesions.  When the surface of the comedone is open, the oil and dead skin cells are visible as a dark dot, which is why open comedones are called blackheads. Closed comedones are called whiteheads. Pimples, papules, nodules and cysts are classified as inflammatory lesions.</p>
    //                                     <p className="text-justify">Acne is commonly caused by a set of hormones called androgens, which cause glands beneath the hair follicles to enlarge and produce more oil, called sebum.  Hormones may also cause excessive skin oil to be produced. These factors provide fertile ground for bacteria to multiply. This leads to frequent acne flare-ups. Also, skin pores or follicle openings can get severely clogged by dead skin cells or oils, causing it to inflame and become a cyst or nodule. Cysts are generally the most painful, and are often likened to a volcano because of the buildup of puss underneath. Contrary to myth, popping pimples, if done correctly, can aid in healing.  Dirty skin or stress may be aggravating factors, but cannot cause acne on their own.  Certain studies indicate that dietary factors may also be aggravating triggers.</p>
    //                                     <p className="text-justify">Acne usually occurs in adolescents, and most individuals outgrow the disease some time during or after puberty. However, there are many young adults who continue to struggle with it throughout their 20s.  Acne may also appear when hormonal changes are at a high. For example, some women may experience acne during monthly menstrual periods or during pregnancy.</p>
    //                                     <p className="text-justify">All treatments should be recommended by a dermatologist, a doctor who specializes in skin ailments. The most common treatment of acne is the use of benzoyl peroxide, which kills the acne bacteria. In more severe cases, isotretinoin may be prescribed, which can lead to a remission in the acne condition.  Other topical treatments may include tretinoin, adapalene, and tazarotene. Antibiotics may also be prescribed by dermatologists for skin treatment. Certain oral contraceptives for women have also led to clearing up of acne.  Laser and light therapy is also known to be beneficial. A dermatologist will work with you to help find a treatment that is suitable for you. So make your appointment with a dermatologist today.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Depression.png" alt="Recent" /><NavLink to="/blogs/depression">Depression</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Diabetes.png" alt="Recent" /><NavLink to="/blogs/diabetes">Diabetes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 16 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Psoriasis.png" alt="Recent" /><NavLink to="/blogs/psoriasis">Psoriasis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 17 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "osteoporosis") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Osteoporosis | DHPFinder</title>
    //                 <meta name="description" content="Although anyone may develop osteoporosis, women and people over 50 are at a higher risk. Read on for more risk factors and the treatment for this disease." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Osteoporosis.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>February, 5 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Osteoporosis</h4>
    //                                     <p className="text-justify">Almost 34 million Americans are suffering from osteoporosis. Osteoporosis is a bone disease in which the bones become very fragile and are often referred to as ‘porous bones’. The brittleness of bones may lead to fractures when performing routine activities. Hip and spine fractures are common injuries for people who have this disease. Also, while the disease can start at any age, it is more common in women and the elderly.</p>
    //                                     <h4>Symptoms of Osteoporosis</h4>
    //                                     <p className="text-justify">Many people may be losing bone mass and never realize it until they have an unexpected fracture. This is why osteoporosis is sometimes referred to as a silent disease. Osteoporosis may also cause a vertebra to collapse, which may cause a person to become shorter, experience severe back pain, and curving of the spine so that you develop a stooping posture.</p>
    //                                     <h4>Risk Factors for Developing Osteoporosis</h4>
    //                                     <p className="text-justify">Although anyone can get osteoporosis and at any time, you should consider visiting your doctor soon if you can identify any risk factors from the following:</p>
    //                                     <ul>
    //                                         <li>1)Family history of osteoporosis</li>
    //                                         <li>2)An age above 50</li>
    //                                         <li>3)Early menopause</li>
    //                                         <li>4)Unexpected fracture</li>
    //                                         <li>5)Loss of height</li>
    //                                         <li>6)Taking corticosteroids for a long period of time</li>
    //                                         <li>7)Smoking and excessive alcohol consumption</li>
    //                                         <li>8)You are white or Asian</li>
    //                                         <li>9)Smaller body frame</li>
    //                                         <li>10)Overactive thyroid</li>
    //                                         <li>11)Sedentary lifestyle</li>
    //                                         <li>12)A diet insufficient to meet the daily calcium requirements</li>
    //                                     </ul>
    //                                     <p className="text-justify">Women should have their calcium levels and bone density checked periodically to gauge their chances of developing this disease.</p>
    //                                     <h4>Management and Treatment</h4>
    //                                     <p className="text-justify">Patients with osteoporosis may be given either antiresorptive medicines or anabolic medicines. The former works by slowing down the loss of bone while the latter medication type helps in increasing bone formation. Your doctor will work with you to choose the best medicine for you; keeping in mind your medical history and extent of the disease. Your doctor will also decide which drug will cause the least number of serious side effects for you.  Along with these drugs, it is also very important to exercise regularly and eat a balanced diet. Consult your doctor before you start an exercise regimen to discuss its suitability for your bones and posture. Also, be patient with your medicines. Do not stop taking them even if you feel they are not helping; instead, talk to your doctors to discuss your issues with the medicines.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Depression.png" alt="Recent" /><NavLink to="/blogs/depression">Depression</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Diabetes.png" alt="Recent" /><NavLink to="/blogs/diabetes">Diabetes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 16 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Psoriasis.png" alt="Recent" /><NavLink to="/blogs/psoriasis">Psoriasis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> January, 17 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "multiple-sclerosis") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Multiple Sclerosis | DHPFinder</title>
    //                 <meta name="description" content="Multiple sclerosis occurs when the protective layer of nerves is damaged. This may lead to a wide range of symptoms. Read on for more information." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Multiple Sclerosis.jpg" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>February, 15 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Understanding Multiple Sclerosis</h4>
    //                                     <p className="text-justify">Multiple sclerosis (MS) is a chronic disease that affects the nervous system. It is also sometimes referred to as disseminated sclerosis. It affects the nerves of the brain and the spinal cord.</p>
    //                                     <p className="text-justify">In this disease, a covering of the nerves called the myelin is damaged. Myelin is supposed to protect the nerves but when it is destroyed, the nerves may be damaged. Myelin damage may be caused by inflammation which occurs when the body’s own immune cells starts attacking the nervous system.</p>
    //                                     <p className="text-justify">Nerves are responsible for carrying messages to and from the brain to other parts of the body, so damage to these may disturb the transmission of these messages. Therefore, impulses transmitted by the demyelinated (with myelin removed) nerves may become slower or may be blocked completely.</p>
    //                                     <p className="text-justify">The more nerves that are affected, then more damage will occur to the functions of the body that are handled by the nervous system. Symptoms of MS show up when the nerves are unable to deliver messages to the brain.</p>
    //                                     <h4>Symptoms of MS</h4>
    //                                     <p className="text-justify">The common symptoms of MS are:</p>
    //                                     <ul>
    //                                         <li>1)Walking, balance, or coordination problems</li>
    //                                         <li>2)Difficulty in writing</li>
    //                                         <li>3)Memory loss</li>
    //                                         <li>4)Numbness</li>
    //                                         <li>5)Emotional changes</li>
    //                                         <li>6)Fatigue</li>
    //                                         <li>7)Vision problems</li>
    //                                         <li>8)Speech problems</li>
    //                                     </ul>
    //                                     <p className="text-justify">The severity of the disease depends on where the damage has taken place and which functions it has affected. This is the reason why symptoms of MS will vary from person to person, and also fluctuate as the myelin repairs or is damaged further.</p>
    //                                     <h4>Causes of MS</h4>
    //                                     <p className="text-justify">Causes of MS are not quite clear. It is mostly related to an immune system disorder, but researchers also focus on genetic reasons and other environmental factors that may trigger it.</p>
    //                                     <h4>MS and encephalomyelitis disseminate (ADEM)</h4>
    //                                     <p className="text-justify">At times MS is confused with encephalomyelitis disseminate (ADEM), since both may have similar symptoms. In ADEM, there is an inflammation in the brain and spinal cord that damages the white matter or myelin. It is an acute attack.</p>
    //                                     <p className="text-justify">The fact that it mostly follows infections differentiates it from MS. Also, the symptoms are more rapid. Moreover, ADEM mostly affects children, while MS affects adults, mostly those between the ages of 20 to 50 years.  There is no cure for MS, but the condition is treatable and there are several options for treatment available.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Alcohol.jpg" alt="Recent" /><NavLink to="/blogs/alcohol">Alcohol</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/asthma.jpg" alt="Recent" /><NavLink to="/blogs/asthma">Asthma</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Breast Cancer.jpg" alt="Recent" /><NavLink to="/blogs/breast-cancer">Breast Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 17 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "alcohol") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Alcohol | DHPFinder</title>
    //                 <meta name="description" content="Drinking alcohol may be accepted as a social activity, but too many people ignore the negative effects on the body. Learn more about these and prevention methods." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Alcohol.jpg" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>February, 15 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <p className="text-justify">Even though most college students are under 21 and not allowed to legally drink, college students remain one of the groups with the highest consumption of alcohol. Tragically, not all college students are careful about the quantities that they consume, often exceeding the daily recommended guidelines of 3 to 4 units for men and 2 to 3 units for women. As a result, this social activity has the potential to become very hazardous to student health.</p>
    //                                     <p className="text-justify">Drinking alcohol in excess on a regular basis has a series of negative effects on the body. It causes fatigue by disrupting the pathways in the brain, making the consumer less alert and altering his or her mood. It causes a stiffening of the arteries and blood vessels in the body, leading to high blood pressure and an increased risk of heart diseases. It also places high stress on the liver, causing it to accumulate excess fat or induce tissue death, conditions known as steatosis and cirrhosis respectively.</p>
    //                                     <p className="text-justify">The list does not stop here. Drinking certain concentrated forms of alcohol like vodka also negatively impacts the pancreas and the immune system. This makes the individual more prone to illnesses, and severely impacts their digestion. Because of the influence on the brain’s pathways, alcohol also has the potential to induce depression in individuals. Since these conditions take a long time to develop and manifest themselves fully, they are overlooked by students drinking at parties or within their social groups.</p>
    //                                     <p className="text-justify">What is apparent though is a marked decrease in a student’s alertness and concentration towards his education. Roughly 25 percent of college-going students face difficulty in maintaining their academic standing due to alcohol-related problems of fatigue, a decrease mental acuity, and a compromised immune system. A significant percentage of students who have attempted suicide have listed alcohol as one of the reasons.</p>
    //                                     <p className="text-justify">Developing healthier drinking habits, like avoiding binge drinking (having more than 5 consecutive drinks in one sitting) and monitoring your intake are ways of reducing your risk for any health issues. Sticking to the recommended consumption guidelines for both men and women is also highly effective in doing so. Alcohol negatively affects everyone equally, regardless of age or sex, which is why it must be dealt with cautiously.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Multiple Sclerosis.jpg" alt="Recent" /><NavLink to="/blogs/multiple-sclerosis">Multiple Sclerosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/asthma.jpg" alt="Recent" /><NavLink to="/blogs/asthma">Asthma</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Breast Cancer.jpg" alt="Recent" /><NavLink to="/blogs/breast-cancer">Breast Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 17 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "asthma") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Asthma | DHPFinder</title>
    //                 <meta name="description" content="Asthma can be a minor nuisance or a major interference in an individual’s life. Learn more about the causes of the condition and ways to manage it by reading here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Asthma.jpg" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>February, 15 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <p className="text-justify">Asthma is the chronic inflammation of airways in the lungs, which can affect people of any age or sex. It is characterized by difficulty in breathing, which is due to constriction of the airways in the lungs. This constriction can be caused by a variety of factors which do not have to occur all at once. The inflammation also varies in its intensity among people. For some, it is a minor problem which can even be ignored; for others though, it can have a severe negative effect on their lifestyle.</p>
    //                                     <p className="text-justify">There are many triggers for an asthma attack. Some individuals have a reaction to allergens in the air, such as pollen or dust. Pollutants like smoke or an allergic reaction to food like shellfish can also induce asthma in some individuals. In some cases, this condition can also be a symptom of gastro esophageal relux disease (GERD), where stomach acids back up into your throat causing irritation and difficulty in breathing. Some individuals are also sensitive enough to have an asthmatic episode upon sudden exposure to cold air.</p>
    //                                     <p className="text-justify">Individuals with a family history of asthma are more likely to develop the condition. It is also noticed that smokers and people who come into regular contact with chemicals and pollutants are more susceptible to it.</p>
    //                                     <p className="text-justify">Unfortunately, there is no definite cure for asthma. However, it is possible to reduce your risk of certain factors from triggering your asthma, which can help you gain control over the condition. Taking steroids orally or intravenously tends to help reduce inflammation in the airways, allowing for easier breathing. Based on the severity of your asthma, the kind of steroidal medicine prescribed to you may vary in its duration of effect. Individuals with more extreme cases are prescribed longer-acting medications in order to be effective over more time. Many doctors also recommend getting tested for allergies and immediately seeking treatment to help control your reaction to them, since allergies may be a leading trigger for asthma.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Multiple Sclerosis.jpg" alt="Recent" /><NavLink to="/blogs/multiple-sclerosis">Multiple Sclerosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/alcohol.jpg" alt="Recent" /><NavLink to="/blogs/alcohol">Alcohol</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Breast Cancer.jpg" alt="Recent" /><NavLink to="/blogs/breast-cancer">Breast Cancer</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 17 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "breast-cancer") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Breast Cancer | DHPFinder</title>
    //                 <meta name="description" content="Breast cancer can be intimidating if not understood properly. Treatments exist which can help you gain control over the condition. Read on to learn more." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Breast Cancer.jpg" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>February, 17 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <p className="text-justify">Breast cancer is a highly common cancer in the United States and around the world. Breast cancer results from an abnormal growth of cells within the breast. It is also possible for men to develop breast cancer, even though it is much more common in women.</p>
    //                                     <p className="text-justify">There are two types of breast cancer, and they are based on the region where they form. In women, cancers beginning in the milk ducts of the breast tend to occur in the highest numbers. Another type of cancer occurs in the lobules of the breast, which are responsible for producing milk. If left unchecked, it is possible for the cancer to spread to other tissues in the breast. Nevertheless, improvements in treatment and diagnostic methods for breast cancer have contributed to reducing the mortality rate.</p>
    //                                     <p className="text-justify">Several risk factors make individuals more susceptible to cancer than others. Having a family history of breast cancer puts you at greater chance of developing the condition. With increasing age, the chance of breast cancer occurring in women also rises significantly. In both men and women who are overweight, fat tissues (or the adipose tissue) produce excess amounts of estrogen. Cancerous formations may be triggered by estrogen, which causes an acceleration of their growth and puts overweight individuals at higher risk.</p>
    //                                     <p className="text-justify">Based on which stage the breast cancer is in when diagnosed, the treatment that doctors will recommend will vary. In some cases, removing the cancerous tissue from the breast, or removing the entire breast, will prevent it from spreading. In other cases, doctors may recommend radiation therapy or chemotherapy, which kills the cancerous tissue without the need for any surgical procedures. While these treatment routes may be effective in reducing the spread of cancer, they are sometimes not enough to eliminate the cancer. It is possible for an individual to develop breast cancer again at a later time. As a result, following up with your doctor on a regular basis is important.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Multiple Sclerosis.jpg" alt="Recent" /><NavLink to="/blogs/multiple-sclerosis">Multiple Sclerosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/alcohol.jpg" alt="Recent" /><NavLink to="/blogs/alcohol">Alcohol</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/asthma.jpg" alt="Recent" /><NavLink to="/blogs/asthma">Asthma</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "cancer") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Cancer | DHPFinder</title>
    //                 <meta name="description" content="Cancer can be deadly, but some types may be treatable. Read on to get more information about cancer." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Cancer.jpg" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>February, 20 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Cancer</h4>
    //                                     <p className="text-justify">Cancer is a disease where there are abnormal cells that are uncontrollably dividing and invading and destroying body tissue. This creates an abnormal mass of tissue called neoplasm. Neoplasm may be benign or malignant. Malignant neoplasm is a tumor that is cancerous.</p>
    //                                     <p className="text-justify">Cancer affects all races, ages, and sexes. Anyone can be diagnosed with cancer. The following however may increase the likelihood of cancer development:</p>
    //                                     <ul>
    //                                         <li>1)Environment</li>
    //                                         <li>2)Health conditions</li>
    //                                         <li>3)Family history</li>
    //                                         <li>4)Habits</li>
    //                                         <li>5)Age</li>
    //                                     </ul>
    //                                     <p className="text-justify">Since cancer does not target a specific person, you should be aware of the various symptoms associated with cancer. However, keep in mind that cancer symptoms will differ from one type of cancer to another.</p>
    //                                     <ul>
    //                                         <li>1)Lump under the skin</li>
    //                                         <li>2)Unexplained and persistent night sweats or fevers</li>
    //                                         <li>3)Unexplained and persistent joint or muscle pain</li>
    //                                         <li>4)Persistent indigestion after eating</li>
    //                                         <li>5)Hoarseness</li>
    //                                         <li>6)Difficulty swallowing</li>
    //                                         <li>7)Persistent cough</li>
    //                                         <li>8)Changes in bladder or bowel habits</li>
    //                                         <li>9)Sores that won’t heal</li>
    //                                         <li>10)Unexplained changes in skin color</li>
    //                                         <li>11)Unexplained weight gain or loss</li>
    //                                         <li>12)Fatigue</li>
    //                                     </ul>
    //                                     <p className="text-justify">Someone diagnosed with cancer must not believe that his/her life is over. Cancer is more treatable today than ever before due to the advancements in medicine. The numbers of those who have survived cancer are higher. Various treatments are available. Not all of them may be able to put the cancer in remission, but they may successfully treat the symptoms of cancer, making the person’s life as normal as possible. The following are some of the few examples of types cancer treatment:</p>
    //                                     <ul>
    //                                         <li>1)Surgery</li>
    //                                         <li>2)Chemotherapy</li>
    //                                         <li>3)Targeted drug therapy</li>
    //                                         <li>4)Hormone therapy</li>
    //                                         <li>5)Biological therapy</li>
    //                                         <li>6)Stem cell transplant</li>
    //                                         <li>7)Radiation therapy</li>
    //                                     </ul>
    //                                     <p className="text-justify">Cancer may not be completely preventable; however, by adopting the following suggestions, you may decrease the likelihood of cancer development:</p>
    //                                     <ul>
    //                                         <li>1)Follow an active and healthy lifestyle</li>
    //                                         <li>2)Quit smoking</li>
    //                                         <li>3)Periodic cancer screening exams</li>
    //                                         <li>4)Should you chose to drink alcohol, drink in moderation only</li>
    //                                         <li>5)Maintain a healthy weight</li>
    //                                         <li>6)Avoid excessive sun exposure</li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Multiple Sclerosis.jpg" alt="Recent" /><NavLink to="/blogs/multiple-sclerosis">Multiple Sclerosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/alcohol.jpg" alt="Recent" /><NavLink to="/blogs/alcohol">Alcohol</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/asthma.jpg" alt="Recent" /><NavLink to="/blogs/asthma">Asthma</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "copd") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>COPD | DHPFinder</title>
    //                 <meta name="description" content="COPD is a collection of lung diseases which cannot be cured. Although COPD is a leading cause of death in the United States, it can be prevented." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>COPD</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/COPD.jpg" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>February, 22 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>COPD</h4>
    //                                     <p className="text-justify">COPD, or Chronic Obstructive Pulmonary Disease, is a serious respiratory disease, which if left untreated could exacerbates and cause severe health issues. According to the American Lung Association, COPD is the third leading cause of death in the United States. The disease cannot be permanently cured, but it is preventable.</p>
    //                                     <p className="text-justify">Frequent and long-term smokers of cigarettes, cigars, pipes, and marijuana are likely to develop this disease. People with asthma who smoke are also at a greater risk of developing COPD. Chemical fumes, dust, and in the rare case, a genetic disorder, may also increase the likelihood of development of COPD. People aged 35-40, who have been smoking for a long time may start to show COPD symptoms.</p>
    //                                     <p className="text-justify">Symptoms of COPD are shortness of breath; chest tightness; lack of energy; frequent respiratory infections; wheezing; a chronic cough that produces greenish, white, clear, or yellow sputum. It is important that these symptoms are not overlooked and ignored.</p>
    //                                     <p className="text-justify">It is worth remembering that COPD is not just one disease but a collection of lung diseases.  Chronic Obstructive Airway Disease and Chronic Obstructive Lung Disease are two forms of COPD. The disease, as mentioned earlier, cannot be permanently cured. However, there are treatment options available to keep the person’s life as normal as possible. Quitting smoking is one of the first steps when treating COPD. Furthermore, lung therapies in the form of pulmonary rehabilitation program or oxygen therapy may also be prescribed. Surgery may also be an option, but only in rare cases. Medications are often used to manage COPD. Antibiotics, Theophylline, Phosphodiesterase-4 inhibitors, oral steroids, combination inhalers, inhaled steroids, and Bronchodilators may be prescribed by the doctors.</p>
    //                                     <p className="text-justify">The disease can be prevented. Not smoking is an important preventative measure. Those who are more at risk to inhaling workplace chemical fumes and dust should use protective respiratory equipment to avoid unnecessary exposure.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Multiple Sclerosis.jpg" alt="Recent" /><NavLink to="/blogs/multiple-sclerosis">Multiple Sclerosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/alcohol.jpg" alt="Recent" /><NavLink to="/blogs/alcohol">Alcohol</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/asthma.jpg" alt="Recent" /><NavLink to="/blogs/asthma">Asthma</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "erectile-dysfunction") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Erectile Dysfunction | DHPFinder</title>
    //                 <meta name="description" content="Erectile dysfunction can be a troubling condition to experience, and a sign of other health complications. Read on to learn more about causes and treatment." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Erectile Dysfunction.jpg" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>February, 28 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Erectile Dysfunction</h4>
    //                                     <p className="text-justify">Erectile dysfunction (ED) is a condition in which a man faces difficulty in getting or maintaining an erection. While it may be embarrassing to admit, it is not unusual condition that men may experience at some point in their life. However, for men experiencing this as an on-going problem, it could be a sign for underlying health problems, and it is recommended that you consult with a healthcare professional soon.</p>
    //                                     <p className="text-justify">There are several different causes for erectile dysfunction in men. Some of these factors are psychological and a consequence of stress or anxiety. Stress can reduce sexual pleasure and adversely affect sexual performance.  As a result, maintaining an erection becomes difficult. Sometimes, the contributing factor can even be a problem within the relationship itself.</p>
    //                                     <p className="text-justify">In other cases, ED can be one of the symptoms of other serious health problems. A narrowing of the blood vessels in the body – a condition known as atherosclerosis – can also occur in the arteries of the penis. This decreased blood flow may cause difficulty in getting an erection. Atherosclerosis is caused by a high cholesterol diet, and is a risk factor for cardiovascular disease. Men who drink in excess or smoke are also prone to experiencing erection difficulties, since alcohol and nicotine also restrict blood flow in the body. Some skin conditions, like scleroderma – a hardening of the skin – can also contribute towards ED in men.</p>
    //                                     <p className="text-justify">While it is not a topic men may be comfortable discussing with a doctor, going in for an evaluation is definitely a good idea. Treatment for the condition does not require any drastic steps. In the event that your erectile dysfunction is a symptom of another health problem, it is important to identify the cause as early as possible in order to start treatment in time to avoid further complications. If the cause for your condition is deemed to be psychological, doctors often recommend therapy and tips to reduce stress and anxiety to help work through your problem.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Multiple Sclerosis.jpg" alt="Recent" /><NavLink to="/blogs/multiple-sclerosis">Multiple Sclerosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/alcohol.jpg" alt="Recent" /><NavLink to="/blogs/alcohol">Alcohol</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/asthma.jpg" alt="Recent" /><NavLink to="/blogs/asthma">Asthma</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "plastic-surgery") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Plastic Surgery | DHPFinder</title>
    //                 <meta name="description" content="Plastic surgery operations are performed for reconstructive or cosmetic purposes. They can be both dangerous and expensive. Read the article for more details." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Plastic Surgery.jpg" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 2 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Plastic Surgery</h4>
    //                                     <p className="text-justify">A specialized medical field, plastic surgery has grown in recent years. Generally speaking, plastic surgery procedures are performed for two reasons; reconstructive and cosmetic. The American Society of Plastic Surgeons (ASPS) reported that there were more than 5.6 million reconstructive plastic surgeries in 2012. According to the American Society for Aesthetic Plastic Surgery (ASAPS), there were more than 1.6 million surgical cosmetic procedures in 2012.</p>
    //                                     <p className="text-justify">Reconstructive plastic surgery is performed to help correct body parts that may have been damaged by an accident, an illness, or from a birth defect. The following are some of the many examples of reconstructive surgery:</p>
    //                                     <ul>
    //                                         <li>1)Skin expansion</li>
    //                                         <li>2)Head and neck reconstruction</li>
    //                                         <li>3)Flap reconstruction</li>
    //                                         <li>4)Extremity reconstruction</li>
    //                                         <li>5)Chest wall reconstruction</li>
    //                                         <li>6)Body contouring</li>
    //                                         <li>7)Breast reconstruction</li>
    //                                         <li>8)Abdominal wall reconstruction</li>
    //                                     </ul>
    //                                     <p className="text-justify">Cosmetic or aesthetic surgery is performed because a person may want to change the way they look. Cosmetic surgery is performed for a whole variety of reasons like:</p>
    //                                     <ul>
    //                                         <li>1)Breast augmentation</li>
    //                                         <li>2)Skin resurfacing</li>
    //                                         <li>3)Rhinoplasty</li>
    //                                         <li>4)Liposuction</li>
    //                                         <li>5)Injectables and fillers</li>
    //                                         <li>6)Forehead and brow lift</li>
    //                                         <li>7)Face lift</li>
    //                                         <li>8)Face contour</li>
    //                                         <li>9)Brachioplasty</li>
    //                                         <li>10)Blepharoplasty</li>
    //                                         <li>11)Abdominoplasty</li>
    //                                     </ul>
    //                                     <p className="text-justify">Cosmetic surgeries are performed more out of choice than necessity, while reconstructive surgeries are performed mainly out of necessity. Some of the most popular cosmetic surgeries according to the latest statistics from the ASAPS are:</p>
    //                                     <ul>
    //                                         <li>1)Breast augmentation</li>
    //                                         <li>2)Liposuction</li>
    //                                         <li>3)Weight loss related surgeries</li>
    //                                         <li>4)Gynecomastia (male breast reduction)</li>
    //                                         <li>5)Nose jobs</li>
    //                                         <li>6)Facelifts</li>
    //                                     </ul>
    //                                     <p className="text-justify">Like other surgical procedures, plastic surgery operations may go wrong. Cosmetic surgery could leave a person in a worst shape than before. Therefore, to minimize the risks of unwanted outcomes and help make your plastic surgery safer, the ASPS recommends that you select a surgeon whom you trust. Furthermore, the surgeon should have the following qualifications:</p>
    //                                     <ul>
    //                                         <li>1)The doctor must be experienced in plastic surgery procedures</li>
    //                                         <li>2)The doctor must be certified by The American Board of Plastic Surgery</li>
    //                                         <li>3)The doctor follows a strict code of ethics</li>
    //                                         <li>4)The doctor operates in accredited medical facilities only</li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Multiple Sclerosis.jpg" alt="Recent" /><NavLink to="/blogs/multiple-sclerosis">Multiple Sclerosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/alcohol.jpg" alt="Recent" /><NavLink to="/blogs/alcohol">Alcohol</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/asthma.jpg" alt="Recent" /><NavLink to="/blogs/asthma">Asthma</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "prostate-cancer") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Prostate Cancer | DHPFinder</title>
    //                 <meta name="description" content="Prostate cancer is a serious health issue and may not be preventable. However, those who are diagnosed with it do not always die as treatment options are available." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Prostate Cancer.jpg" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 5 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Prostate Cancer</h4>
    //                                     <p className="text-justify">Prostate cancer is one of the most commonly found cancers in men. The cancer, if left untreated, can kill. According to the National Cancer Institute’s latest statistics, there are more than 238,000 new cases of prostate cancer in the United States, out of which more than 29,000 have already succumbed to the disease and died. Therefore, it is important to understand this cancer’s symptoms, and to take the necessary steps at the right time.</p>
    //                                     <p className="text-justify">The following may be the most commonly reported symptoms of prostate cancer:</p>
    //                                     <ul>
    //                                         <li>1)Trouble urinating</li>
    //                                         <li>2)Blood in the urine</li>
    //                                         <li>3)Decreased force in the stream of urine</li>
    //                                         <li>4)Blood in the semen</li>
    //                                         <li>5)Discomfort in the pelvic area</li>
    //                                         <li>6)Swelling in the lungs</li>
    //                                         <li>7)Bone pain</li>
    //                                     </ul>
    //                                     <p className="text-justify">In addition to the symptoms, it is important to know why prostate cancer may develop. The following are some of the risk factors for this cancer:</p>
    //                                     <ul>
    //                                         <li>1)Age - men older than 65 years of age may experience the symptoms of the cancer. The likelihood of the development of the cancer is higher in older men than younger men.</li>
    //                                         <li>2)Obesity - prostate cancer diagnosed in over-weight men is not a good sign. The cancer is likely to be in its later stages and harder to treat.</li>
    //                                         <li>3)Family history - those whose male relatives have been diagnosed with prostate cancer must look out for the cancer’s symptoms as they are at a greater risk of developing the cancer.</li>
    //                                         <li>4)Race - the cancer is diagnosed in men of all races; however, it is more common in African-American men than any other racial group. The cancer in African-American men is found in its advanced stages.</li>
    //                                     </ul>
    //                                     <p className="text-justify">The cancer may not be preventable, but you can reduce the risk of developing prostate cancer significantly by:</p>
    //                                     <ul>
    //                                         <li>1)Adopting a healthy lifestyle - changing to a healthier diet and exercising on most days of the week.</li>
    //                                         <li>2)Maintaining a healthy weight.</li>
    //                                         <li>3)Talking to your doctor about the cancer.</li>
    //                                     </ul>
    //                                     <p className="text-justify">Once diagnosed, not all men will undergo the same type of treatment. The treatment options available are radiation, hormone therapy, chemotherapy, and surgery. The type of treatment will depend on the cancer’s prognosis such as the speed with which it is spreading or the affected person’s health.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Multiple Sclerosis.jpg" alt="Recent" /><NavLink to="/blogs/multiple-sclerosis">Multiple Sclerosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/alcohol.jpg" alt="Recent" /><NavLink to="/blogs/alcohol">Alcohol</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/asthma.jpg" alt="Recent" /><NavLink to="/blogs/asthma">Asthma</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "weight-loss") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Weight Loss | DHPFinder</title>
    //                 <meta name="description" content="Obesity is a major health issue, and may even lead to fatal diseases. Therefore, weight loss for maintaining a healthy lifestyle is important." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Weight Loss.jpg" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 8 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Weight Loss</h4>
    //                                     <p className="text-justify">A healthy lifestyle can prevent you from a whole variety of diseases. Maintaining a healthy weight is an integral part of a healthy lifestyle regimen. Over-weight or obese people are prone to developing multiple diseases and disorders, including fatal diseases.</p>
    //                                     <p className="text-justify">Weight loss may be achieved if you burn excess calories in your diet. One way to do this is to consume fewer calories by eating healthy foods and burn calories through physical exercises or activities.</p>
    //                                     <p className="text-justify">There are a lot of companies advertising weight loss programs. However, it is important to find the program that best suits you. When deciding on a particular weight loss program, the following points must be kept in mind:</p>
    //                                     <ul>
    //                                         <li>1)Is regular physical activity part of the program?</li>
    //                                         <li>2)Is the program safe and effective?</li>
    //                                         <li>3)Does the program include proper amounts of calories and nutrients to maintain your health while you lose weight?</li>
    //                                         <li>4)Does it fit your lifestyle?</li>
    //                                         <li>5)Is it cost-effective; can you afford it?</li>
    //                                         <li>6)Does it allow you to eat your favorite foods?</li>
    //                                         <li>7)Does the program include healthy foods like vegetables, fruits, grains, seeds, nuts, lean protein sources, and low-fat dairy products?</li>
    //                                         <li>8)Can you eat the foods proposed by the program for the rest of your life and not just for a few days or weeks?</li>
    //                                         <li>9)The foods proposed by the program, are they easily available in your local market?</li>
    //                                     </ul>
    //                                     <p className="text-justify">If your answer is yes to all the abovementioned questions, the weight loss program could be a good program for you. Otherwise keep looking until you find the right weight loss program.</p>
    //                                     <p className="text-justify">A healthy diet is only a part of a healthy lifestyle. A healthy lifestyle includes physical activity or exercise as well. Physical activity does not necessarily mean a heavy duty fitness program at the gym. Gardening, mowing, shopping, making the bed, and cleaning the house are some of the many examples of physical activity. However, there is no physical activity quite as effective as exercising, which when coupled with the right diet, can help you lose weight effectively and in a healthier way.</p>
    //                                     <p className="text-justify">Remember that there are no quick fixes or shortcuts to weight loss; it is a lifetime commitment.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Multiple Sclerosis.jpg" alt="Recent" /><NavLink to="/blogs/multiple-sclerosis">Multiple Sclerosis</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/alcohol.jpg" alt="Recent" /><NavLink to="/blogs/alcohol">Alcohol</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/asthma.jpg" alt="Recent" /><NavLink to="/blogs/asthma">Asthma</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Feb, 15 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "causes-of-copd-exacerbation") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Causes of COPD Exacerbation | DHPFinder</title>
    //                 <meta name="description" content="There are several causes of COPD exacerbation, and knowing about these can help you mitigate and even manage your episodes. Read on to learn more about these here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>Causes of COPD Exacerbation</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/COPD Exacerbation Causes.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 9 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Causes of COPD Exacerbation</h4>
    //                                     <p className="text-justify">COPD is a serious respiratory condition which affects over 30 million Americans. The disease tends to progress over time, and is not sudden in its onset. According to the American Lung Association, COPD is the third leading cause of death in the United States, so it is important to know the causes and risk factors.</p>
    //                                     <h5>Symptoms of COPD may become more severe, and this state is known as COPD exacerbation. There are several causes of COPD exacerbation:</h5>
    //                                     <ul>
    //                                         <li>1)Respiratory infections caused by bacteria and/or viruses</li>
    //                                         <li>2)Exposure to irritants like environmental pollution</li>
    //                                         <li>3)Increased anxiety or stress</li>
    //                                         <li>4)Heart disease</li>
    //                                         <li>5)Fireplace or barbeque smoke</li>
    //                                         <li>6)A sudden change in temperature</li>
    //                                     </ul>
    //                                     <p className="text-justify">The disease exacerbates due to the complex interaction between the external triggers mentioned above and the already present disease. As a result, there is increased inflammation in the lower airways. The protective anti-inflammatory defenses are overpowered, resulting in tissue damage which makes the flow of air more difficult in the lungs.</p>
    //                                     <h5>Common bacterial and viral causes of exacerbation of COPD can be divided into mild, moderate, and severe exacerbations. Some of the causes of mild to moderate COPD exacerbations are:</h5>
    //                                     <ul>
    //                                         <li>1)Mycoplasma pneumoniae</li>
    //                                         <li>2)Chlamydia pneumoniae</li>
    //                                         <li>3)Moraxella catarrhalis</li>
    //                                         <li>4)Haemophilus influenzae</li>
    //                                         <li>5)Streptococcus pneumoniae</li>
    //                                         <li>6)Viruses</li>
    //                                     </ul>
    //                                     <h5>Severe COPD exacerbations may be caused by:</h5>
    //                                     <ul>
    //                                         <li>1)Pseudomonas species</li>
    //                                         <li>2)Other gram-negative enteric bacilli</li>
    //                                     </ul>
    //                                     <h5>COPD, initially or in its exacerbated form, is never completely cured. However, treatments are available to manage the disease. Some of the many management options are:</h5>
    //                                     <ul>
    //                                         <li>1)Quitting cigarette smoking</li>
    //                                         <li>2)Medications such as antibiotics, corticosteroids, and bronchodilators</li>
    //                                         <li>3)Oxygen therapy</li>
    //                                         <li>4)Pulmonary rehabilitation</li>
    //                                         <li>5)Surgery in extreme cases</li>
    //                                         <li>6)Joining a social support group – one group, the Better Breathers Clubs, educates their members who are diagnosed with COPD on how to breathe better.</li>
    //                                     </ul>
    //                                     <p className="text-justify">People with COPD should know what causes COPD exacerbations. All efforts should then be directed at minimizing contact with these causes. Unfortunately, there is no known permanent cure of the disease. However, the disease can be prevented altogether by quitting smoking or not starting smoking in the first place, avoiding secondhand smoke, and protecting yourself from chemical fumes and dust.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/COPD Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd">COPD Causes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 10 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Exacerbation Symptoms.png" alt="Recent" /><NavLink to="/blogs/copd-exacerbation-symptoms">COPD Exacerbation Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 11 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Bronchitis Symptoms.png" alt="Recent" /><NavLink to="/blogs/bronchitis-symptoms">Bronchitis Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 12 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "causes-of-copd") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Causes of COPD | DHPFinder</title>
    //                 <meta name="description" content="There are many COPD causes, but cigarette smoking is the primary cause. Whatever the cause, prevention is important to not developing the disease. Read on for more information." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>Causes of COPD</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/COPD Causes.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 10 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Causes of COPD</h4>
    //                                     <p className="text-justify">It is important to know what causes COPD, since it cannot be cured once a person has developed the disease. However, making healthy lifestyle changes can prevent the onset of the disease or slow it down. COPD’s symptoms can be managed through medications and therapy, which help make a person’s life as normal as possible.</p>
    //                                     <p className="text-justify">There is no one known cause of COPD. Instead there are many potential causes of COPD. Cigarette smoking, especially long-term smoking, remains the most common cause of the disease. Besides cigarette smoking, there are other factors that may help the disease to develop. Chemical fumes or smoke, workplace exposure to dust, air pollution, and genetic susceptibility (1% of those with COPD have developed the disease due to a genetic disorder) may also cause COPD. In developing countries, women from poor economic backgrounds are more susceptible to developing COPD. This is because they may inhale fumes from burning fuel for heating and cooking in poorly ventilated kitchens.</p>
    //                                     <h5>COPD is a respiratory disorder; the disease obstructs the airways and makes breathing more difficult. There are two main conditions that cause airway obstruction:</h5>
    //                                     <ul>
    //                                         <li>1)Chronic bronchitis - bronchial tubes are inflamed and narrowed while the lungs make more mucus. This can further block the narrowed tubes. The affected person usually develops a chronic cough as a body’s way to clear his/her airways.</li>
    //                                         <li>2)Emphysema - is a lung disease which causes destruction of the elastic fibers and fragile walls of the alveoli. When exhaling, the small airways collapse in the lungs, which hinders the process.</li>
    //                                     </ul>
    //                                     <p className="text-justify">While COPD cannot be cured, it can be prevented. Since its main cause is already known, you can prevent the development of COPD by either not starting to smoke or quitting smoking. Smokers may want to seek medical help for information about programs to quit smoking, or for prescriptions for medication or devices to help stop smoking. Workplace dust and chemical fumes may be avoided by using respiratory protective equipment. You should speak with your manager about getting this equipment.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/COPD Exacerbation Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd-exacerbation">Causes of COPD Exacerbation</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 9 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Exacerbation Symptoms.png" alt="Recent" /><NavLink to="/blogs/copd-exacerbation-symptoms">COPD Exacerbation Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 11 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Bronchitis Symptoms.png" alt="Recent" /><NavLink to="/blogs/bronchitis-symptoms">Bronchitis Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 12 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "copd-exacerbation-symptoms") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>COPD Exacerbation Symptoms | DHPFinder</title>
    //                 <meta name="description" content="There are many COPD exacerbation symptoms that a person with COPD should know. The symptoms may be managed through pharmacological and non-pharmacological therapies." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>COPD Exacerbation Symptoms</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/COPD Exacerbation Symptoms.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 11 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>COPD Exacerbation Symptoms</h4>
    //                                     <p className="text-justify">Some of the many signs and symptoms of COPD exacerbation are the same signs and symptoms of COPD. However, the exacerbated signs and symptoms of the disease are more severe and dangerous, increasing the chances of mortality.</p>
    //                                     <h5>The following are symptoms of COPD:</h5>
    //                                     <ul>
    //                                         <li>1)Shortness of breath especially during physical activities</li>
    //                                         <li>2)Unintended weight loss</li>
    //                                         <li>3)Lack of energy</li>
    //                                         <li>4)Frequent respiratory infections</li>
    //                                         <li>5)Blueness of the lips or fingernail beds</li>
    //                                         <li>6)A chronic cough that produces sputum that may be greenish, yellow, white or clear</li>
    //                                         <li>7)Having to clear the throat after waking up due to excess mucus in the lungs</li>
    //                                         <li>8)Chest tightness</li>
    //                                         <li>9)Wheezing</li>
    //                                         <li>10)Malaise</li>
    //                                         <li>11)Fever</li>
    //                                         <li>12)Fatigue</li>
    //                                         <li>13)Tachypnea</li>
    //                                         <li>14)Dyspnea</li>
    //                                         <li>15)Sleepiness</li>
    //                                         <li>16)Insomnia</li>
    //                                         <li>17)Depression</li>
    //                                         <li>18)Confusion</li>
    //                                         <li>19)Decreased exercise tolerance</li>
    //                                         <li>20)Tachycardia</li>
    //                                     </ul>
    //                                     <p className="text-justify">A person who experiences exacerbation will have these symptoms, but they will get worse and could last for days or longer. A common sign of exacerbation is when sputum turns deep yellow, brown, or red.</p>
    //                                     <p className="text-justify">The severity of symptoms of COPD exacerbation may differ from person to person. The condition may not progress uniformly in those who have been diagnosed with COPD. The symptoms do, however, tend to get worse with time. Exacerbation may occur due to respiratory bacteria and viruses which increase the airways’ inflammation. It is important that those diagnosed with the disease keep an eye on the worsening symptoms of the disease as COPD exacerbation is linked with substantial mortality and morbidity.</p>
    //                                     <p className="text-justify">There is no permanent cure for COPD. However, COPD exacerbation signs and symptoms are controlled by the use of long-acting bronchodilators, inhaled steroids, or a combination of both. More recently, therapies like home ventilatory support, self-management, and pulmonary rehabilitation are also being adopted. However, effective COPD exacerbation management lies in the optimal combination of pharmacological and non-pharmacological therapies.</p>
    //                                     <p className="text-justify">As mentioned earlier, there is no cure for COPD. Prevention remains the only cure. Since cigarette smoking and inhaling chemical fumes and dust at one’s workplace are the leading causes of COPD, quitting smoking remains the primary source of prevention against COPD. Moreover, the use of protective respiratory equipment at work can help reduce exposure to unwanted fumes and dust.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/COPD Exacerbation Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd-exacerbation">Causes of COPD Exacerbation</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 9 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd">COPD Causes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 10 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Bronchitis Symptoms.png" alt="Recent" /><NavLink to="/blogs/bronchitis-symptoms">Bronchitis Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 12 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "bronchitis-symptoms") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Bronchitis Symptoms | DHPFinder</title>
    //                 <meta name="description" content="Bronchitis is an inflammation of the bronchial tubes in the lungs. Read more on bronchitis symptoms and the types of bronchitis here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>Bronchitis Symptoms</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Bronchitis Symptoms.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 12 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>What is Bronchitis?</h4>
    //                                     <p className="text-justify">Bronchitis is an infection or irritation that affects the bronchial tubes, the main airways of the lung. Bronchitis occurs when there is inflammation of the bronchial tubes. The inflammation of the bronchial tubes causes more mucus to be secreted into the tubes, which results in swelling of the tissues. This swelling will narrow the tubes and could block the passage of air. This causes difficulty in breathing, and results in bronchitis symptoms to appear.</p>
    //                                     <h5>Types of Bronchitis</h5>
    //                                     <h6>Bronchitis can be acute or chronic:</h6>
    //                                     <p className="text-justify"><b>Acute bronchitis</b> – Acute bronchitis is not long lasting. It may be the result of a cold or a viral infection, and subsides within a week or two with proper care. Bacteria may also cause acute bronchitis, but mostly it is caused by a viral infection.</p>
    //                                     <p className="text-justify"><b>Chronic bronchitis</b> - Chronic bronchitis is a long term condition that needs to be taken seriously. This type of bronchitis damages the inner walls of the airways, and is characterized by constant coughing and mucus production that lasts for more than three months.</p>
    //                                     <p className="text-justify">Smoking is considered to be the major factor that causes chronic bronchitis. Almost 50% affected by chronic bronchitis show a history of smoking. Other factors that contribute to it may be environmental pollution, exposure to lung irritants, or continuous viral or bacterial bouts of acute bronchitis that may eventually lead to chronic bronchitis.</p>
    //                                     <h5>Symptoms of Acute Bronchitis</h5>
    //                                     <p className="text-justify">Bronchitis symptoms in adults are the same as those experienced by children. Symptoms of acute bronchitis may be similar to those of flu. They include the following:</p>
    //                                     <ul>
    //                                         <li>1)A runny nose</li>
    //                                         <li>2)A slight temperature at times</li>
    //                                         <li>3)Difficulty in breathing or shortness of breath</li>
    //                                         <li>4)Cough</li>
    //                                         <li>5)Mild wheezing</li>
    //                                     </ul>
    //                                     <h5>Symptoms of Chronic Bronchitis</h5>
    //                                     <ul>
    //                                         <li>1)Persistent cough</li>
    //                                         <li>2)Sputum production</li>
    //                                         <li>3)Shortness of breath</li>
    //                                         <li>4)Wheezing</li>
    //                                         <li>5)Chest pain because of constant cough</li>
    //                                         <li>6)Headaches</li>
    //                                     </ul>
    //                                     <p className="text-justify">The severity of symptoms may differ from person to person, but as the disease progresses, the air flow will become more difficult. Therefore, it is very important to immediately get in touch with your healthcare practitioner if any of the symptoms for bronchitis become more severe.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/COPD Exacerbation Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd-exacerbation">Causes of COPD Exacerbation</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 9 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd">COPD Causes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 10 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Exacerbation Symptoms.png" alt="Recent" /><NavLink to="/blogs/copd-exacerbation-symptoms">COPD Exacerbation Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 11 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "chronic-bronchitis") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Chronic Bronchitis | DHPFinder</title>
    //                 <meta name="description" content="Chronic bronchitis is a long term condition that needs continuous medical attention. Find out more about chronic bronchitis and its symptoms here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Chronic Bronchitis.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 13 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>About Bronchitis</h4>
    //                                     <p className="text-justify">Bronchitis is an inflammation of the bronchial tubes, which carry air to and from the lungs. The inflammation may be because of an infection or an irritation. It results in the production of more than normal amounts of mucus in the bronchial tubes, which causes breathing problems and a persistent cough.</p>
    //                                     <p className="text-justify">Children and adults may both develop bronchitis. There are two types of bronchitis: acute and chronic bronchitis. The difference between both is given below.</p>
    //                                     <h5>What is Acute Bronchitis?</h5>
    //                                     <p className="text-justify">Acute bronchitis is a short term condition that may last for 10 days or about two weeks. Mostly viruses, and at times bacterial infections, may be the cause of this disease. Acute bronchitis may come with a bout of flu, so it is more common in winters. The cough takes long to subside and may continue even after the infection has cleared.</p>
    //                                     <h5>What is Chronic Bronchitis?</h5>
    //                                     <p className="text-justify">Chronic bronchitis is a long term disease. Chronic bronchitis is more commonly diagnosed in people above 40 years of age. In this condition, due to consistent coughing or frequent infections of the bronchial tubes, the lining of the bronchial tubes may be damaged.</p>
    //                                     <p className="text-justify">Chronic bronchitis is suspected when a person’s coughing persists for more than three months and occurs two years in a row, and there is an over production of mucus. If this occurs, medical care becomes necessary as the disease will progress and worsen if it is not taken care of.</p>
    //                                     <p className="text-justify">Acute and chronic bronchitis have some similar symptoms, but the difference for chronic bronchitis is that the symptoms may be more severe and felt for a longer stretch of time. At times, because of the prolonged disease, other symptoms may develop like fatigue, headaches, and chest pain.</p>
    //                                     <p className="text-justify">A medical health practitioner diagnoses chronic bronchitis by reviewing a patient’s medical history, performing a physical examination, and may order X-rays, CT scans, and pulmonary function tests. After evaluating the severity of the condition and determining the cause, a doctor will work with the person to determine a proper treatment plan.</p>
    //                                     <p className="text-justify">Since cigarette smoking is the leading cause of the disease, quitting is highly recommended. Avoiding second hand smoke and airborne bronchial irritants is also important.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/COPD Exacerbation Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd-exacerbation">Causes of COPD Exacerbation</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 9 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd">COPD Causes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 10 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Exacerbation Symptoms.png" alt="Recent" /><NavLink to="/blogs/copd-exacerbation-symptoms">COPD Exacerbation Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 11 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "what-causes-bronchitis") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>What Causes Bronchitis | DHPFinder</title>
    //                 <meta name="description" content="Bronchitis is an inflammation of the lining in the bronchial tubes. Read more about acute and chronic bronchitis and about what causes bronchitis." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/What Causes Bronchitis.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 14 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Understanding Bronchitis</h4>
    //                                     <p className="text-justify">Bronchitis is not uncommon and can happen at any age. It is an inflammation in the lining of the airways, bronchial tubes. These tubes carry air to and from the lungs. When this inflammation occurs, it overproduces mucus, which starts to build up and disturb breathing. It may result in a cough as the body tries to rid itself of the excess mucus. Other symptoms accompanied by coughing may include wheezing, chest pain, light fevers, chills, and shortness of breath.</p>
    //                                     <h5>What Causes Bronchitis</h5>
    //                                     <p className="text-justify">To understand the causes of bronchitis we first need to know more about the two types of bronchitis.  These are acute bronchitis and chronic bronchitis.</p>
    //                                     <p className="text-justify">Acute bronchitis lasts for a few days and up to a few weeks. It may be caused by a viral infection, bacterial infection, or from chemical irritants. Bronchitis caused by a virus or bacteria is infectious. Chemical irritants that are inhaled can interrupt the airways and cause complications. The viruses that cause acute bronchitis are the same that cause colds and flu. This type of bronchitis can be treated with medical care. It does not leave any long lasting effects.</p>
    //                                     <p className="text-justify">Chronic bronchitis, on the other hand, is a long term disease. It develops because of the worsening condition of the bronchial tubes that were damaged due to constant exposure to lung irritants or infections. It has been defined as a cough that is persistent, and lasts for more than three months and occurs two years in a row. The cough is accompanied by sputum (mucus). These are the two most prominent symptoms that characterize chronic bronchitis. There might be other symptoms, but do not let a chronic cough linger on. Timely care can prevent the progression of the disease.</p>
    //                                     <p className="text-justify">A lung disease may also increase the risk of developing bronchitis. Also, most cases of chronic bronchitis reveal a link to smoking. Treatment will focus on controlling the disease and managing the symptoms. Deciding on a treatment plan could be based on what caused the disease. Patients of chronic bronchitis are recommended to avoid tobacco smoke, dust, chemical fumes, and lung irritants as they can aggravate the condition.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/COPD Exacerbation Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd-exacerbation">Causes of COPD Exacerbation</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 9 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd">COPD Causes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 10 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Exacerbation Symptoms.png" alt="Recent" /><NavLink to="/blogs/copd-exacerbation-symptoms">COPD Exacerbation Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 11 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "causes-of-emphysema") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Causes of Emphysema | DHPFinder</title>
    //                 <meta name="description" content="Cigarette smoking is one of the leading causes of emphysema. Read on to learn about how cigarette smoke may cause this disease and what other causes there may be." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Emphysema Causes.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 16 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Causes of Emphysema</h4>
    //                                     <p className="text-justify">Emphysema is a type of COPD (chronic obstructive pulmonary disease) in which the air sacs, known as the alveoli, in the lungs are damaged. This may be primarily due to long-term exposure to airway irritants.</p>
    //                                     <h5>What Causes Emphysema?</h5>
    //                                     <p className="text-justify">Smoking is undoubtedly one of the most common causes of emphysema. Over time, cigarette smoke damages the elasticity of the alveoli, which may cause a person to develop emphysema. The number of years and the amount of smoke a person is exposed to are directly proportional to that person’s chances of developing emphysema. A person reduces his/her chances of developing emphysema if they quit smoking. If a person has already been diagnosed with the disease, then quitting may also provide relief from some of the symptoms. While less common than cigarette smoking, there are other reasons which may contribute to the development of emphysema. These include inhaling other irritants in the air like marijuana smoke, silica and coal dust, and manufacturing fumes. Some other causes are discussed in detail below:</p>
    //                                     <h5>Other Diseases</h5>
    //                                     <p className="text-justify">Some diseases may lead to a person developing emphysema. These include:</p>
    //                                     <ul>
    //                                         <li>1)Alpha 1-antitrypsin deficiency</li>
    //                                         <li>2)Salla disease</li>
    //                                         <li>3)Menke syndrome</li>
    //                                         <li>4)Cutis laxa</li>
    //                                         <li>5)Marfan syndrome</li>
    //                                         <li>6)Pneumonia</li>
    //                                         <li>7)HIV infection</li>
    //                                     </ul>
    //                                     <p className="text-justify">All of these diseases either damage the elastin or are rare hereditary conditions in which elastin is weakened or lessened. Elastin is the protein in the lung muscle that allows for it to expand and contract efficiently without damage – absence of or damage to it can severely affect a lung.</p>
    //                                     <p className="text-justify">An alpha 1-antitrypsin deficiency is perhaps the most common cause of emphysema which is not related to cigarette smoke.</p>
    //                                     <h5>Malnutrition</h5>
    //                                     <p className="text-justify">Malnutrition, an absence of essential nutrients in a person’s diet, can also affect the elastin’s function in lungs; and may make a person more susceptible to developing emphysema.</p>
    //                                     <h5>Intravenous Drug Abuse</h5>
    //                                     <p className="text-justify">Abusing drugs intravenously may also lead to a person developing emphysema (often referred to as ‘precocious emphysema’). It is not clear how this causes the disease, but it is speculated it may be due to the toxic effects of the drug or there may be a synergism with smoke from cigarettes.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Multiple Sclerosis.jpg" alt="Recent" /><NavLink to="/blogs/causes-of-copd-exacerbation">Causes of COPD Exacerbation</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 9 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd">COPD Causes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 10 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Exacerbation Symptoms.png" alt="Recent" /><NavLink to="/blogs/copd-exacerbation-symptoms">COPD Exacerbation Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 11 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "copd-exacerbation-treatment") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>COPD Exacerbation Treatment | DHPFinder</title>
    //                 <meta name="description" content="Timely COPD exacerbation treatment can greatly reduce the morbidity and mortality associated with the condition. Read more about the treatment options here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Exacerbation Treatment COPD.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 18 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>COPD Exacerbation Treatment</h4>
    //                                     <p className="text-justify">A person with COPD (chronic obstructive pulmonary disease) may at times witness a sudden worsening of their symptoms known as an exacerbation of the disease. Exacerbation may manifest itself in the form of increased sputum production (mucus), coughing, and dyspnea (shortness of breath). Timely COPD exacerbation treatment may help a person recover from the uncomfortable signs and symptoms associated with an exacerbation, and reduce chances of severe health consequences, including death.</p>
    //                                     <h5>Treatment for COPD Exacerbation</h5>
    //                                     <p className="text-justify">Treatment of COPD exacerbations may be tailored around medication and/or therapies. Whatever the route taken, it is very important that the focus is on long term management of the exacerbation and reducing its chances of occurring again.</p>
    //                                     <h5>Some of the medicines commonly used to manage exacerbations are discussed below:</h5>
    //                                     <h6>Bronchodilators</h6>
    //                                     <p className="text-justify">This class of inhaled medication relaxes the muscles around a person’s airways. This makes breathing more comfortable and often relieves shortness of breath and coughing.</p>
    //                                     <h6>Steroids</h6>
    //                                     <p className="text-justify">Certain steroids may be taken orally or inhaled in case of an exacerbation. These help by reducing airway inflammation which may be causing exacerbation. Steroids often have associated side effects and are given only in the case where a patient develops frequent and severe exacerbations.</p>
    //                                     <h6>Antibiotics</h6>
    //                                     <p className="text-justify">Exacerbations may be caused by a bacterial infection such as pneumonia or influenza. Antibiotics aim at treating the underlying cause of an exacerbation and providing relief to the patient.</p>
    //                                     <p className="text-justify">Other than medication, some physicians may also recommend various therapies to treat and manage COPD exacerbations. These include the therapies discussed below.</p>
    //                                     <h5>Non-Invasive Positive Pressure Ventilation (NIPPV)</h5>
    //                                     <p className="text-justify">NIPPV is a non-invasive therapy that improves the gaseous exchange and pH in a patient’s blood. For this therapy, a patient wears a tight fitting mask which supplies oxygen rich air to the person, improving the gas flow to and from the lungs and thus easing breathing.</p>
    //                                     <h5>Invasive Mechanical Ventilation</h5>
    //                                     <p className="text-justify">Some people may not tolerate NIPPV or may see no improvement in their symptoms. These people may have to be given invasive mechanical ventilation for an improvement in their condition. Physicians may also choose to give invasive mechanical ventilation if a person has comorbid conditions such as sepsis or myocardial infarction.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/COPD Exacerbation Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd-exacerbation">Causes of COPD Exacerbation</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 9 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd">COPD Causes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 10 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Exacerbation Symptoms.png" alt="Recent" /><NavLink to="/blogs/copd-exacerbation-symptoms">COPD Exacerbation Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 11 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "emphysema-symptoms") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Emphysema symptoms | DHPFinder</title>
    //                 <meta name="description" content="Emphysema symptoms include coughing and other breathing issues. Click here to read about other symptoms and how this disease may be caused." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Emphysema Symptoms.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 20 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Emphysema symptoms</h4>
    //                                     <p className="text-justify">Emphysema is commonly caused by smoking, though it can also be caused by sustained exposure to air pollution. Emphysema symptoms become noticeable over time, and substantially lower the quality of life in the person suffering from the disease.</p>
    //                                     <p className="text-justify">Emphysema is a long term ailment, and classified as a chronic obstructive pulmonary disease (COPD). It gradually destroys the lung tissue and capillaries around the alveoli sac, which compromises oxygen supply. It may be coupled with chronic bronchitis and other complications. Consequently, a noticeable symptom of emphysema is a perpetual shortness of breath, even when a person is resting.  Another visible symptom is the increase in the diameter of the chest, making the person look barrel-chested which indicates trapped air. Other symptoms include wheezing, caused by excessive mucus production, and an irritating cough.  Over time, all these add up and can have debilitating effects.</p>
    //                                     <p className="text-justify">The damage done by emphysema requires constant health management. In some cases, partial rehabilitation of the lung may be possible, but it requires a serious commitment to certain lifestyle changes. These healthier changes may help you manage symptoms of emphysema.  First and foremost, if you are a smoker, stop smoking today. This of course is easier said than done, but there are several quit smoking programs which may help you.</p>
    //                                     <p className="text-justify">Other treatment options include bronchodilation medications. These help the airways open more fully, which allow for more air to enter the lungs. Treatment could include oxygen being given through a tube into the lungs.</p>
    //                                     <p className="text-justify">Another treatment option is a lung transplant, where an unhealthy or compromised lung is replaced with a healthy lung from a deceased donor, but these procedures are extremely expensive.</p>
    //                                     <p className="text-justify">If you feel you have signs and symptoms of emphysema, you should contact your physician immediately. After observing symptoms of emphysema, your physician may order certain tests to affirm or rule out emphysema. Your physician may then guide you on what steps to take and a timetable.  Your doctor may also prescribe certain medication for symptomatic relief.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/COPD Exacerbation Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd-exacerbation">Causes of COPD Exacerbation</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 9 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd">COPD Causes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 10 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Exacerbation Symptoms.png" alt="Recent" /><NavLink to="/blogs/copd-exacerbation-symptoms">COPD Exacerbation Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 11 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "copd-diagnosis") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>COPD Diagnosis | DHPFinder</title>
    //                 <meta name="description" content="COPD diagnosis requires a series of physical examinations and tests that your physician will prescribe. Visit our website to learn more about COPD diagnosis." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/COPD Diagnosis.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>March, 22 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>COPD Diagnosis</h4>
    //                                     <p className="text-justify">COPD, or chronic obstructive pulmonary disease, is a very serious disease of the respiratory system, which if left untreated can be potentially fatal. The American Lung Association says that COPD is the third leading cause of death in the U.S. While the disease has no cure, it is preventable and can be managed. Given that many of the symptoms are similar to other respiratory diseases, COPD diagnosis requires careful examination and testing.</p>
    //                                     <p className="text-justify">Should you be concerned? This depends on how exposed you are to cigarette smoke, air pollution, or certain chemicals on a routine basis. Smokers are often at the greatest risk of developing this disease. The symptoms could begin to show in a person in his/her late 30s if that person has been smoking for many years. Most common symptoms of COPD are:</p>
    //                                     <ul>
    //                                         <li>1)Shortness of breath</li>
    //                                         <li>2)Chest tightness</li>
    //                                         <li>3)Lack of energy</li>
    //                                         <li>4)Frequent respiratory infections</li>
    //                                         <li>5)Wheezing</li>
    //                                         <li>6)A chronic cough with mucus and sputum</li>
    //                                     </ul>
    //                                     <p className="text-justify">For a positive diagnosis of COPD, a spirometry test is required. This will determine the air flow limitations in a person’s lungs.  If a person is suspected to have COPD, the doctor is most likely to take the person’s exposure to risk factors into account. Risk factors may include if the person is a smoker, exposed to second hand smoke, exposed to high amounts of air pollution, or exposed to chemicals or gases at work.</p>
    //                                     <p className="text-justify">Additionally a physical exam may be undertaken which will entail taking the patient’s temperature, breathing rate, and blood pressure. Other physical signs of the disease will present themselves around the patient’s ears, throat, nose, and eyes. The patient’s legs, ankles, and other body parts may be swollen, which could be indicators of the disease. Lung function tests, CT scans, and a chest X-ray may also be useful tools in diagnosing COPD. A chest X-ray in a patient suffering from COPD will show large areas of density in the lungs. A complete blood count test will show hemoglobin levels.</p>
    //                                     <p className="text-justify">If you suspect that you have COPD, you should contact your physician immediately. It is a very serious disease, and the earlier diagnosis, the better the prognosis will be for you.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/COPD Exacerbation Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd-exacerbation">Causes of COPD Exacerbation</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 9 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Causes.png" alt="Recent" /><NavLink to="/blogs/causes-of-copd">COPD Causes</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 10 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/COPD Exacerbation Symptoms.png" alt="Recent" /><NavLink to="/blogs/copd-exacerbation-symptoms">COPD Exacerbation Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Mar, 11 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "causes-of-heart-disease") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Causes of Heart Disease | DHPFinder</title>
    //                 <meta name="description" content="There are various factors that could be considered as causes of heart disease. Some are genetic, while others may depend on your lifestyle. Learn about risk factors and prevention here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Causes of Heart Disease.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>April, 28 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Causes of Heart Disease</h4>
    //                                     <p className="text-justify">The human heart is an important organ in our body because it is responsible for circulating blood through our veins. It is also sensitive, so it is prone to diseases as a result of certain habits or genetic conditions. Understanding what causes heart disease is important to helping you avoid the onset of such diseases.</p>
    //                                     <p className="text-justify">One of the triggers of heart disease is stress. Constantly being stressed causes your blood pressure to rise and your blood vessels to harden – a condition known as arteriosclerosis. The stiffening of these vessels hinders the smooth flow of blood through the body. As a result, the heart has to pump harder in order to maintain this flow. Over an extended period, this can cause the muscles of the heart to become damaged or scarred, which could also put you at greater risk for a heart attack. Individuals who are heavy smokers or consume high levels of alcohol are at a higher risk of developing heart diseases, since both habits also cause hardening of the arteries and an increase in blood pressure.</p>
    //                                     <p className="text-justify">High cholesterol and sugar levels in the body are also speculated causes of heart disease. If you have diabetes, or if you consume a large amount of fatty foods on a regular basis with little exercise, then you may be at a higher risk of developing heart disease. The excess fat and sugar in the body forms deposits along the walls of your blood vessels – a condition called atherosclerosis – reducing the size of the vessel and leading to an elevated blood pressure. In some cases, these deposits could cause tearing in the blood vessels or the muscles of the heart, which may cause cardiac arrest.</p>
    //                                     <h5>Preventing Heart Disease</h5>
    //                                     <p className="text-justify">Adopting a healthier lifestyle is an effective way to reduce the onset of heart disease in individuals. Heavy smokers and drinkers should cut back, and engage in regular physical activity to keep the heart healthy. Some doctors may also recommend taking medication to reduce cholesterol and blood pressure levels in the body to reduce the risk of deposits. Reducing the amount of sugar and cholesterol in your diet by incorporating fresh foods and salads are also ways to counter heart disease risk factors.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Heart Disease Symptoms.png" alt="Recent" /><NavLink to="/blogs/heart-disease-symptoms">Heart Disease Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 28 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Heart Disease Treatment.png" alt="Recent" /><NavLink to="/blogs/heart-disease-treatment">Heart Disease Treatment</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 29 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/How to Prevent Heart Disease.png" alt="Recent" /><NavLink to="/blogs/how-to-prevent-heart-disease">How to Prevent Heart Disease</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 29 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "heart-disease-symptoms") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Heart Disease Symptoms | DHPFinder</title>
    //                 <meta name="description" content="Understanding heart disease symptoms can help you identify when you are at risk, and take preventive measures in time. Read on for more details about these symptoms." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Heart Disease Symptoms.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>April, 28 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Heart Disease Symptoms</h4>
    //                                     <p className="text-justify">Heart diseases can manifest themselves in more ways than just a sudden pain in the chest. Understanding heart disease symptoms can help you determine when you are at a greater risk, and allow you to take the right steps to prevent the condition from getting worse.</p>
    //                                     <p className="text-justify">Symptoms of heart disease vary with the type of condition. Individuals experiencing blockage of the coronary arteries – the blood vessels supplying the heart – experience a condition called angina. The symptoms of this disease are tightness in the chest and a constant dull pain which can vary in intensity depending on the extent of blockage. In some cases, this pain radiates from the chest to the back, the stomach, and even the jaw. These heart disease symptoms are classified under the category of coronary heart disease (CHD), and can lead to a heart attack if the arteries are completely blocked.</p>
    //                                     <p className="text-justify">Some individuals experience an irregularity in their heartbeat, which is classified as arrhythmia.</p>
    //                                     <p className="text-justify">Symptoms of heart problems relating to arrhythmia include:</p>
    //                                     <ul>
    //                                         <li>1)A racing heartbeat (tachycardia)</li>
    //                                         <li>2)A slow heartbeat (bradychardia)</li>
    //                                         <li>3)Shortness of breath</li>
    //                                         <li>4)Lightheadedness</li>
    //                                         <li>5)Fainting (syncope)</li>
    //                                     </ul>
    //                                     <p className="text-justify">Some heart diseases cause a thickening of the heart muscle, known as cardiomyopathy. Heart disease symptoms relating to this condition include:</p>
    //                                     <ul>
    //                                         <li>1)Irregularity of the heartbeat, similar to arrhythmia</li>
    //                                         <li>2)Dizziness</li>
    //                                         <li>3)Swelling in the ankles, feet, or legs</li>
    //                                         <li>4)Bloating of the abdomen</li>
    //                                         <li>5)Fatigue</li>
    //                                     </ul>
    //                                     <p className="text-justify">There is a significant amount of overlap in the symptoms of heart disease, even though their root cause may be very different. Because of this, diagnosing the correct condition becomes slightly difficult, especially in cases where the disease may have become seriously aggravated. If you are experiencing any of these symptoms, it is a good idea to consult your doctor or healthcare provider in order to determine the risk of heart disease.</p>
    //                                     <p className="text-justify">One way to reduce the risk of heart disease and the onset of symptoms of heart disease is to adopt a healthier diet. Replacing fatty foods high in saturated fats with foods high in unsaturated fats will help to improve your health. Engaging in regular exercise and reducing smoking and excess drinking are also highly effective.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Causes of Heart Disease.png" alt="Recent" /><NavLink to="/blogs/causes-of-heart-disease">Causes of Heart Disease</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 28 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Heart Disease Treatment.png" alt="Recent" /><NavLink to="/blogs/heart-disease-treatment">Heart Disease Treatment</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 29 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/How to Prevent Heart Disease.png" alt="Recent" /><NavLink to="/blogs/how-to-prevent-heart-disease">How to Prevent Heart Disease</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 29 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "heart-disease-treatment") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Heart Disease Treatment | DHPFinder</title>
    //                 <meta name="description" content="Professional care is important for heart disease treatment. Other options like adopting a healthier lifestyle help with treatment too. Read on for more." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Heart Disease Treatment.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>April, 29 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Heart Disease Treatment</h4>
    //                                     <p className="text-justify">Heart disease is a general term used for a variety of diseases that affect the heart. However, the term ‘heart disease’ is commonly used to describe cardiovascular disease. Cardiovascular disease includes several conditions in which the blood vessels either become narrower or are blocked, which may lead to a stroke, chest pains, or angina (more commonly referred to as a ‘heart attack’). Heart disease treatment not only requires a patient to follow a healthcare professional’s advice, which may include medications and surgery, but also adopting a healthier lifestyle.</p>
    //                                     <p className="text-justify">For a minor complaint, physicians may start a person off on some lifestyle changes. Although it is not a cure for heart disease, a healthy lifestyle may go a long way in managing the disease. A cardiologist is likely to advise that the patient get some regular physical exercise, follow a diet low in sodium and fat, quit smoking, and cut down on daily alcohol consumption.</p>
    //                                     <h5>Medicines for Treating Heart Disease</h5>
    //                                     <p className="text-justify">If the blockages are severe and following a healthy lifestyle regime is not enough, medication may be prescribed as a treatment for heart disease. Depending upon the person’s medical history and disease symptoms, these may be for lowering cholesterol and blood pressure. Other medicines may include:</p>
    //                                     <ul>
    //                                         <li>1)Antiplatelet drugs like aspirin</li>
    //                                         <li>2)Anticoagulants, also referred to as blood thinners</li>
    //                                         <li>3)Beta blockers</li>
    //                                         <li>4)Nitrates</li>
    //                                         <li>5)Thrombolytic agents, during a heart attack to disintegrate a clot</li>
    //                                     </ul>
    //                                     <h5>Surgery for Heart Disease</h5>
    //                                     <p className="text-justify">Another treatment for heart disease is surgery or other invasive procedures, especially if medications and a healthy lifestyle cannot clear the blockages. Coronary angioplasty is one type of procedure used in extreme cases. This involves a catheter that is placed in an artery in the arm or groin of a person, and then threading a small balloon to the blocked artery and inflating the balloon to open up the artery. A stent, which is a small metal coil, may also be placed in the artery during the procedure.</p>
    //                                     <p className="text-justify">Coronary artery bypass is another type of surgery recommended to those with a serious heart disease. This procedure requires a vein to be taken from the person’s leg, which is used to create another route around the blocked section of the artery.</p>
    //                                     <p className="text-justify">If you have a heart disease, do not attempt to self-diagnose and self-treat. Consult a cardiologist immediately and decide the best treatment option in consultation with the doctor.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Causes of Heart Disease.png" alt="Recent" /><NavLink to="/blogs/causes-of-heart-disease">Causes of Heart Disease</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 28 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Heart Disease Symptoms.png" alt="Recent" /><NavLink to="/blogs/heart-disease-symptoms">Heart Disease Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 28 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/How to Prevent Heart Disease.png" alt="Recent" /><NavLink to="/blogs/how-to-prevent-heart-disease">How to Prevent Heart Disease</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 29 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "how-to-prevent-heart-disease") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>How to Prevent Heart Disease | DHPFinder</title>
    //                 <meta name="description" content="Knowing how to prevent heart disease can literally save your life. Simple changes to your habits and lifestyle can make all the difference. Read more here." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/How to Prevent Heart Disease.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>April, 29 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>How to Prevent Heart Disease</h4>
    //                                     <p className="text-justify">Many people are unsure about how to prevent heart disease. They believe that it is an uphill effort requiring lots of medication and medical procedures. On the contrary, heart disease prevention is can be as simple as making a few lifestyle changes.</p>
    //                                     <p className="text-justify">One of the major contributors of heart disease is a high consumption of fatty or sugar-rich foods. In excess quantities, these substances lead to fatty deposits to form in the blood vessels, restricting blood flow and increasing blood pressure. Some individuals are genetically prone to having high cholesterol or diabetes as well. In both cases, adopting a healthier diet with more fresh fruits and less fatty foods goes a long way in helping your heart’s condition. Avoid eating large amounts of cheese or foods cooked in coconut oil, and consider healthier alternatives like sunflower oil and fish for meat. These contain unsaturated fats, which are good for the body. This is not to say that you should eliminate all meat and cheese from your diet, only that you should try to maintain a good balance.</p>
    //                                     <p className="text-justify">Participating in physical activity is another method to improve your heart’s condition. Even if it is a simple jog around your block or on a treadmill, developing a habit of exercise reduces your body’s fat levels and lowers your blood pressure. It also helps keep your weight down, which is another high risk factor for heart disease. Studies have shown that exercising for even a half hour on a regular basis is an effective measure for heart disease prevention.</p>
    //                                     <p className="text-justify">Individuals who are heavy smokers or drinkers should reduce their consumption or give up the habit altogether. Drinking and smoking in excess puts you at greater risk of developing heart diseases like atherosclerosis and arteriosclerosis. Doctors often suggest using nicotine patches as a way to ease off smoking. For alcohol, staying within the recommended amounts is helpful in preventing heart disease.</p>
    //                                     <p className="text-justify">Simply knowing how to prevent heart disease is not enough. It does require a certain amount of commitment to change your lifestyle towards a healthier direction. However, the benefits of doing so greatly outweigh the risks.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Causes of Heart Disease.png" alt="Recent" /><NavLink to="/blogs/causes-of-heart-disease">Causes of Heart Disease</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 28 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Heart Disease Symptoms.png" alt="Recent" /><NavLink to="/blogs/heart-disease-symptoms">Heart Disease Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 28 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Heart Disease Treatment.png" alt="Recent" /><NavLink to="/blogs/heart-disease-treatment">Heart Disease Treatment</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 29 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "heart-disease-diet") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Heart Disease Diet | DHPFinder</title>
    //                 <meta name="description" content="Eating healthy is one way to prevent heart disease. A diet that excludes fatty foods and sugars and includes more unsaturated fats is a great start. Read on to learn more." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Heart Disease Diet.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>April, 30 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Heart Disease Diet</h4>
    //                                     <p className="text-justify">Diet and heart disease may seem like very different subjects, but the two have a relationship that affects your health. Studies have shown that people with unhealthy eating habits or are overweight are more likely to develop heart disease. A diet rich in fats, sugars, and missing fruits or vegetables is highly discouraged by doctors around the globe, especially if you have a family history of diabetes, high cholesterol, and heart disease.</p>
    //                                     <p className="text-justify">Maintaining a healthy diet for heart disease does not mean that you will have to give up on all the foods you love to eat. You simply need to achieve a balance that allows your body to take in all the healthy nutrients it needs without overburdening it with excess sugars and fats.</p>
    //                                     <h5>Diet for Heart Disease</h5>
    //                                     <p className="text-justify">The ideal heart disease diet focuses on increasing your consumption of fresh fruits and vegetables. These foods contain high fiber content, while being low on carbohydrates and calories. On the other hand, fatty foods contain high levels of saturated fats and cholesterol. In excess quantities, these substances may cause plaques of fatty material to develop in your arteries and reduce their diameter. This puts a strain on your heart because it has to work harder to pump blood through your body, and elevates your risk of developing heart diseases like atherosclerosis and arteriosclerosis.</p>
    //                                     <p className="text-justify">Doctors also recommend including more whole grain products like brown rice and whole grain pasta, and vegetables like corn and potatoes. These foods are high in carbohydrates, which provide you with adequate energy, but are low in cholesterol. You should take care to stay within the recommended guidelines and exercise regularly too to avoid gaining weight.</p>
    //                                     <p className="text-justify">An effective diet for heart disease will also focus on reducing a person’s intake of processed meat products like sausages or hot dogs and substitute these with fish. Eating lean meat with the excess fat removed, and preparing your meat in a way that avoids deep frying will also prevent the buildup of fat content in your body.</p>
    //                                     <p className="text-justify">If you use a lot of cooking oil at home, consider switching from options like vegetable oil to olive oil or canola oil; both being a healthier alternative. If you use a lot of salt in your cooking, you should consider keeping the daily amount under 2400 grams.</p>
    //                                     <p className="text-justify">Therefore, in order to help prevent heart disease, diet is a factor that cannot be highlighted enough.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Causes of Heart Disease.png" alt="Recent" /><NavLink to="/blogs/causes-of-heart-disease">Causes of Heart Disease</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 28 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Heart Disease Symptoms.png" alt="Recent" /><NavLink to="/blogs/heart-disease-symptoms">Heart Disease Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 28 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Heart Disease Treatment.png" alt="Recent" /><NavLink to="/blogs/heart-disease-treatment">Heart Disease Treatment</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 29 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "reversing-heart-disease") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Reversing Heart Disease | DHPFinder</title>
    //                 <meta name="description" content="Reversing heart disease is not impossible, but preventing it from developing in the first place is more important. Read on to find out what you can do." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/Reversing Heart Disease.png" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>April, 30 2024</span></label>
    //                                 </div>
    //                                 <div class="blog-details-previous" style={{ wordWrap: 'break-word' }}>
    //                                     <h4>Reversing Heart Disease</h4>
    //                                     <p className="text-justify">Reversing heart disease is not entirely impossible, but taking steps to prevent its onset in the first place is more effective. Contrary to popular belief, you do not have to take a lot of medication – unless you have high cholesterol or diabetes – and may only need to make minor adjustments to your lifestyle as a way of reducing your chances of developing heart disease.</p>
    //                                     <p className="text-justify">For instance, studies have shown that exercising for roughly 30 minutes a day on a regular basis is an excellent way to prevent the onset of heart disease because you could reduce your body’s fat content and increase the strength of your heart muscles. Otherwise, the added fat in your body can form plaques in your arteries, which block the flow of blood and causes complications with blood pressure. The end result is added strain on the heart, and a greater risk for heart disease.</p>
    //                                     <p className="text-justify">Switching to a healthier diet can also help in reversing heart disease development. Incorporating more fish and canola oil into your diet instead of large amounts of bacon and coconut oil, for instance, is another effective way to reduce the onset of heart disease. Fish and canola oils supply your body with nutrients and unsaturated fats which are healthy for your heart. This does not mean that you have to give up on all other types of meat; a balanced diet is necessary for your body to function smoothly. Try to avoid deep frying your food as much as possible, and steam, roast, or smoke your food instead.</p>
    //                                     <p className="text-justify">Increasing the amount of fiber in your diet is also helpful, since it reduces the chances of plaque formation in your blood vessels. As a result, eating whole grain products like brown rice or whole grain pasta is a wise option and highly recommended by doctors.</p>
    //                                     <p className="text-justify">Reversing heart disease also requires you to reduce smoking and alcohol consumption. Both of these substances cause a hardening of your arteries, which increases blood pressure and chances of a rupture. Individuals who drink regularly should responsibly stay within the daily recommended guidelines.</p>
    //                                     <p className="text-justify">For individuals who have diabetes or high cholesterol in their families, it is strongly advised that they take their medication regularly. Otherwise, it could lead to a buildup of harmful chemicals in the body and heighten the risk of heart disease.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form>
    //                                         <input type="text" class="form-control" placeholder="Search" />
    //                                         <button type="button" class="btn blog-details-btn"><i class="icofont-search-1"></i></button>
    //                                     </form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/Causes of Heart Disease.png" alt="Recent" /><NavLink to="/blogs/causes-of-heart-disease">Causes of Heart Disease</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 28 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Heart Disease Symptoms.png" alt="Recent" /><NavLink to="/blogs/heart-disease-symptoms">Heart Disease Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 28 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/Heart Disease Treatment.png" alt="Recent" /><NavLink to="/blogs/heart-disease-treatment">Heart Disease Treatment</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> Apr, 29 2024</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "open-heart-surgery") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Open Heart Surgery | DHPFinder</title>
    //                 <meta name="description" content="Open heart surgery is an invasive procedure which is used to help treat various heart conditions. Read on for more details on the types of open heart surgery." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/30.jpg" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 8 2024</span></label>
    //                                     <p className="text-justify">Open heart surgery is a treatment option for heart conditions which may not be treated by medications or which need to be treated quicker than the time needed for medication to be effective. In most cases, open heart surgery is used as the last resort for very serious heart ailments. The beating of the heart is temporarily stopped and blood is pumped through a heart-lung machine. The risks of using the machine may lead to blood clotting and organ damage. Despite the dangers associated with the procedure, an increasing number of Americans are having open heart surgeries to address a number of  heart conditions. The surgery may be performed to fix malfunctioning heart valves, or to clear blockages in the heart valves. Open heart surgery may also be performed to repair and/or replace heart valves. Open heart surgery is also used for heart transplants. All types of thoracic aortic conditions may also be addressed through open heart surgery.
    //                                         As medical science has advanced, so has open heart surgery. Today, it is broadly divided into two types of surgeries:
    //                                     </p>
    //                                 </div>
    //                                 <div class="blog-details-previous">
    //                                     <ul>
    //                                         <li><b>1.</b> Traditional open heart surgery - the breast bone is divided and the chest opened for the surgeons to operate on the heart. Since it involves “cracking the chest”, an 8-10 inch scar is left on the chest. Traditional surgery is more traumatic, and involves longer recovery time as compared to “modern” open heart surgery.</li>
    //                                         <li><b>2.</b> Modern open heart surgery – this type of surgery is less invasive because it does not involve the opening up of the chest. Instead, the surgeons make smaller incisions between the ribs so that the patient can be operated on. The person is left with 2-3 inch scars, and the recovery time is much faster. </li>
    //                                     </ul>
    //                                     <br />
    //                                     <p className="text-justify">The length of hospital stay will depend upon your specific needs and the type of surgery you have had. It may take a few days to a few weeks, especially if there are complications, before you can leave the hospital. The recovery process itself will span over a few weeks, and will differ from person to person. During the recovery period, you should follow your doctor’s orders and be sure to go to all of your follow-up appointments.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your comment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form><input type="text" class="form-control" placeholder="Search" /><button
    //                                         type="submit" class="btn blog-details-btn"><i
    //                                             class="icofont-search-1"></i></button></form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/30.jpg" alt="Recent" /><NavLink to="/blogs/open-heart-surgery">Open Heart Surgery</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 15 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/13.png" alt="Recent" /><NavLink to="/blogs/children-allergy-symptoms">Children Allergy Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 15 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <li><img src="../images/blog/29.jpg" alt="Recent" /><NavLink to="/blogs/women-heart-health">Women Heart Health</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 15 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else if (parameter.parameter == "women-heart-health") {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Women Heart Health | DHPFinder</title>
    //                 <meta name="description" content="An increasing prevalence of heart disease is being seen in women. Heart health is more sensitive as compared to men. Read on to learn more about these conditions." />
    //             </Helmet>
    //             <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
    //                 <div class="d-table">
    //                     <div class="d-table-cell">
    //                         <div class="page-title-item">
    //                             <h2 style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</h2>
    //                             {/* <ul>
    //                             <li><NavLink to="/">Home</NavLink></li>
    //                             <li class="active" style={{ textTransform: 'capitalize' }}>{parameter.parameter.replace(/-/g, ' ')}</li>
    //                         </ul> */}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="blog-details-area pt-100">
    //                 <div class="container">
    //                     <div class="row">
    //                         <div class="col-lg-8">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-img"><img src="../images/blog/29.jpg" alt="Blog" />
    //                                     <br />
    //                                     <label id="date">Published: <span>January, 8 2024</span></label>
    //                                     <br />
    //                                     <p className="text-justify">Contrary to popular belief, heart disease equally affects both men and women. Heart health is just as important for females as it is to males. However, since women do not necessarily experience the same symptoms of heart diseases as men, the presence of these diseases tends to often go unnoticed. Understanding the unique symptoms of heart disease in women, and the importance of a heart-healthy diet can help maintain your health.</p>
    //                                     <br />
    //                                     <p className="text-justify">In women, heart health is influenced by a variety of factors. For instance, low levels of estrogen arising from menopause affects the blood vessels around the heart, increasing the risk of heart disease. While smoking is a catalyst for cardiovascular disease in both genders, women are more susceptible to the effects of nicotine than men. Similarly, women are also more prone to developing heart conditions as a result of severe emotional distress or depression. Because the triggers of heart conditions are so unusual, many women are unaware of the damage until it reaches an extreme state. In fact, many women discount the symptoms they are experiencing – lightheadedness, nausea, and shortness of breath – as exhaustion or a mild infection.</p>
    //                                     <br />
    //                                     <p className="text-justify">Fortunately, improving heart health in women is not a daunting task. With certain changes to your lifestyle and a little commitment, you could ensure that your heart stays strong and healthy. If you smoke or consume more alcohol than the recommended daily guidelines, you should consider reducing it, or even better, quitting. Nicotine and alcohol harden the arteries, making it easier for deposits to form along the arteries’ inner walls and increase your blood pressure. Also, changing your diet to include more fibrous foods and fresh fruits and vegetables is a good idea. The fiber reduces the absorption of fats from the intestines, thus keeping their blood concentration in check. It is also recommended that you incorporate more fish and olive or canola oils in your food in place of coconut oils and red meat. Adding whole grain to your diet is also a good suggestion. Exercising regularly for at least 30 minutes is another excellent way to strengthen your heart muscles and maintain your health.</p>
    //                                 </div>
    //                             </div>
    //                             <div class="blog-details-form pb-100">
    //                                 <div class="blog-details-form-wrap">
    //                                     <h2>Drop your coomment</h2>
    //                                     <form>
    //                                         <div class="form-group"><textarea class="form-control" id="your_comments" rows="8"
    //                                             placeholder="Your Comments"></textarea></div>
    //                                         <div class="row">
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Name" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="email" class="form-control"
    //                                                     placeholder="Email" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Phone" /></div>
    //                                             </div>
    //                                             <div class="col-lg-6">
    //                                                 <div class="form-group"><input type="text" class="form-control"
    //                                                     placeholder="Subject" /></div>
    //                                             </div>
    //                                         </div>
    //                                         <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
    //                                             A Comment</button></div>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-4">
    //                             <div class="blog-details-item">
    //                                 <div class="blog-details-search">
    //                                     <form><input type="text" class="form-control" placeholder="Search" /><button
    //                                         type="submit" class="btn blog-details-btn"><i
    //                                             class="icofont-search-1"></i></button></form>
    //                                 </div>
    //                                 <div class="blog-details-recent">
    //                                     <h3>Recent Blogs</h3>
    //                                     <ul>
    //                                         <li><img src="../images/blog/30.jpg" alt="Recent" /><NavLink to="/blogs/open-heart-surgery">Open Heart Surgery</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 15 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <br />
    //                                         <li><img src="../images/blog/Allergy5.jpg" alt="Recent" /><NavLink to="/blogs/children-allergy-symptoms">Children Allergy Symptoms</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 15 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                         <br />
    //                                         <br />
    //                                         <li><img src="../images/blog/29.jpg" alt="Recent" /><NavLink to="/blogs/women-heart-health">Women Heart Health</NavLink>
    //                                             <ul>
    //                                                 <li><i class="icofont-calendar"></i> December, 15 2023</li>
    //                                             </ul>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // } else {
    //     return (
    //         <>
    //             <LoadingBar color='blue' progress={100} height={5} />
    //             <Helmet>
    //                 <title>Blogs Not Found | DHPFinder</title>
    //                 <meta name="description" content="Blogs Not Found" />
    //             </Helmet>
    //             <div><h2 className="text-center mt-5 mb-5 my-auto"><b>Blogs Not Found</b></h2><br /><br /></div>
    //         </>
    //     )
    // }
}