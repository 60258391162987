import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import LoadingBar from "react-top-loading-bar";
import BASE_URL from "../../API";
import { NavLink, useParams } from "react-router-dom";

export default function DoctorSpecialities() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    const parameter = useParams();
    const [progress, setProgress] = useState(70);
    const [specialities, setspecialities] = useState([]);
    const [specialitieserr, setspecialitieserr] = useState(0);
    const [length, setlength] = useState(0);
    const [doctors, setdoctors] = useState([]);
    const [doctorserr, setdoctorserr] = useState(0);
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        axios.get(BASE_URL.URL1 + "/api/Doctor/GetDoctorSpecialties?state=" + parameter.parameter1.replace(/-/g, ' ').trim() + "&city=" + parameter.parameter2.replace(/-/g, ' ').trim())
            .then(response => {
                if (response.data.length == 0) {
                    setspecialitieserr(1)
                    setProgress(100)
                } else {
                    setspecialities(response.data)
                    setProgress(100)
                }
                axios.get(BASE_URL.URL1 + "/api/Doctor/GetRandomDoctorsByCity?state=" + parameter.parameter1.replace(/-/g, ' ').trim() + "&city=" + parameter.parameter2.replace(/-/g, ' ').trim())
                    .then(response => {
                        if (response.data.length == 0 && doctors.length == 0) {
                            setdoctorserr(1)
                            setProgress(100)
                        } else if (response.data.length == 0 && doctors.length > 0) {
                            setlength(response.data.length)
                            setProgress(100)
                        } else {
                            //setdoctors(pre => [...pre, ...response.data])
                            setdoctors(response.data)
                            setlength(response.data.length)
                            setProgress(100)
                        }
                    })
                    .catch(err => {
                        //console.log(err);
                        setProgress(100)
                    })
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
            })
    }, []);
    //console.log(specialities);
    //console.log(doctors);

    return (
        <>
            <Helmet>
                <title>Best Doctors near {parameter.parameter2.replace(/-/g, ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase())}, {parameter.parameter1.replace(/-/g, ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase())} | DHPFinder</title>
                <meta name="description" content={"Best Doctors in " + parameter.parameter2.replace(/-/g, ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + ', ' + parameter.parameter1.replace(/-/g, ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase())} />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            <div className="page-title-area" style={{ backgroundImage: 'url(../../../images/doctors/mainbannerdoctor.jpg)' }}>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="page-title-item">
                            <h3 className="text-white" style={{ textTransform: 'capitalize' }}><b>Find Doctors in {parameter.parameter2.replace(/-/g, ' ')}</b></h3>
                            <ul>
                                <li><NavLink to="/best-doctors/">Home</NavLink></li>
                                <li class="active">Specialities</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className="services-area">
                {
                    specialitieserr != 1 ?
                        specialities.length > 0 ?
                            <div className="container">
                                <div className="section-title-two"><span>Specialities</span>
                                    <h2 style={{ width: '100%' }}>Select By Speciality</h2>
                                </div>
                                <div className="row border p-2">
                                    {
                                        specialities.map((a =>
                                            <div className="col-md-4 col-12 col-lg-4 mb-2" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <NavLink to={'/best-doctors/' + parameter.parameter1 + '/' + parameter.parameter2 + '/' + a.specialtyName.trim().replace(/ /g, '-').replace('amp;', '').toLowerCase() + '/'}>
                                                    <span style={{ whiteSpace: 'nowrap', fontWeight: '600', fontFamily: 'sans-serif' }}>{a.specialtyName.replace('amp;', '')}</span>
                                                </NavLink>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            : <div className="text-center"><h2><b>Loading...</b></h2></div>
                        : <div className="text-center"><h2><b>Coming Soon</b></h2></div>
                }
            </div>
            <div className="doctors-area doctors-area-two pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 col-12">
                            {
                                <div className="container">
                                    {
                                        doctorserr != 1 ?
                                            doctors.length > 0 &&
                                            doctors.map((d, i) => {
                                                return (
                                                    <NavLink to={'/best-doctors/' + d.state.toLowerCase() + '/' + d.city.toLowerCase() + '/' + d.specialtyName.replace(/ /g, '-').toLowerCase().trim() + '/' + d.doctorName.replace(/,/g, '').replace('.', '').replace(/ /g, '-').toLowerCase().trim() + `-` + d.uid}>
                                                        <div className="row">
                                                            {/* <div className="col-md-6 col-lg-12"> */}
                                                            <div className="card mb-3 doctor-item" style={{ width: '100%' }}>
                                                                <div className="row no-gutters p-3">
                                                                    <div class="col-md-9 col-12" style={{ textAlign: 'left' }}>
                                                                        <div className="d-flex" style={{ marginTop: 'auto' }}>
                                                                            {
                                                                                d.docImage != '' &&
                                                                                    d.gender != 'Male' ?
                                                                                    <img src={d.docImage} className="my-auto" style={{ width: '80px', height: '80px', borderRadius: '50px' }} alt={d.doctorName} />
                                                                                    : <img src='../../../images/doctors/doctor.png' className="my-auto" style={{ width: '70px', height: '70px', borderRadius: '50px' }} alt={d.doctorName} />
                                                                            }
                                                                            <div className="my-auto" style={{ marginLeft: '25px' }}>
                                                                                <h6 className="card-title mb-0 font-weight-bolder" style={{ fontFamily: 'sans-serif' }}><a>{d.doctorName}</a></h6>
                                                                                <span className="text-dark"><p className="card-text mb-0"><small><b>{d.specialtyName}</b></small></p></span>
                                                                                <span className="text-dark"><p className="card-text mb-0"><b>Rating: </b><small>{d.rating.replace('ratings', '%')}</small></p></span>
                                                                                <span className="text-dark"><p className="card-text mb-0"><b><i className="fa fa-map-marker" style={{ color: 'red' }}></i> </b><small>{d.address}</small></p></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-3 col-12 p-2 my-auto">
                                                                        <button className="btn btn-danger w-100">Profile</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* </div> */}
                                                        </div>
                                                    </NavLink>
                                                )
                                            })
                                            : <>
                                                <h4 className="text-center">No Doctors Found</h4>
                                                <br />
                                                <div className="col-12 text-center">
                                                    <NavLink to='/' className='btn btn-danger text-center'>Go To Home</NavLink>
                                                </div>
                                            </>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </>
    )
}