import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, NavLink } from "react-router-dom";
import BASE_URL from '../../API.js';
import LoadingBar from "react-top-loading-bar";

export default function DoctorSpecialityDetails() {
    var parameter = useParams();
    var id = parameter.parameter4.split('-').at(-1).trim();
    var speciality = parameter.parameter3.replace(/&/g, 'and').replace(/-/g, ' ').trim();

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    var newUrl = currentUrl.slice(0, -1);
    if (lastElement == "/") {
        window.history.pushState({}, null, newUrl);
    }

    const [doctordetail, setdoctordetail] = useState([]);
    const [progress, setProgress] = useState(70);
    const [blogs, setblogs] = useState([]);
    const [blogserr, setblogserr] = useState(0);
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        axios.get(BASE_URL.URL1 + "/api/Doctor/GetDoctorSpecialtyDetails?UID=" + id)
            .then(response => {
                setdoctordetail(response.data)
                setProgress(100)
                axios.get(BASE_URL.URL1 + "/api/Blog/GetBlogsBySpeciality?speciality=" + speciality)
                    .then(response => setblogs(response.data))
                    .catch(err => {
                        //console.log(err);
                        setblogserr(1)
                    })
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
            })
    }, []);
    //console.log(doctordetail);
    //console.log(blogs);

    return (
        <>
            <Helmet>
                <title>{doctordetail.length > 0 ? doctordetail[0].doctorName.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + " - (" + doctordetail[0].specialtyName.trim() + ') Specialist in ' + doctordetail[0].city + ', ' + doctordetail[0].stateID + ' | DHPFinder' : ''}</title>
                <meta name="description" content={doctordetail.length > 0 && doctordetail[0].doctorName.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + ' - (' + doctordetail[0].specialtyName.trim() + ') Specialist in ' + doctordetail[0].city + ', ' + doctordetail[0].stateID} />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            {
                doctordetail.length > 0 ?
                    <>
                        <div className="page-title-area" style={{ backgroundImage: 'url(../../../../images/doctors/mainbannerdoctor.jpg)' }}>
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="page-title-item">
                                        <h3 className="text-white"><b>{doctordetail[0].doctorName} ({doctordetail[0].specialtyName.trim()})</b></h3>
                                        <ul>
                                            <li><NavLink to="/best-doctors/">Home</NavLink></li>
                                            <li class="active">Doctor Details</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="doctor-details-area">
                            <div className="container">
                                <div className="row doctor-details-item py-5 px-3">
                                    <div className="col-md-6 my-auto">
                                        <h3>{doctordetail[0].doctorName}</h3>
                                        <p><b>Gender: </b>{doctordetail[0].gender} . {doctordetail[0].age}</p>
                                        <p><b>Speciality: </b>{doctordetail[0].specialtyName}</p>
                                        <p>{doctordetail[0].rating != null && <><b>Rating: </b>{doctordetail[0].rating}</>}</p>
                                        <p><b>Biography: </b> {doctordetail[0].doctorName} is a {doctordetail[0].doctorSpecialty} in {doctordetail[0].city}. Office accepts new patients and telehealth appointments.</p>
                                    </div>
                                    <div className="col-md-2 my-auto text-center">
                                        {
                                            doctordetail[0].docImage != '' ?
                                                <img style={{ width: "65%" }} src={doctordetail[0].docImage} alt={doctordetail[0].doctorName} className="doctor-details-left" />
                                                : <img src='../../../../images/doctors/doctor.png' style={{ width: '65%' }} alt={doctordetail[0].doctorName} />
                                        }
                                    </div>
                                    <div className="col-md-4 doctor-details-contact doctor-details-left pt-4">
                                        <h3 className="text-center">Doctor Contact info</h3>
                                        <ul>
                                            <li><i class="icofont-ui-call"></i>Call: <a href={'tel:' + doctordetail[0].phoneNumber1}>{doctordetail[0].phoneNumber1}</a></li>
                                            <li><b><svg class="map-icon" data-qa-target="map-icon" height="24" id="marker-XX6BRB01" version="1" width="18"><path d="M0 9c0 4 4 10 7 13l2 2 2-2c3-3 7-9 7-13 0-5-4-9-9-9S0 4 0 9z"></path><text text-anchor="middle" x="9" y="13">1</text></svg></b>&nbsp;{doctordetail[0].address1}</li>
                                            <h3 className="text-center"><a href={doctordetail[0].mapDirection1} target="_blank">Direction</a></h3>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            doctordetail[0].education == null || doctordetail[0].education == "" ? "" :
                                <div class="doctor-details-area">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-7">
                                                <div class="doctor-details-item py-5 pr-4">
                                                    <div class="doctor-details-biography">
                                                        <h3>Education</h3>
                                                        <ul>
                                                            {
                                                                doctordetail[0].education.split('//').map((e =>
                                                                    <li><b><i class="fa fa-circle" style={{ fontSize: '8px' }} aria-hidden="true"></i></b> {e}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        {
                            doctordetail[0].otherSpecialties == null || doctordetail[0].otherSpecialties == "" ? "" :
                                <div class="doctor-details-area">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-7">
                                                <div class="doctor-details-item py-5 pr-4">
                                                    <div class="doctor-details-biography">
                                                        <h3>Other Specialities</h3>
                                                        <ul>
                                                            {
                                                                doctordetail[0].otherSpecialties.split('//').map((e =>
                                                                    <li><b><i class="fa fa-circle" style={{ fontSize: '8px' }} aria-hidden="true"></i></b> {e}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        {
                            doctordetail[0].comments == null || doctordetail[0].comments == "" ? "" :
                                <div class="doctor-details-area">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-7">
                                                <div class="doctor-details-item py-5 pr-4">
                                                    <div class="doctor-details-biography">
                                                        <h3>Comments</h3>
                                                        <ul>
                                                            {
                                                                doctordetail[0].comments.split('//').map((e =>
                                                                    <li className="border p-2"><b></b> {e.replaceAll('&quot;', ' ').replaceAll('&#x27;', '')}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        <div class="pb-100">
                            <div class="appointment-area-three">
                                <div class="container-fluid p-0">
                                    <div class="row m-0 justify-content-center">
                                        <div class="col-12 col-md-7 my-auto">
                                            <div class="appointment-item appointment-item-two" style={{ maxWidth: '100%', marginRight: 'auto', padding: '30px 45px' }}>
                                                <div class="blog-details-form">
                                                    <div class="blog-details-form-wrap" style={{ maxWidth: '100%' }}>
                                                        <h2>Drop your comment</h2>
                                                        <form>
                                                            <div class="form-group"><textarea class="form-control" id="your_comments" rows="4"
                                                                placeholder="Your Comments"></textarea></div>
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="form-group"><input type="text" class="form-control"
                                                                        placeholder="Name" /></div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="form-group"><input type="email" class="form-control"
                                                                        placeholder="Email" /></div>
                                                                </div>
                                                            </div>
                                                            <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
                                                                A Comment</button></div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="doctor-details-area pb-70">
                            <div className="container">
                                <div className="row border pt-3">
                                    <div className="col-12">
                                        <img style={{ width: "100%" }} src={'../../../../images/map.png'} alt={doctordetail[0].doctorName} />
                                    </div>
                                    <div className="col-12 mt-3 pt-3">
                                        <h3 className="font-weight-bold">Contact info</h3>
                                        <div>
                                            {doctordetail[0].groupPractice1 != '' && <span><b>{doctordetail[0].groupPractice1.replaceAll("&#x27;", "'")}</b></span>}
                                            <br />
                                            {doctordetail[0].address1 != '' && <span>
                                                <b><svg class="map-icon" data-qa-target="map-icon" height="24" id="marker-XX6BRB01" version="1" width="18"><path d="M0 9c0 4 4 10 7 13l2 2 2-2c3-3 7-9 7-13 0-5-4-9-9-9S0 4 0 9z"></path><text text-anchor="middle" x="9" y="13">1</text></svg></b>&nbsp;
                                                {doctordetail[0].address1}</span>}
                                            <br />
                                            {doctordetail[0].phoneNumber1 != '' && <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="icofont-ui-call"></i><b>Call: </b><a href={'tel:' + doctordetail[0].phoneNumber1}>{doctordetail[0].phoneNumber1}</a></span>}
                                            <br />
                                            {doctordetail[0].fax1 != '' && <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-fax"></i>&nbsp;<b>Fax: </b><a>{doctordetail[0].fax1}</a></span>}
                                            {doctordetail[0].mapDirection1 != '' && <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href={doctordetail[0].mapDirection1} target="_blank" className="font-weight-bold">Direction</a></p>}
                                        </div>
                                        {
                                            doctordetail[0].phoneNumber2 == '' && doctordetail[0].fax2 == '' && doctordetail[0].address2 == '' && doctordetail[0].mapDirection2 == '' && doctordetail[0].groupPractice2 == '' ? '' :
                                                <>
                                                    <hr />
                                                    <div>
                                                        {doctordetail[0].groupPractice2 != '' && <span><b>{doctordetail[0].groupPractice2.replaceAll("&#x27;", "'")}</b></span>}
                                                        <br />
                                                        {doctordetail[0].address2 != '' && <span>
                                                            <b><svg class="map-icon" data-qa-target="map-icon" height="24" id="marker-XX6BRB01" version="1" width="18"><path d="M0 9c0 4 4 10 7 13l2 2 2-2c3-3 7-9 7-13 0-5-4-9-9-9S0 4 0 9z"></path><text text-anchor="middle" x="9" y="13">2</text></svg></b>&nbsp;
                                                            {doctordetail[0].address2}</span>}
                                                        <br />
                                                        {doctordetail[0].phoneNumber2 != '' && <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="icofont-ui-call"></i><b>Call: </b><a href={'tel:' + doctordetail[0].phoneNumber2}>{doctordetail[0].phoneNumber2}</a></span>}
                                                        <br />
                                                        {doctordetail[0].fax2 != '' && <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-fax"></i>&nbsp;<b>Fax: </b><a>{doctordetail[0].fax2}</a></span>}
                                                        {doctordetail[0].mapDirection2 != '' && <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href={doctordetail[0].mapDirection2} target="_blank" className="font-weight-bold">Direction</a></p>}
                                                    </div>
                                                </>
                                        }
                                        {
                                            doctordetail[0].phoneNumber3 == '' && doctordetail[0].fax3 == '' && doctordetail[0].address3 == '' && doctordetail[0].mapDirection3 == '' && doctordetail[0].groupPractice3 == '' ? '' :
                                                <>
                                                    <hr />
                                                    <div>
                                                        {doctordetail[0].groupPractice3 != '' && <span><b>{doctordetail[0].groupPractice3.replaceAll("&#x27;", "'")}</b></span>}
                                                        <br />
                                                        {doctordetail[0].address3 != '' && <span>
                                                            <b><svg class="map-icon" data-qa-target="map-icon" height="24" id="marker-XX6BRB01" version="1" width="18"><path d="M0 9c0 4 4 10 7 13l2 2 2-2c3-3 7-9 7-13 0-5-4-9-9-9S0 4 0 9z"></path><text text-anchor="middle" x="9" y="13">3</text></svg></b>&nbsp;
                                                            {doctordetail[0].address3}</span>}
                                                        <br />
                                                        {doctordetail[0].phoneNumber3 != '' && <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="icofont-ui-call"></i><b>Call: </b><a href={'tel:' + doctordetail[0].phoneNumber3}>{doctordetail[0].phoneNumber3}</a></span>}
                                                        <br />
                                                        {doctordetail[0].fax3 != '' && <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-fax"></i>&nbsp;<b>Fax: </b><a>{doctordetail[0].fax3}</a></span>}
                                                        {doctordetail[0].mapDirection3 != '' && <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href={doctordetail[0].mapDirection3} target="_blank" className="font-weight-bold">Direction</a></p>}
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="doctors-area doctors-area-two">
                            {
                                doctordetail[0].affiliatedHospital1 == '' && doctordetail[0].affiliatedHospital2 == '' && doctordetail[0].affiliatedHospital3 == '' && doctordetail[0].affiliatedHospital4 == '' && doctordetail[0].affiliatedHospital5 == '' ? ''
                                    : <div className="container">
                                        <h3 className="font-weight-bold pb-3">Affiliated Hospitals</h3>
                                        <div className="row">
                                            {
                                                doctordetail[0].affiliatedHospital1 != '' &&
                                                <div className="col-md-7 col-12">
                                                    <div className="card mb-3 doctor-item" style={{ width: '100%' }}>
                                                        <div className="row no-gutters p-3">
                                                            <div class="col-md-9 col-12" style={{ textAlign: 'left' }}>
                                                                <div className="d-flex" style={{ marginTop: 'auto' }}>
                                                                    <img src='../../../../images/hospitals/hospital.png' style={{ width: '80px', height: '80px', borderRadius: '50px' }} alt='' />
                                                                    <div className="my-auto" style={{ marginLeft: '25px' }}>
                                                                        <h6 className="card-title mb-0 font-weight-bolder" style={{ fontFamily: 'sans-serif' }}>{doctordetail[0].affiliatedHospital1}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-12 p-2 my-auto">
                                                                <button className="btn btn-danger w-100">Details</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                doctordetail[0].affiliatedHospital2 != '' &&
                                                <div className="col-md-7 col-12">
                                                    <br />
                                                    <div className="card mb-3 doctor-item" style={{ width: '100%' }}>
                                                        <div className="row no-gutters p-3">
                                                            <div class="col-md-9 col-12" style={{ textAlign: 'left' }}>
                                                                <div className="d-flex" style={{ marginTop: 'auto' }}>
                                                                    <img src='../../../../images/hospitals/hospital.png' style={{ width: '80px', height: '80px', borderRadius: '50px' }} alt='' />
                                                                    <div className="my-auto" style={{ marginLeft: '25px' }}>
                                                                        <h6 className="card-title mb-0 font-weight-bolder" style={{ fontFamily: 'sans-serif' }}>{doctordetail[0].affiliatedHospital2}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-12 p-2 my-auto">
                                                                <button className="btn btn-danger w-100">Details</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                doctordetail[0].affiliatedHospital3 != '' &&
                                                <div className="col-md-7 col-12">
                                                    <br />
                                                    <div className="card mb-3 doctor-item" style={{ width: '100%' }}>
                                                        <div className="row no-gutters p-3">
                                                            <div class="col-md-9 col-12" style={{ textAlign: 'left' }}>
                                                                <div className="d-flex" style={{ marginTop: 'auto' }}>
                                                                    <img src='../../../../images/hospitals/hospital.png' style={{ width: '80px', height: '80px', borderRadius: '50px' }} alt='' />
                                                                    <div className="my-auto" style={{ marginLeft: '25px' }}>
                                                                        <h6 className="card-title mb-0 font-weight-bolder" style={{ fontFamily: 'sans-serif' }}>{doctordetail[0].affiliatedHospital3}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-12 p-2 my-auto">
                                                                <button className="btn btn-danger w-100">Details</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                doctordetail[0].affiliatedHospital4 != '' &&
                                                <div className="col-md-7 col-12">
                                                    <br />
                                                    <div className="card mb-3 doctor-item" style={{ width: '100%' }}>
                                                        <div className="row no-gutters p-3">
                                                            <div class="col-md-9 col-12" style={{ textAlign: 'left' }}>
                                                                <div className="d-flex" style={{ marginTop: 'auto' }}>
                                                                    <img src='../../../../images/hospitals/hospital.png' style={{ width: '80px', height: '80px', borderRadius: '50px' }} alt='' />
                                                                    <div className="my-auto" style={{ marginLeft: '25px' }}>
                                                                        <h6 className="card-title mb-0 font-weight-bolder" style={{ fontFamily: 'sans-serif' }}>{doctordetail[0].affiliatedHospital4}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-12 p-2 my-auto">
                                                                <button className="btn btn-danger w-100">Details</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                doctordetail[0].affiliatedHospital5 != '' &&
                                                <div className="col-md-7 col-12">
                                                    <br />
                                                    <div className="card mb-3 doctor-item" style={{ width: '100%' }}>
                                                        <div className="row no-gutters p-3">
                                                            <div class="col-md-9 col-12" style={{ textAlign: 'left' }}>
                                                                <div className="d-flex" style={{ marginTop: 'auto' }}>
                                                                    <img src='../../../../images/hospitals/hospital.png' style={{ width: '80px', height: '80px', borderRadius: '50px' }} alt='' />
                                                                    <div className="my-auto" style={{ marginLeft: '25px' }}>
                                                                        <h6 className="card-title mb-0 font-weight-bolder" style={{ fontFamily: 'sans-serif' }}>{doctordetail[0].affiliatedHospital5}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-12 p-2 my-auto">
                                                                <button className="btn btn-danger w-100">Details</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                        <br />
                        <br />
                        <div class="doctor-details-area pb-70">
                            <div className="container">
                                <h4 style={{ marginLeft: '-1%' }}><b><u>Frequently Asked Questions</u></b></h4>
                                <br />
                                <div className="row">
                                    <div className="col-md-8 border pt-3">
                                        <h6>Where is {doctordetail[0].doctorName.replaceAll("&#x27;", "'")} office located?</h6>
                                        <p>Office of {doctordetail[0].doctorName.replaceAll("&#x27;", "'")} is located at {doctordetail[0].address}</p>
                                    </div>
                                    <div className="col-md-8 border pt-3 mt-1">
                                        <h6>How can I make an appointment with {doctordetail[0].doctorName.replaceAll("&#x27;", "'")}?</h6>
                                        <p>For appointment contact us: <a href={'tel:' + doctordetail[0].phoneNumber1}>{doctordetail[0].phoneNumber1}</a></p>
                                    </div>
                                    <div className="col-md-8 border pt-3 mt-1">
                                        <h6>What is the specialty of {doctordetail[0].doctorName.replaceAll("&#x27;", "'")}?</h6>
                                        <p>Top specialty of {doctordetail[0].doctorName.replaceAll("&#x27;", "'")} is {doctordetail[0].doctorSpecialty.replaceAll("&#x27;", "'")}</p>
                                    </div>
                                    <div className="col-md-8 border pt-3 mt-1">
                                        <h6>Does {doctordetail[0].doctorName.replaceAll("&#x27;", "'")} offer telehealth??</h6>
                                        <p>For information about telehealth contact us on this number: <a href={'tel:' + doctordetail[0].phoneNumber1}>{doctordetail[0].phoneNumber1}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-area pt-100 pb-70 mb-md-0 mb-3">
                            {
                                blogs.length > 0 &&
                                <div className="container">
                                    <div className="section-title">
                                        <h2>Recommended Blogs</h2>
                                    </div>
                                    <div className="row justify-content-center">
                                        {
                                            blogs.map((b =>
                                                <div className="col-md-6 col-lg-2">
                                                    <NavLink to={'/blogs/' + b.blogHeading.trim().replace(/ /g, '-').toLowerCase()}>
                                                        <div className="blog-item">
                                                            <div className="blog-top">
                                                                <img src={'https://www.dhpfinder.com' + b.blogImage} alt={b.blogHeading} />
                                                            </div>
                                                            <div className="blog-bottom">
                                                                <h6 className="font-weight-bold">{b.blogHeading}</h6>
                                                                <p><small className="text-dark">{b.blogDescription}... Click here for more details.</small></p>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                    : <div className="text-center my-5"><h2><b>Loading...</b></h2></div>
            }

        </>
    )
}