import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useParams, useLocation } from "react-router-dom";
import BASE_URL from "../../API.js";
import LoadingBar from "react-top-loading-bar";

export default function DoctorsBySpeciality() {
    var parameter = useParams();
    const location = useLocation();
    var state = parameter.parameter1.replace(/-/g, ' ').trim();
    var city = parameter.parameter2.replace(/-/g, ' ').trim();
    var speciality = parameter.parameter3.replace(/&/g, 'and').replace(/-/g, ' ').trim();

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    useEffect(() => {
        setdoctors([])
        setdoctorserr(0)
    }, [location.key]);

    const [progress, setProgress] = useState(70);
    const [count, setcount] = useState(0);
    const [page, setpage] = useState(1);
    const [length, setlength] = useState(0);
    const [doctors, setdoctors] = useState([]);
    const [doctorserr, setdoctorserr] = useState(0);
    const [hospital, sethospital] = useState([]);
    const [lengthh, setlengthh] = useState(0);
    const [hospitalerr, sethospitalerr] = useState(0);
    const [blogs, setblogs] = useState([]);
    const [blogserr, setblogserr] = useState(0);
    useEffect(() => {
        setProgress(50)
        axios.get(BASE_URL.URL1 + "/api/Doctor/GetDoctorBySpecialtyData?state=" + state + "&city=" + city + "&specialty=" + speciality)
            .then(response => {
                if (response.data.length == 0 && doctors.length == 0) {
                    setdoctorserr(1)
                    setProgress(100)
                } else if (response.data.length == 0 && doctors.length > 0) {
                    setlength(response.data.length)
                    setProgress(100)
                } else {
                    //setdoctors(pre => [...pre, ...response.data])
                    setdoctors(response.data)
                    setlength(response.data.length)
                    setProgress(100)
                }
                axios.get(BASE_URL.URL1 + "/api/Blog/GetBlogsBySpeciality?speciality=" + speciality)
                    .then(response => {
                        setblogs(response.data)
                        axios.get(BASE_URL.URL1 + "/api/Home/GetRandomHospitalsByStateAndCity?state=" + state + "&city=" + city)
                            .then(response => {
                                if (response.data.length > 0) {
                                    setlengthh(response.data.length)
                                    sethospital(response.data)
                                } else {
                                    setlengthh(response.data.length)
                                }
                            })
                            .catch(err => {
                                //console.log(err);
                                sethospitalerr(1)
                            })
                    })
                    .catch(err => {
                        //console.log(err);
                        setblogserr(1)
                    })
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
            })
    }, []);
    //console.log(doctors);
    //console.log(hospital);
    //console.log(blogs);

    return (
        <>
            <Helmet>
                <title>Best {parameter.parameter3.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} near {parameter.parameter2.replace('and', '&').replace(/-/g, ' ').trim().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}, {parameter.parameter1.replace('and', '&').replace(/-/g, ' ').trim().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} | DHPFinder</title>
                <meta name="description" content={"DHPFinder can help you to find best (" + parameter.parameter3.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + ") specialist near you by weighing reviews and other important factor."} />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            <div className="page-title-area" style={{ backgroundImage: 'url(../../../../images/doctors/mainbannerdoctor.jpg)' }}>
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="page-title-item">
                            <h3 className="text-white" style={{ textTransform: 'capitalize' }}><b>Meet Your Qualified {parameter.parameter3.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim()}</b></h3>
                            <ul>
                                <li><NavLink to="/best-doctors/">Home</NavLink></li>
                                <li className="active text-capitalize">{parameter.parameter3.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim()}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className="doctors-area doctors-area-two pt-100 pb-70">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9 col-12">
                            {
                                <div className="container">
                                    {
                                        doctorserr != 1 ?
                                            doctors.length > 0 ?
                                                doctors.map((d, i) => {
                                                    return (
                                                        <NavLink to={'/best-doctors/' + parameter.parameter1 + '/' + parameter.parameter2 + '/' + parameter.parameter3 + '/' + d.doctorName.replace(/,/g, '').replace('.', '').replace(/ /g, '-').toLowerCase().trim() + `-` + d.uid}>
                                                            <div className="row">
                                                                {/* <div className="col-md-6 col-lg-12"> */}
                                                                <div className="card mb-3 doctor-item" style={{ width: '100%' }}>
                                                                    <div className="row no-gutters p-3">
                                                                        <div class="col-md-9 col-12" style={{ textAlign: 'left' }}>
                                                                            <div className="d-flex" style={{ marginTop: 'auto' }}>
                                                                                {
                                                                                    d.docImage != '' &&
                                                                                        d.gender != 'Male' ?
                                                                                        <img src={d.docImage} className="my-auto" style={{ width: '80px', height: '80px', borderRadius: '50px' }} alt={d.doctorName} />
                                                                                        : <img src='../../../../images/doctors/doctor.png' className="my-auto" style={{ width: '70px', height: '70px', borderRadius: '50px' }} alt={d.doctorName} />
                                                                                }
                                                                                <div className="my-auto" style={{ marginLeft: '25px' }}>
                                                                                    <h6 className="card-title mb-0 font-weight-bolder" style={{ fontFamily: 'sans-serif' }}><a>{d.doctorName}</a></h6>
                                                                                    <span className="text-dark"><p className="card-text mb-0"><small><b>{d.specialtyName}</b></small></p></span>
                                                                                    <span className="text-dark"><p className="card-text mb-0"><b>Rating: </b><small>{d.rating.replace('ratings', '%')}</small></p></span>
                                                                                    <span className="text-dark"><p className="card-text mb-0"><b><i className="fa fa-map-marker" style={{ color: 'red' }}></i> </b><small>{d.address}</small></p></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3 col-12 p-2 my-auto">
                                                                            <button className="btn btn-danger w-100">Profile</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* </div> */}
                                                            </div>
                                                        </NavLink>
                                                    )
                                                })
                                                : <div className="text-center"><h2><b>Loading...</b></h2></div>
                                            : <>
                                                <h4 className="text-center">No Doctors Found</h4>
                                                <br />
                                                <div className="col-12 text-center">
                                                    <NavLink to='/' className='btn btn-danger text-center'>Go To Home</NavLink>
                                                </div>
                                            </>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog-area pt-100 pb-70 mb-md-0 mb-3">
                {
                    blogs.length > 0 &&
                    <div className="container">
                        <div className="section-title">
                            <h2>Recommended Blogs</h2>
                        </div>
                        <div className="row justify-content-center">
                            {
                                blogs.map((b =>
                                    <div className="col-md-6 col-lg-2">
                                        <NavLink to={'/blogs/' + b.blogHeading.trim().replace(/ /g, '-').toLowerCase()}>
                                            <div className="blog-item">
                                                <div className="blog-top">
                                                    <img src={'https://www.dhpfinder.com' + b.blogImage} alt={b.blogHeading} />
                                                </div>
                                                <div className="blog-bottom">
                                                    <h6 className="font-weight-bold">{b.blogHeading}</h6>
                                                    <p><small className="text-dark">{b.blogDescription}... Click here for more details.</small></p>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
            </div>
            <div className="doctors-area doctors-area-two pt-100">
                {
                    hospitalerr != 1 &&
                    lengthh > 0 &&
                    <div className="container">
                        <h3 className="text-capitalize font-weight-bold text-center">{city} Top Rated Hospitals</h3>
                        <div className="row mt-4 mb-3 justify-content-center">
                            <div className="col-md-9 col-12">
                                {
                                    <div className="container">
                                        {
                                            hospital.length > 0 &&
                                            hospital.map((d, i) => {
                                                return (
                                                    <NavLink to={`/best-hospitals/` + d.state.replace(/ /g, '-').toLowerCase() + `/` + d.city.replace(/ /g, '-').toLowerCase() + `/` + d.hospitalName.replaceAll(/,/g, '').replaceAll('.', '').replaceAll('/', '-').replace(/ /g, '-').toLowerCase().trim() + `-` + d.uid} className="mb-3 w-100">
                                                        <div className="row">
                                                            {/* <div class="col-md-6 col-lg-12"> */}
                                                            <div class="card mb-2 doctor-item" style={{ maxWidth: '100%' }}>
                                                                <div class="row no-gutters p-2">
                                                                    <div class="col-md-9 col-12" style={{ textAlign: 'left' }}>
                                                                        <div className="d-flex" style={{ marginTop: 'auto' }}>
                                                                            <img src='../../../../images/hospitals/hospital.png' style={{ width: '80px', height: '80px', borderRadius: '50px' }} alt={d.hospitalName} />
                                                                            <div className="my-auto" style={{ marginLeft: '25px' }}>
                                                                                <h6 class="card-title mb-0 font-weight-bolder" style={{ fontFamily: 'sans-serif' }}><a>{d.hospitalName}</a></h6>
                                                                                {d.rating != "" && <span className="text-dark"><p class="card-text mb-0"><b>Rating: </b><small>{d.rating}</small></p></span>}
                                                                                <span className="text-dark"><p class="card-text mb-0"><b><i className="fa fa-map-marker" style={{ color: 'red' }}></i> </b><small>{d.address}</small></p></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-3 col-12 p-2 my-auto">
                                                                        <button className="btn btn-danger w-100">Details</button>
                                                                    </div>
                                                                </div>
                                                                {/* </div> */}
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row mt-4 justify-content-center">
                            <div className="col-6 text-center">
                                <NavLink to={'/best-hospitals/' + state + '/' + city + '/'} className='btn btn-danger text-center'>See all hospitals in <span className="text-capitalize">{city}</span></NavLink>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <br />
            <br />
        </>
    )
}