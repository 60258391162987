import React from "react";
import { NavLink } from "react-router-dom";
import img from '../logo.png'

export default function Header() {
    const openNav = () => {
        document.getElementById("links").style.width = "70%";
        document.getElementsByClassName('collapse')[0].classList.add('show');
    }

    const closeNav = (e) => {
        if (document.getElementById("navbarSupportedContent") != null) {
            document.getElementById("navbarSupportedContent").classList.remove("show");
        }
    }
    return (
        <>
            <div id="navbar" className="navbar-area sticky-top" style={{ boxShadow: '0 0 15px 0 #ddd' }}>
                <div className="main-nav">
                    <div className="container">
                        <nav class="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: 'white' }}>
                            <NavLink className="navbar-brand" to="/" style={{ width: '15%' }}>
                                <img src={img} className="logo" alt="logo" />
                                <sub><strong>DHPFinder</strong></sub>
                            </NavLink>
                            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>

                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav" id="links">
                                    <li className="nav-item"><NavLink className="nav-link" onClick={() => closeNav()} to="/">Home</NavLink></li>
                                    <li className="nav-item"><NavLink className="nav-link dropdown-toggle" onClick={() => closeNav()} to="/best-doctors/">Find a Doctor</NavLink>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><NavLink className="nav-link false" onClick={() => closeNav()} to="/specialities/best-doctors-for-dentist/">Dentist</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link false" onClick={() => closeNav()} to="/specialities/best-doctors-for-neurologists/">Neurologists</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link false" onClick={() => closeNav()} to="/specialities/best-doctors-for-psychologists/">Psychologists</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link false" onClick={() => closeNav()} to="/specialities/best-doctors-for-psychiatrists/">Psychiatrists</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link false" onClick={() => closeNav()} to="/specialities/best-doctors-for-nephrologists/">Nephrologists</NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><NavLink className="nav-link dropdown-toggle" onClick={() => closeNav()} to="/best-hospitals/">Find a Hospital</NavLink>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><a className="nav-link false" onClick={() => closeNav()} >Pulmonary</a></li>
                                            <li className="nav-item"><a className="nav-link false" onClick={() => closeNav()} >Labor & Delivery</a></li>
                                            <li className="nav-item"><a className="nav-link false" onClick={() => closeNav()} >Stroke</a></li>
                                            <li className="nav-item"><a className="nav-link false" onClick={() => closeNav()} >Critical Care</a></li>
                                            <li className="nav-item"><a className="nav-link false" onClick={() => closeNav()} >Coronary Intervention</a></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><NavLink className="nav-link dropdown-toggle" onClick={() => closeNav()} to="/best-pharmacies/">Find a Pharmacy</NavLink>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><a className="nav-link false" onClick={() => closeNav()} >Pharmacist</a></li>
                                            <li className="nav-item"><a className="nav-link false" onClick={() => closeNav()} >Medical Supplies</a></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><NavLink className="nav-link" onClick={() => closeNav()} to="/blogs/">Blogs</NavLink></li>
                                    <li className="nav-item"><NavLink className="nav-link" onClick={() => closeNav()} to="/write-for-us/">Write for Us</NavLink></li>
                                </ul>
                                {/* <div className="nav-srh">
                                    <form>
                                        <input type="text" className="form-control" id="search" placeholder="Search..." />
                                        <button className="search-icon icon-search" disabled><i className="icofont-search-1"></i></button>
                                    </form>
                                </div> */}
                            </div>
                        </nav>
                    </div>
                </div>
            </div>

            {/* <div id="navbar" className="navbar-area sticky-top">
                <div className="main-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <NavLink className="navbar-brand" to="/" style={{ width: '15%' }}>
                                <img src={logo} className="w-50" alt="logo" />
                                <sub><strong>DHPFinder</strong></sub>
                            </NavLink>
                            <button className="navbar-toggler navbar-toggler-right collapsed" onClick={() => openNav()} type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                                aria-label="Toggle navigation"><span className="icon-bar top-bar"></span><span
                                    className="icon-bar middle-bar"></span><span className="icon-bar bottom-bar"></span></button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav" id="links">
                                    <li className="nav-item"><NavLink className="nav-link" to="/">Home</NavLink></li>
                                    <li className="nav-item"><NavLink className="nav-link dropdown-toggle" to="/best-doctors/">Find a Doctor</NavLink>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><NavLink className="nav-link false" to="/specialities/best-doctors-for-dentist">Dentist</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link false" to="/specialities/best-doctors-for-neurologists">Neurologists</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link false" to="/specialities/best-doctors-for-psychologists">Psychologists</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link false" to="/specialities/best-doctors-for-psychiatrists">Psychiatrists</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link false" to="/specialities/best-doctors-for-nephrologists">Nephrologists</NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><NavLink className="nav-link dropdown-toggle" to="/best-hospitals/">Find a Hospital</NavLink>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><NavLink className="nav-link false" >Best Hospitals</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link false" >Women's Care</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link false" >Pulmonary</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link false" >Labor & Delivery</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link false" >Stroke</NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><NavLink className="nav-link dropdown-toggle" to="/best-pharmacies/">Find a Pharmacy</NavLink>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><NavLink className="nav-link false" >Pharmacist</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link false" >Medical Supplies</NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><NavLink className="nav-link" to="/blogs/">Blogs</NavLink></li>
                                </ul>
                            </div>
                            <div className="nav-srh">
                                <form><input type="text" className="form-control" id="search" placeholder="Search..." /><button
                                    className="search-icon icon-search"><i className="icofont-search-1"></i></button></form>
                            </div>
                        </nav>
                    </div>
                </div>
            </div> */}
        </>
    )
}