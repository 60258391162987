import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import LoadingBar from "react-top-loading-bar"
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import BASE_URL from "../../API";

export default function Index() {
    window.history.pushState({}, null, '/');

    const [progress, setProgress] = useState(70)
    const [blogs, setblogs] = useState([]);
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        axios.get(BASE_URL.URL1 + "/api/Blog/GetRandomBlogsForMain")
            .then(response => {
                setblogs(response.data)
                setProgress(100)
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
            })
    }, []);
    //console.log(blogs);

    return (
        <>
            <Helmet>
                <title>Best Qualified Doctors - Hospitals - Online Doctor Appointment | DHPFinder</title>
                <meta name="description" content="Finding Qualified doctor means focusing on the elements that can really affect your health." />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            <div className="banner-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="banner-item">
                                <h1>Your Healthy Life is Our First Priority</h1>
                                <p>Find the proper medical care supplier for your necessities in the US. We connect you with top specialists in view of their specialization, experience, and client appraisals.</p>
                                <div className="common-btn-two"><NavLink>Get Appointment</NavLink>
                                    <NavLink className="cmn-btn-right-two">Learn More</NavLink></div>
                                <div className="banner-right" style={{ position: 'unset' }}>
                                    <img src="../images/home-three/BANNER 2.png" alt="Banner" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="speciality-area pb-100">
                <div className="container-fluid p-0">
                    <div className="row m-0">
                        <div className="col-lg-7">
                            <div className="speciality-left">
                                <div className="section-title-two"><span>Speciality</span>
                                    <h2>DHPFinder Expertise</h2>
                                </div>
                                <div className="speciality-item">
                                    <div className="row m-0">
                                        <div className="col-sm-6 col-lg-6">
                                            <div className="speciality-inner"><i className="icofont-check-circled"></i>
                                                <h3>Child Care</h3>
                                                <p>Discover trusted pediatric care tailored to your child's needs.</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-6">
                                            <div className="speciality-inner"><i className="icofont-check-circled"></i>
                                                <h3>More Stuff</h3>
                                                <p>Explore endless possibilities with Stuff your gateway to abundance.</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-6">
                                            <div className="speciality-inner"><i className="icofont-check-circled"></i>
                                                <h3>Enough Lab</h3>
                                                <p>Empowering lives through knowledge and innovation at Enough Lab.</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-6">
                                            <div className="speciality-inner"><i className="icofont-check-circled"></i>
                                                <h3>24 Hour Doctor</h3>
                                                <p>Accessible, round-the-clock healthcare for your peace of mind.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 pr-0 mt-auto">
                            <div className="speciality-item"> {/*  speciality-right */}
                                <img src="../images/blog/33.jpg" alt="Speciality" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-area pb-70">
                <div className="container">
                    <div className="section-title-two"><span>Services</span>
                        <h2>DHP Finder Services</h2>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-6 col-lg-3">
                            <NavLink to="/best-doctors/">
                                <div className="service-item bg rounded-4">
                                    <div className="service-front"><img src="../images/blog/doctor.png" style={{ width: '30%' }} />
                                        <h3>Find A Doctor</h3>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <NavLink to="/best-hospitals/">
                                <div className="service-item bg rounded-4">
                                    <div className="service-front"><img src="../images/blog/hospital.png" style={{ width: '30%' }} />
                                        <h3>Find A Hospital</h3>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <NavLink to="/best-pharmacies/">
                                <div className="service-item bg rounded-4">
                                    <div className="service-front"><img src="../images/blog/pharmacy.png" style={{ width: '30%' }} />
                                        <h3>Find A Pharmacy</h3>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <NavLink to="/best-urgent-care/">
                                <div className="service-item bg rounded-4">
                                    <div className="service-front"><img src="../images/blog/urgent-care.png" style={{ width: '30%' }} />
                                        <h3>Find An Urgent Care</h3>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <NavLink to="/best-group-practice/">
                                <div className="service-item bg rounded-4">
                                    <div className="service-front"><img src="../images/blog/medical-supplier.png" style={{ width: '30%' }} />
                                        <h3>Find Group Practice</h3>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <NavLink>
                                <div className="service-item bg rounded-4">
                                    <div className="service-front"><img src="../images/blog/nursing.png" style={{ width: '30%' }} />
                                        <h3>Find Nursing</h3>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <NavLink>
                                <div className="service-item bg rounded-4">
                                    <div className="service-front"><img src="../images/blog/home-health.png" style={{ width: '30%' }} />
                                        <h3>Find Home Health</h3>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <NavLink>
                                <div className="service-item bg rounded-4">
                                    <div className="service-front"><img src="../images/blog/dialysis-facility.png" style={{ width: '30%' }} />
                                        <h3>Find Dialysis Facility</h3>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <NavLink>
                                <div className="service-item bg rounded-4">
                                    <div className="service-front"><img src="../images/blog/dental-clinic.png" style={{ width: '30%' }} />
                                        <h3>Find A Dental Clinic</h3>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <NavLink>
                                <div className="service-item bg rounded-4">
                                    <div className="service-front"><img src="../images/blog/pharmacist.png" style={{ width: '30%' }} />
                                        <h3>Find A Pharmacist</h3>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <NavLink>
                                <div className="service-item bg rounded-4">
                                    <div className="service-front"><img src="../images/blog/mental-health.png" style={{ width: '30%' }} />
                                        <h3>Find Mental Health</h3>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <NavLink>
                                <div className="service-item bg rounded-4">
                                    <div className="service-front"><img src="../images/blog/surgery-centre.png" style={{ width: '30%' }} />
                                        <h3>Find Surgery Center</h3>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="welcome-area pb-100">
                <div className="container-fluid p-0">
                    <div className="row m-0">
                        <div className="col-lg-6 pl-0">
                            <div className="welcome-item welcome-left-two"> {/*  welcome-left */}
                                <img src="../images/blog/34.jpg" alt="Welcome" /></div>
                        </div>
                        <div className="col-lg-6">
                            <div className="welcome-item welcome-right mt-3">
                                <div className="section-title-two"><span>About Us</span>
                                    <h2>Why you choose DHPFinder</h2>
                                </div>
                                <ul>
                                    <li><i className="icofont-doctor-alt"></i>
                                        <div className="welcome-inner">
                                            <h3>Certified Doctors</h3>
                                            <p>Trust your health in the hands of certified professionals.
                                                Experience peace of mind with our accredited team of dedicated doctors</p>
                                        </div>
                                    </li>
                                    <li><i className="icofont-stretcher"></i>
                                        <div className="welcome-inner">
                                            <h3>Emergency 24 hours</h3>
                                            <p>Reliable care at any hour, because emergencies don't wait.
                                                Count on us 24/7 for immediate and expert medical attention.</p>
                                        </div>
                                    </li>
                                    <li><i className="icofont-network"></i>
                                        <div className="welcome-inner">
                                            <h3>Modern Technologey</h3>
                                            <p>Experience the future of innovation at your fingertips. Transforming
                                                possibilities with cutting-edge technology for a smarter tomorrow.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-area pt-100 pb-70 mb-md-0 mb-3">
                {
                    blogs.length > 0 &&
                    <div className="container">
                        <div className="section-title">
                            <h2>Our Latest Blogs</h2>
                        </div>
                        <div className="row justify-content-center">
                            {
                                blogs.map((b =>
                                    <div className="col-md-6 col-lg-3">
                                        <NavLink to={'/blogs/' + b.blogHeading.trim().replace(/ /g, '-').toLowerCase()}>
                                            <div className="blog-item">
                                                <div className="blog-top">
                                                    <img src={'https://www.dhpfinder.com' + b.blogImage} alt={b.blogHeading} />
                                                </div>
                                                <div className="blog-bottom">
                                                    <h6 className="font-weight-bold">{b.blogHeading}</h6>
                                                    <label className="text-dark">{b.blogDescription}</label>
                                                    <ul>
                                                        <li></li>
                                                        <li>Read More <i className="icofont-long-arrow-right"></i></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
            </div>
        </>
    )
}