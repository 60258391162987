import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, NavLink } from "react-router-dom";
import BASE_URL from '../../API.js';
import LoadingBar from "react-top-loading-bar";


export default function HospitalDetails() {
    var parameter1 = useParams();
    var state = parameter1.parameter1.replace(/-/g, ' ').trim();
    var city = parameter1.parameter2.replace(/-/g, ' ').trim();
    var lastIndex = parameter1.parameter3.lastIndexOf("-");
    var hopitalName = parameter1.parameter3.substring(0, lastIndex).replace(/-/g, ' ').trim();
    var id = parameter1.parameter3.split('-').at(-1).trim();

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    var newUrl = currentUrl.slice(0, -1);
    if (lastElement == "/") {
        window.history.pushState({}, null, newUrl);
    }

    const [hospitaldetail, sethospitaldetail] = useState([]);
    const [progress, setProgress] = useState(70);
    const [length, setlength] = useState(0);
    const [doctors, setdoctors] = useState([]);
    const [doctorserr, setdoctorserr] = useState(0);
    const [blogs, setblogs] = useState([]);
    const [blogserr, setblogserr] = useState(0);
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        axios.get(BASE_URL.URL1 + "/api/Home/GetHospitalDetails?UID=" + id)
            .then(response => {
                sethospitaldetail(response.data)
                setProgress(100)
                axios.get(BASE_URL.URL1 + "/api/Home/GetDoctorsByHospitals?state=" + state + "&city=" + city + "&hopitalname=" + hopitalName)
                    .then(response => {
                        if (response.data.length == 0 && doctors.length == 0) {
                            setdoctorserr(1)
                            setProgress(100)
                        } else if (response.data.length == 0 && doctors.length > 0) {
                            setlength(response.data.length)
                            setProgress(100)
                        } else {
                            setdoctors(response.data)
                            setlength(response.data.length)
                            setProgress(100)
                        }
                        axios.get(BASE_URL.URL1 + "/api/Blog/GetBlogsBySpeciality?speciality=''")
                            .then(response => setblogs(response.data))
                            .catch(err => {
                                //console.log(err);
                                setblogserr(1)
                            })
                    })
                    .catch(err => {
                        //console.log(err);
                        setProgress(100)
                    })
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
            })
    }, []);
    //console.log(hospitaldetail);
    //console.log(doctors);
    //console.log(blogs);

    return (
        <>
            <Helmet>
                <title>{hospitaldetail.length > 0 && hospitaldetail[0].hospitalName.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + " - " + hospitaldetail[0].city + ', ' + hospitaldetail[0].stateID + ' | DHPFinder'}</title>
                <meta name="description" content={hospitaldetail.length > 0 && hospitaldetail[0].hospitalName + ' in ' + hospitaldetail[0].city + ', ' + hospitaldetail[0].stateID + ' - Get directions, phone number, research doctors, and best emergency hospital for Shelby Baptist Medical Center on DHPFinder.'} />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            {
                hospitaldetail.length > 0 ?
                    <>
                        <div class="page-title-area" style={{ backgroundImage: 'url(../../../images/hospitals/mainbannerhospital.jpg)' }}>
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="page-title-item">
                                        <h3 className="text-white"><b>{hospitaldetail[0].hospitalName}</b></h3>
                                        <ul>
                                            <li><NavLink to="/best-doctors/">Home</NavLink></li>
                                            <li class="active">Hospital Details</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="doctor-details-area">
                            <div className="container">
                                <div className="row doctor-details-item pt-5 px-3">
                                    <div className="col-md-6 my-auto">
                                        <h3>{hospitaldetail[0].hospitalName}</h3>
                                        {hospitaldetail[0].rating != '' && <p><b>Rating: </b>{hospitaldetail[0].rating} Patients would definitely recommend</p>}
                                        <p><b>Biography: </b> {hospitaldetail[0].hospitalName} is a best medical facility located in {hospitaldetail[0].city}, {hospitaldetail[0].stateID}.</p>
                                    </div>
                                    <div className="col-md-2 my-auto text-center">
                                        <img src='../../../images/hospitals/hospital.png' className="" style={{ width: '70%' }} alt={hospitaldetail[0].doctorName} />
                                    </div>
                                    <div className="col-md-4 doctor-details-contact doctor-details-left pt-4">
                                        <h3 className="text-center">Hospital Contact info</h3>
                                        <ul>
                                            <li><i class="icofont-ui-call"></i>Call: <a href={'tel:' + hospitaldetail[0].phoneNumber1}>{hospitaldetail[0].phoneNumber}</a></li>
                                            <li><b><i className="fa fa-map-marker" style={{ color: 'red' }}></i></b>&nbsp;{hospitaldetail[0].address}</li>
                                            <h3 className="text-center"><a href={hospitaldetail[0].mapDirection} target="_blank">Direction</a></h3>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            hospitaldetail[0].hospitalQualities == null || hospitaldetail[0].hospitalQualities != "" &&
                            <div class="">
                                <div class="appointment-area-three">
                                    <div class="container-fluid p-0">
                                        <div class="row m-0" style={{ backgroundColor: 'ghostwhite' }}>
                                            <div class="col-lg-7">
                                                <div class="appointment-item mb-5" style={{ backgroundImage: 'none' }}>
                                                    <h3>Services</h3>
                                                    <br />
                                                    {
                                                        hospitaldetail[0].hospitalQualities.split(',').map((e =>
                                                            <>
                                                                {
                                                                    e.replaceAll('&quot;', ' ').replaceAll('&#x27;', '').replaceAll(' ', '') != '' &&
                                                                    <div class="appointment-form mb-1">
                                                                        {
                                                                            <div class="doctor-details-biography">
                                                                                <ul style={{ listStyleType: 'none' }} className="p-2">
                                                                                    {<li><b><i class="fa fa-circle" style={{ fontSize: '8px' }} aria-hidden="true"></i></b> {e.replaceAll('&quot;', ' ').replaceAll('&#x27;', '')}</li>}
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div class="pb-100">
                            <div class="appointment-area-three">
                                <div class="container-fluid p-0">
                                    <div class="row m-0 justify-content-center">
                                        <div class="col-12 col-md-7 my-auto">
                                            <div class="appointment-item appointment-item-two" style={{ maxWidth: '100%', marginRight: 'auto', padding: '30px 45px' }}>
                                                <div class="blog-details-form">
                                                    <div class="blog-details-form-wrap" style={{ maxWidth: '100%' }}>
                                                        <h2>Drop your comment</h2>
                                                        <form>
                                                            <div class="form-group"><textarea class="form-control" id="your_comments" rows="4"
                                                                placeholder="Your Comments"></textarea></div>
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="form-group"><input type="text" class="form-control"
                                                                        placeholder="Name" /></div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="form-group"><input type="email" class="form-control"
                                                                        placeholder="Email" /></div>
                                                                </div>
                                                            </div>
                                                            <div class="text-left"><button type="submit" class="btn blog-details-form-btn">Post
                                                                A Comment</button></div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            doctorserr != 1 &&
                            <div className="doctors-area doctors-area-two pt-100 pb-70">
                                <div className="container">
                                    <h3><u><b>Providers</b></u></h3>
                                    <br />
                                    <div className="row">
                                        <div className="col-md-7 col-12">
                                            {
                                                <div className="container">
                                                    {
                                                        doctors.length > 0 &&
                                                        doctors.map((d, i) => {
                                                            return (
                                                                <NavLink to={'/best-doctors/' + state.toLowerCase() + '/' + city.toLowerCase() + '/' + d.specialtyName.replace(/ /g, '-').toLowerCase().trim() + '/' + d.doctorName.replace(/,/g, '').replace('.', '').replace(/ /g, '-').toLowerCase().trim() + `-` + d.uid}>
                                                                    <div className="row">
                                                                        {/* <div className="col-md-6 col-lg-12"> */}
                                                                        <div className="card mb-3 doctor-item" style={{ width: '100%' }}>
                                                                            <div className="row no-gutters p-1">
                                                                                <div class="col-md-9 col-12" style={{ textAlign: 'left' }}>
                                                                                    <div className="d-flex" style={{ marginTop: 'auto' }}>
                                                                                        {
                                                                                            d.docImage != '' && d.docImage != null ?
                                                                                                <img src={d.docImage} className="my-auto" style={{ width: '60px', height: '60px', borderRadius: '50px' }} alt={d.doctorName} />
                                                                                                : <img src='../../../images/doctors/doctor.png' className="my-auto" style={{ width: '70px', height: '70px', borderRadius: '50px' }} alt={d.doctorName} />
                                                                                        }
                                                                                        <div className="my-auto" style={{ marginLeft: '25px' }}>
                                                                                            <h6 className="card-title mb-0 font-weight-bolder" style={{ fontFamily: 'sans-serif' }}><a><small>{d.doctorName}</small></a></h6>
                                                                                            <span className="text-dark"><p className="card-text mb-0 p-0"><small><b>{d.specialtyName}</b></small></p></span>
                                                                                            {d.rating != '' && <span className="text-dark"><p className="card-text mb-0 p-0"><small><b>Rating: </b></small><small>{d.rating.replace('ratings', '%')}</small></p></span>}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-3 col-12 p-2 my-auto">
                                                                                    <button className="btn btn-danger w-100">Profile</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* </div> */}
                                                                    </div>
                                                                </NavLink>
                                                            )
                                                        })
                                                        //: <h4 className="text-center">No Doctors Found</h4>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div class="doctor-details-area pb-70">
                            <div className="container">
                                <div className="row border pt-3">
                                    <div className="col-12">
                                        <img style={{ width: "100%" }} src={'../../../images/map.png'} alt={hospitaldetail[0].hospitalName} />
                                    </div>
                                    <div className="col-12 mt-3 border pt-3">
                                        <h3 className="font-weight-bold">Contact info</h3>
                                        <span><i class="icofont-ui-call"></i><b>Call: </b><a href={'tel:' + hospitaldetail[0].phoneNumber}>{hospitaldetail[0].phoneNumber}</a></span>
                                        <br />
                                        <span><b><i className="fa fa-map-marker" style={{ color: 'red' }}></i> </b> {hospitaldetail[0].address}</span>
                                        <p><a href={hospitaldetail[0].mapDirection} target="_blank" className="font-weight-bold">Direction</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div class="doctor-details-area pb-70">
                            <div className="container">
                                <h4 style={{ marginLeft: '-1%' }}><b><u>Frequently Asked Questions</u></b></h4>
                                <br />
                                <div className="row">
                                    <div className="col-md-8 border pt-3">
                                        <h6>Where is {hospitaldetail[0].hospitalName.replaceAll("&#x27;", "'")} located?</h6>
                                        <p>{hospitaldetail[0].hospitalName.replaceAll("&#x27;", "'")} is located at {hospitaldetail[0].address}</p>
                                    </div>
                                    <div className="col-md-8 border pt-3 mt-1">
                                        <h6>How would I get in at {hospitaldetail[0].hospitalName.replaceAll("&#x27;", "'")}?</h6>
                                        <p>Phone number, appointment information, and directions can be seen as on {hospitaldetail[0].hospitalName} profile..</p>
                                    </div>
                                    {
                                        hospitaldetail[0].rating != '' && hospitaldetail[0].rating != null &&
                                        <div className="col-md-8 border pt-3 mt-1">
                                            <h6>Is {hospitaldetail[0].hospitalName.replaceAll("&#x27;", "'")} rated with patients?</h6>
                                            <p>{hospitaldetail[0].rating} patients recommend this hospital.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="blog-area pt-100 pb-70 mb-md-0 mb-3">
                            {
                                blogs.length > 0 &&
                                <div className="container">
                                    <div className="section-title">
                                        <h2>Recommended Blogs</h2>
                                    </div>
                                    <div className="row justify-content-center">
                                        {
                                            blogs.map((b =>
                                                <div className="col-md-6 col-lg-2">
                                                    <NavLink to={'/blogs/' + b.blogHeading.trim().replace(/ /g, '-').toLowerCase()}>
                                                        <div className="blog-item">
                                                            <div className="blog-top">
                                                                <img src={'https://www.dhpfinder.com' + b.blogImage} alt={b.blogHeading} />
                                                            </div>
                                                            <div className="blog-bottom">
                                                                <h6 className="font-weight-bold">{b.blogHeading}</h6>
                                                                <p><small className="text-dark">{b.blogDescription}... Click here for more details.</small></p>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                    : <div className="text-center my-5"><h2><b>Loading...</b></h2></div>
            }

        </>
    )
}