import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useParams, useLocation } from "react-router-dom";
import BASE_URL from "../../API.js";
import LoadingBar from "react-top-loading-bar";

export default function GetDoctorSpecialityList() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    var parameter = useParams();
    const location = useLocation();
    var speciality = parameter.parameter.replace('best-doctors-for-', '').replace(/-/g, ' ').trim();

    const [progress, setProgress] = useState(70);
    // const [states, setstates] = useState([]);
    // useEffect(() => {
    //     window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    //     axios.get(BASE_URL.URL1 + "/api/Doctor/GetDoctorStates")
    //         .then(response => setstates(response.data))
    //         .catch(err => {
    //             //console.log(err);
    //         })
    // }, []);
    //console.log(states);

    useEffect(() => {
        setdoctors([])
        setdoctorserr(0)
    }, [location.key]);

    const [count, setcount] = useState(0);
    const [page, setpage] = useState(1);
    const [length, setlength] = useState(0);
    const [doctors, setdoctors] = useState([]);
    const [doctorserr, setdoctorserr] = useState(0);
    useEffect(() => {
        setProgress(50)
        axios.get(BASE_URL.URL1 + "/api/Doctor/GetDoctorsBySpecialties?specialty=" + speciality + "&pageNumber=" + page + "&pageSize=10")
            .then(response => {
                if (response.data.length == 0 && doctors.length == 0) {
                    setdoctorserr(1)
                    setProgress(100)
                } else if (response.data.length == 0 && doctors.length > 0) {
                    setlength(response.data.length)
                    setProgress(100)
                } else {
                    setdoctors(pre => [...pre, ...response.data])
                    setlength(response.data.length)
                    setProgress(100)
                    setcount(count + 1)
                }
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
            })
    }, [page, location.key]);
    //console.log(doctors);

    // useEffect(() => {
    //     if (doctors.length > 0) {
    //         if (length > 0) {
    //             setpage(page + 1)
    //         }
    //     }
    // }, [count]);

    return (
        <>
            <Helmet>
                <title>Best {parameter.parameter.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} near me | DHPFinder</title>
                <meta name="description" content={"DHPFinder can help you to find best " + parameter.parameter.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + " near you by weighing reviews and other important factor."} />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            {
                speciality == 'dentist' ?
                    <div className="page-title-area" style={{ backgroundImage: 'url(../../images/doctors/dentist.png)' }}>
                        <div className="d-table">
                            <div className="d-table-cell">
                                <div className="page-title-item">
                                    <h3 className="text-white" style={{ textTransform: 'capitalize' }}><b>Meet Your Qualified {parameter.parameter.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim()}</b></h3>
                                    <ul>
                                        <li><NavLink to="/best-doctors/">Home</NavLink></li>
                                        <li className="active text-capitalize">{parameter.parameter.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim()}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> : speciality == 'nephrologists' ?
                        <div className="page-title-area" style={{ backgroundImage: 'url(../../images/doctors/nephrologists.png)' }}>
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="page-title-item">
                                        <h3 className="text-white" style={{ textTransform: 'capitalize' }}><b>Meet Your Qualified {parameter.parameter.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim()}</b></h3>
                                        <ul>
                                            <li><NavLink to="/best-doctors/">Home</NavLink></li>
                                            <li className="active text-capitalize">{parameter.parameter.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim()}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> : speciality == 'neurologists' ?
                            <div className="page-title-area" style={{ backgroundImage: 'url(../../images/doctors/neurologists.png)' }}>
                                <div className="d-table">
                                    <div className="d-table-cell">
                                        <div className="page-title-item">
                                            <h3 className="text-white" style={{ textTransform: 'capitalize' }}><b>Meet Your Qualified {parameter.parameter.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim()}</b></h3>
                                            <ul>
                                                <li><NavLink to="/best-doctors/">Home</NavLink></li>
                                                <li className="active text-capitalize">{parameter.parameter.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim()}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> : speciality == 'psychiatrists' ?
                                <div className="page-title-area" style={{ backgroundImage: 'url(../../images/doctors/psychiatrists.png)' }}>
                                    <div className="d-table">
                                        <div className="d-table-cell">
                                            <div className="page-title-item">
                                                <h3 className="text-white" style={{ textTransform: 'capitalize' }}><b>Meet Your Qualified {parameter.parameter.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim()}</b></h3>
                                                <ul>
                                                    <li><NavLink to="/best-doctors/">Home</NavLink></li>
                                                    <li className="active text-capitalize">{parameter.parameter.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim()}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> : speciality == 'psychologists' ?
                                    <div className="page-title-area" style={{ backgroundImage: 'url(../../images/doctors/psychologists.png)' }}>
                                        <div className="d-table">
                                            <div className="d-table-cell">
                                                <div className="page-title-item">
                                                    <h3 className="text-white" style={{ textTransform: 'capitalize' }}><b>Meet Your Qualified {parameter.parameter.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim()}</b></h3>
                                                    <ul>
                                                        <li><NavLink to="/best-doctors/">Home</NavLink></li>
                                                        <li className="active text-capitalize">{parameter.parameter.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim()}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="page-title-area" style={{ backgroundImage: 'url(../../images/doctors/mainbannerdoctor.jpg)' }}>
                                        <div className="d-table">
                                            <div className="d-table-cell">
                                                <div className="page-title-item">
                                                    <h3 className="text-white" style={{ textTransform: 'capitalize' }}><b>Meet Your Qualified {parameter.parameter.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim()}</b></h3>
                                                    <ul>
                                                        <li><NavLink to="/best-doctors/">Home</NavLink></li>
                                                        <li className="active text-capitalize">{parameter.parameter.replace('best-doctors-for-', '').replace('and', '&').replace(/-/g, ' ').trim()}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            }
            <div className="doctors-area doctors-area-two pt-100 pb-70">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9 col-12">
                            {
                                <div className="container">
                                    {
                                        doctorserr != 1 ?
                                            doctors.length > 0 ?
                                                doctors.map((d, i) => {
                                                    return (
                                                        <NavLink to={`/specialities/` + parameter.parameter + `/` + d.doctorName.replace(/,/g, '').replace('.', '').replace(/ /g, '-').toLowerCase().trim() + `-` + d.uid}>
                                                            <div className="row">
                                                                {/* <div className="col-md-6 col-lg-12"> */}
                                                                <div className="card mb-3 doctor-item" style={{ width: '100%' }}>
                                                                    <div className="row no-gutters p-2">
                                                                        <div class="col-md-9 col-12" style={{ textAlign: 'left' }}>
                                                                            <div className="d-flex" style={{ marginTop: 'auto' }}>
                                                                                {
                                                                                    d.docImage != '' ?
                                                                                        //d.gender != 'Male' ?
                                                                                        <img src={d.docImage} className="my-auto" style={{ width: '80px', height: '80px', borderRadius: '50px' }} alt={d.doctorName} />
                                                                                        : <img src='../../../../images/doctors/doctor.png' className="my-auto" style={{ width: '70px', height: '70px', borderRadius: '50px' }} alt={d.doctorName} />
                                                                                }
                                                                                <div className="my-auto" style={{ marginLeft: '25px' }}>
                                                                                    <h6 className="card-title mb-0 font-weight-bolder" style={{ fontFamily: 'sans-serif' }}><a>{d.doctorName}</a></h6>
                                                                                    <span className="text-dark"><p className="card-text mb-0"><small><b>{d.specialtyName}</b></small></p></span>
                                                                                    <span className="text-dark"><p className="card-text mb-0"><b>Rating: </b><small>{d.rating.replace('ratings', '%')}</small></p></span>
                                                                                    <span className="text-dark"><p className="card-text mb-0"><b><i className="fa fa-map-marker" style={{ color: 'red' }}></i> </b><small>{d.address}</small></p></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3 col-12 p-2 my-auto">
                                                                            <button className="btn btn-danger w-100">Profile</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* </div> */}
                                                            </div>
                                                        </NavLink>
                                                    )
                                                })
                                                : <div className="text-center"><h2><b>Loading...</b></h2></div>
                                            : <>
                                                <h4 className="text-center">No Doctors Found</h4>
                                                <br/>
                                                <div className="col-12 text-center">
                                                    <NavLink to='/' className='btn btn-danger text-center'>Go To Home</NavLink>
                                                </div>
                                            </>
                                    }
                                    {
                                        doctors.length != 0 &&
                                        <div className="row mt-4">
                                            <button className={length == 10 ? "btn btn-outline-danger btn-block" : "btn-block d-none"} onClick={() => { setpage(page + 1) }}>Load More</button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </>
    )
}