import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import LoadingBar from "react-top-loading-bar";
import BASE_URL from "../../API";
import { NavLink, useParams } from "react-router-dom";

export default function HospitalCities() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    const parameter = useParams();
    const [progress, setProgress] = useState(70);
    const [cities, setcities] = useState([]);
    const [citieserr, setcitieserr] = useState(0);
    const [hospital, sethospital] = useState([]);
    const [hospitalerr, sethospitalerr] = useState(0);
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        axios.get(BASE_URL.URL1 + "/api/Home/GetHospitalCity?state=" + parameter.parameter.replace(/-/g, ' ').trim())
            .then(response => {
                if (response.data.length == 0) {
                    setcitieserr(1)
                    setProgress(100)
                } else {
                    setcities(response.data)
                    setProgress(100)
                }
                axios.get(BASE_URL.URL1 + "/api/Home/GetRandomHospitalsByState?state=" + parameter.parameter.replace(/-/g, ' ').trim())
                    .then(response => {
                        sethospital(response.data)
                        setProgress(100)
                    })
                    .catch(err => {
                        //console.log(err);
                        setProgress(100)
                        sethospitalerr(1)
                    })
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
            })
    }, []);
    //console.log(cities);

    return (
        <>
            <Helmet>
                <title>Best Hospitals in {parameter.parameter.replace(/-/g, ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase())} | DHPFinder</title>
                <meta name="description" content={"Find best Hospitals in " + parameter.parameter.replace(/-/g, ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + ", including hospital ratings, directions, addresses, phone numbers and affiliated physicians."} />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            <div class="page-title-area" style={{ backgroundImage: 'url(../../images/hospitals/mainbannerhospital.jpg)' }}>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="page-title-item">
                            <h3 className="text-white" style={{ textTransform: 'capitalize' }}><b>Find Hospitals in {parameter.parameter.replace(/-/g, ' ')}</b></h3>
                            <ul>
                                <li><NavLink to="/best-hospitals/">Home</NavLink></li>
                                <li class="active">Cities</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className="services-area">
                {
                    citieserr != 1 ?
                        cities.length > 0 ?
                            <div className="container">
                                <div className="section-title-two"><span>Cities</span>
                                    <h2 style={{ width: '100%' }}>Select By City</h2>
                                </div>
                                <div className="row border p-2">
                                    {
                                        cities.map((a =>
                                            <div className="col-md-3 col-6 col-lg-3 mb-2" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <NavLink to={'/best-hospitals/' + parameter.parameter + '/' + a.city.trim().replace(/ /g, '-').toLowerCase() + '/'}>
                                                    <span style={{ whiteSpace: 'nowrap', fontWeight: '600', fontFamily: 'sans-serif' }} className="">{a.city}</span>
                                                </NavLink>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            : <div className="text-center"><h2><b>Loading...</b></h2></div>
                        : <div className="text-center"><h2><b>Coming Soon</b></h2></div>
                }
            </div>
            <div class="urgent-care-area urgent-care-area-two pt-100 pb-70">
                <div class="container">
                    <div class="row">
                        <div className="col-md-9 col-12">
                            <div className="container">
                                {
                                    hospitalerr != 1 &&
                                    hospital.length > 0 &&
                                    hospital.map((d, i) => {
                                        return (
                                            <NavLink to={`/best-hospitals/` + d.state.replace(/ /g, '-').toLowerCase() + `/` + d.city.replace(/ /g, '-').toLowerCase() + `/` + d.hospitalName.replaceAll(/,/g, '').replaceAll('.', '').replaceAll('/', '-').replace(/ /g, '-').toLowerCase().trim() + `-` + d.uid} className="mb-3 w-100">
                                                <div className="row">
                                                    {/* <div class="col-md-6 col-lg-12"> */}
                                                    <div class="card mb-2 doctor-item" style={{ maxWidth: '100%' }}>
                                                        <div class="row no-gutters p-2">
                                                            <div class="col-md-9 col-12" style={{ textAlign: 'left' }}>
                                                                <div className="d-flex" style={{ marginTop: 'auto' }}>
                                                                    <img src='../../images/hospitals/hospital.png' style={{ width: '80px', height: '80px', borderRadius: '50px' }} alt={d.hospitalName} />
                                                                    <div className="my-auto" style={{ marginLeft: '25px' }}>
                                                                        <h6 class="card-title mb-0 font-weight-bolder" style={{ fontFamily: 'sans-serif' }}><a>{d.hospitalName}</a></h6>
                                                                        {d.rating != "" && <span className="text-dark"><p class="card-text mb-0"><b>Rating: </b><small>{d.rating}</small></p></span>}
                                                                        <span className="text-dark"><p class="card-text mb-0"><b><i className="fa fa-map-marker" style={{ color: 'red' }}></i> </b><small>{d.address}</small></p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-12 p-2 my-auto">
                                                                <button className="btn btn-danger w-100">Details</button>
                                                            </div>
                                                        </div>
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </NavLink>
                                        )
                                    })
                                    //: <h4 className="text-center">No Hospitals Found</h4>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </>
    )
}