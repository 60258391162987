import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar"
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import BASE_URL from "../../API";

export default function Blogs() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    // const smooothScroll = (e) => {
    //     e && e.preventDefault();
    //     const href = e.currentTarget.hash.replace(/#/g, '');
    //     const elementToView = document.getElementById(href);
    //     elementToView.scrollIntoView({ behavior: 'smooth' });
    // }

    const [progress, setProgress] = useState(70)
    const count = useRef(0);
    const [sections, setsections] = useState([]);
    const [blogs, setblogs] = useState([]);
    const [defaultsection, setdefaultsection] = useState('adhdcycle');

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        axios.get(BASE_URL.URL1 + "/api/Blog/GetBlogSections")
            .then(response => {
                setsections(response.data)
            }).catch(err => {
                //console.log(err);
            })
    }, []);
    //console.log(sections);

    useEffect(() => {
        setProgress(70)
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        count.current = sections.map(item => item.sectionHeading.replace('Blogs About ', '').replace(' ', '').toLowerCase()).indexOf(defaultsection);
        axios.get(BASE_URL.URL1 + "/api/Blog/GetBlogsBySection?sectionheading=" + defaultsection)
            .then(response => {
                setblogs(response.data)
                setProgress(100)
            }).catch(err => {
                //console.log(err);
                setProgress(100)
            })
    }, [defaultsection, sections]);
    //console.log(blogs);    

    function showPrevious() {
        if (count.current > 0) {
            count.current = count.current - 1;
            setdefaultsection(sections[count.current].sectionHeading.replace('Blogs About ', '').replace(' ', '').toLowerCase());
        }
    }

    function showNext() {
        if (count.current < sections.length - 1) {
            count.current = count.current + 1;
            setdefaultsection(sections[count.current].sectionHeading.replace('Blogs About ', '').replace(' ', '').toLowerCase());
        }
    }

    let previousHeading = null;
    return (
        <>
            <Helmet>
                <title>Blogs | DHPFinder</title>
                <meta name="description" content="DHPFinder Blogs | Blogs" />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            <div class="page-title-area" style={{ backgroundImage: 'url(../images/blog/mainblogbanner.jpg)' }}>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="page-title-item">
                            <h2>Blogs</h2>
                            <ul>
                                <li><NavLink to='/'>Home</NavLink></li>
                                <li class="active">Blogs</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog-area pt-5 pb-70">
                <div className="container-fluid">
                    <div className="link-container">
                        {
                            sections.length > 0 &&
                            sections.map((p =>
                                <a onClick={() => { setdefaultsection(p.sectionHeading.replace('Blogs About ', '').replace(' ', '').toLowerCase()) }} className={"text-dark border-bottom border-danger font-weight-bold border-3 " + (defaultsection == p.sectionHeading.replace('Blogs About ', '').replace(' ', '').toLowerCase() && ' bg-info ')}><h5>{p.sectionHeading.replace('Blogs About ', '')}</h5></a>
                            ))
                        }
                    </div>
                </div>
                <br />
                <div className="container">
                    {
                        blogs.length > 0 ?
                            <div className="section-title">
                                <h2>Our Blogs</h2>
                            </div>
                            : <div><h2 className="text-center mt-5 mb-3 my-auto"><b>Loading...</b></h2><br /><br /></div>
                    }
                    {
                        blogs.length > 0 &&
                        blogs.map((d, i) => {
                            const shouldPrint = d.sectionHeading !== previousHeading;
                            previousHeading = d.sectionHeading
                            return (
                                <>
                                    {
                                        shouldPrint ?
                                            d.sectionHeading == "Next" ? <><br /></> :
                                                <div id={d.sectionHeading.trim().replace('Blogs About ', '').replace(/ /g, '').toLowerCase()}>
                                                    <div className="row justify-content-center mt-5">
                                                        <div className="section-title">
                                                            <h4 className="font-weight-bold">{d.sectionHeading}</h4>
                                                        </div>
                                                        {
                                                            blogs.map((c, k) => {
                                                                return (
                                                                    <>
                                                                        {
                                                                            d.sectionHeading == c.sectionHeading &&
                                                                            c.blogHeading != '' &&
                                                                            <div className="col-md-3 col-lg-3">
                                                                                <NavLink to={'/blogs/' + c.blogHeading.trim().replace(/ /g, '-').toLowerCase()}>
                                                                                    <div className="blog-item">
                                                                                        <div className="blog-top">
                                                                                            <img src={'https://www.dhpfinder.com' + c.blogImage} alt={c.blogHeading} />
                                                                                        </div>
                                                                                        <div className="blog-bottom">
                                                                                            <h6 className="font-weight-bold">{c.blogHeading}</h6>
                                                                                            <label className="text-dark">{c.blogDescription}</label>
                                                                                            <ul>
                                                                                                <li></li>
                                                                                                <li>Read More <i className="icofont-long-arrow-right"></i></li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </NavLink>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            : ""
                                    }
                                </>
                            )
                        })
                    }
                </div>
                <div className="container">
                    <div className="row justify-content-around">
                        <div className="col-auto">
                            <button onClick={showPrevious} className="btn btn-danger" style={{ width: '100px' }}>Previous</button>
                        </div>
                        <div className="col-auto">
                            <button onClick={showNext} className="btn btn-danger" style={{ width: '100px' }}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}