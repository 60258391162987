import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, NavLink } from "react-router-dom";
import BASE_URL from '../../API.js';
import LoadingBar from "react-top-loading-bar";

export default function UrgentCareDetails() {
    var parameter1 = useParams();
    var id = parameter1.parameter3.split('-').at(-1).trim();
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    var newUrl = currentUrl.slice(0, -1);
    if (lastElement == "/") {
        window.history.pushState({}, null, newUrl);
    }

    const [urgentcaredetail, seturgentcaredetail] = useState([]);
    const [progress, setProgress] = useState(70);
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        axios.get(BASE_URL.URL1 + "/api/UrgentCare/GetUrgentCareDetails?UID=" + id)
            .then(response => {
                seturgentcaredetail(response.data)
                setProgress(100)
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
            })
    }, []);
    //console.log(urgentcaredetail);

    return (
        <>
            <Helmet>
                <title>{urgentcaredetail.length > 0 && urgentcaredetail[0].urgentCare + " - " + urgentcaredetail[0].city + ', ' + urgentcaredetail[0].stateID + ' | DHPFinder'}</title>
                <meta name="description" content={urgentcaredetail.length > 0 && urgentcaredetail[0].urgentCare + ', a best urgent care clinics centers in ' + urgentcaredetail[0].city + ', ' + urgentcaredetail[0].stateID + '. Get directions, addresses, phone numbers, office hours. Call for wait times and more.'} />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            {
                urgentcaredetail.length > 0 &&
                <>
                    <div class="page-title-area" style={{ backgroundImage: 'url(../../../images/urgentcare/18.png)' }}>
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="page-title-item">
                                    <h3 className="text-white"><b>{urgentcaredetail[0].urgentCare}</b></h3>
                                    <ul>
                                        <li><NavLink to="/best-urgent-care/">Home</NavLink></li>
                                        <li class="active">UrgentCare Details</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="doctor-details-area pt-100 pb-70">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-5">
                                    {
                                        urgentcaredetail[0].monday != "" || urgentcaredetail[0].tuesday != "" ||
                                            urgentcaredetail[0].wednesday != "" || urgentcaredetail[0].thursday != "" ||
                                            urgentcaredetail[0].friday != "" || urgentcaredetail[0].saturday != "" ||
                                            urgentcaredetail[0].sunday != "" ?
                                            <div class="doctor-details-work">
                                                <h3>Office Hours</h3>
                                                <div class="appointment-item-two-right">
                                                    <div class="appointment-item-content" style={{ textAlign: 'initial' }}>
                                                        <ul>
                                                            <li>Monday <span>{urgentcaredetail[0].monday}</span></li>
                                                            <li>Tuesday <span>{urgentcaredetail[0].tuesday}</span></li>
                                                            <li>Wednesday <span>{urgentcaredetail[0].wednesday}</span></li>
                                                            <li>Thusday <span>{urgentcaredetail[0].thursday}</span></li>
                                                            <li>Friday <span>{urgentcaredetail[0].friday}</span></li>
                                                            <li>Saturday <span>{urgentcaredetail[0].saturday}</span></li>
                                                            <li>Sunday <span>{urgentcaredetail[0].sunday}</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="doctor-details-area pb-70">
                        <div className="container">
                            <div className="row border pt-3">
                                <div className="col-12">
                                    <img style={{ width: "100%" }} src={'../../../images/map.png'} alt={urgentcaredetail[0].urgentCare} />
                                </div>
                                <div className="col-12 mt-3 border pt-3">
                                    <h3 className="font-weight-bold">Contact info</h3>
                                    <span><b>Call: </b><a href={'tel:' + urgentcaredetail[0].phoneNumber}>{urgentcaredetail[0].phoneNumber}</a></span>
                                    <br />
                                    <span><b><i className="fa fa-map-marker" style={{ color: 'red' }}></i> </b> {urgentcaredetail[0].address}</span>
                                    <p><a href={urgentcaredetail[0].mapDirection} target="_blank" className="font-weight-bold">Direction</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="doctor-details-area pb-70">
                        <div className="container">
                            <h4 style={{ marginLeft: '-1%' }}><b><u>Frequently Asked Questions</u></b></h4>
                            <br />
                            <div className="row">
                                <div className="col-md-8 border pt-3">
                                    <h6>Where is {urgentcaredetail[0].urgentCare.replaceAll("&#x27;", "'")} located?</h6>
                                    <p>{urgentcaredetail[0].urgentCare.replaceAll("&#x27;", "'")} is located at {urgentcaredetail[0].address}</p>
                                </div>
                                <div className="col-md-8 border pt-3 mt-1">
                                    <h6>Is {urgentcaredetail[0].urgentCare.replaceAll("&#x27;", "'")} has Open today?</h6>
                                    <p>Please contact us on this number: <a href={'tel:' + urgentcaredetail[0].phoneNumber}>{urgentcaredetail[0].phoneNumber}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}