import React from 'react'
import { Helmet } from "react-helmet";
import { useEffect } from 'react';
import axios from "axios";
import LoadingBar from "react-top-loading-bar"
import { NavLink } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from "../API";

export default function WriteforUs() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    const vname = (event) => {
        var name = document.getElementById("name").value;
        document.getElementById("name").style.textTransform = "capitalize";
        var email = document.getElementById("email").value;
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        var message = document.getElementById("message").value;
        if (name == "" || name == null || name == undefined
            || email == "" || email == null || email == undefined
            || message == "" || message == null || message == undefined) {
            document.getElementById("sendMessage").style.pointerEvents = "none";
            document.getElementById("sendMessage").style.color = "#ccc";
        } else if (!filter.test(email)) {
            document.getElementById("sendMessage").style.pointerEvents = "none";
            document.getElementById("sendMessage").style.color = "#ccc";
        } else {
            document.getElementById("sendMessage").style.pointerEvents = "";
            document.getElementById("sendMessage").style.color = "";
        }
    }

    const vemail = (event) => {
        var name = document.getElementById("name").value;
        var email = document.getElementById("email").value;
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        var message = document.getElementById("message").value;
        if (name == "" || name == null || name == undefined
            || email == "" || email == null || email == undefined
            || message == "" || message == null || message == undefined) {
            document.getElementById("sendMessage").style.pointerEvents = "none";
            document.getElementById("sendMessage").style.color = "#ccc";
        } else if (!filter.test(email)) {
            document.getElementById("sendMessage").style.pointerEvents = "none";
            document.getElementById("sendMessage").style.color = "#ccc";
        } else {
            document.getElementById("sendMessage").style.pointerEvents = "";
            document.getElementById("sendMessage").style.color = "";
        }
    }

    const vmessage = (event) => {
        var name = document.getElementById("name").value;
        var email = document.getElementById("email").value;
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        var message = document.getElementById("message").value;
        document.getElementById("message").style.textTransform = "capitalize";
        if (name == "" || name == null || name == undefined
            || email == "" || email == null || email == undefined
            || message == "" || message == null || message == undefined) {
            document.getElementById("sendMessage").style.pointerEvents = "none";
            document.getElementById("sendMessage").style.color = "#ccc";
        } else if (!filter.test(email)) {
            document.getElementById("sendMessage").style.pointerEvents = "none";
            document.getElementById("sendMessage").style.color = "#ccc";
        } else {
            document.getElementById("sendMessage").style.pointerEvents = "";
            document.getElementById("sendMessage").style.color = "";
        }
    }

    const progressmsg = () => toast("Sending...");
    const sendMessage = (e) => {
        var name = document.getElementById("name").value;
        var email = document.getElementById("email").value;
        var message = document.getElementById("message").value;

        if (name == "" || email == "" || message == "") {
            return;
        }

        var obj = {
            Name: name,
            Email: email,
            Message: message
        }

        progressmsg()
        let json = JSON.stringify(obj);
        fetch(BASE_URL.URL1 + '/api/Home/InsertWriteForUsForm', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: json
        }).then(response => {
            //console.log(response.ok);
            if (response.ok == true) {
                toast("Send Successfull!");
                window.location.reload()
            } else {
                toast('Failed! ' + response.status);
            }
        }).catch(error => {
            toast(error);
            return;
        });
    }

    return (
        <>
            <Helmet>
                <title>Write for US | Guest Post | Health Medical | DHPFinder</title>
                <meta name="description" content="Join Our Community of Health Industry Voices. We’re always looking for new authors. If you’ve got an idea our readers want to hear about it. Just aim to bring readers a fresh perspective on a topic" />
            </Helmet>
            <LoadingBar color='blue' progress={100} height={5} />
            <div class="container-fluid p-0">
                <img src='../images/writeforus/1.png' />
            </div>
            <br />
            <br />
            <br />
            <div className='container'>
                <div className='row'>
                    <h1 className='text-center font-weight-bold'>Join Our Community of Health Industry Voices</h1>
                    <div className=''>
                        <p className='text-justify'>Welcome to <strong><a>Write-For-Us</a></strong> — your essential partner in boosting your medical industry presence online through targeted guest posting. As a distinguished leader in the field, we excel in creating and distributing content that not only engages your specific audience but also strengthens your standing in the dynamic medical sector. Our expertise has reliably achieved outstanding outcomes for our clientele, securing us 5-Star evaluations across various platforms. With <strong><a>Write-For-Us</a></strong>, your healthcare business or practice benefits from our verified history of success, ensuring your message reaches the right audience with maximum impact.</p>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6 my-auto'>
                        <h3 className='font-weight-bold'>Why Write for Us?</h3>
                        <ul>
                            <li>Visibility: Gain exposure to a broad audience in the health industry.</li>
                            <li>Authority: Establish yourself as a thought leader by sharing your insights and expertise.</li>
                            <li>Networking: Join a community of like-minded professionals and enthusiasts in the health sector.</li>
                            <li>Backlinks: Enhance your online presence with backlinks from our reputable site, increasing your visibility and search engine ranking.</li>
                        </ul>
                        <br />
                        <h3 className='font-weight-bold'>Sponsor Posts</h3>
                        <p className='text-justify'>We are open to publishing sponsor posts on our platform. If you have a compelling, impactful, and useful article that aligns with the interests of our audience, we'd love to hear from you.  Sponsored content is a fantastic way to gain visibility and engage with our community. Reach out to us here with your ideas or articles, and let's make a difference together.</p>
                    </div>
                    <div className='col-md-6 mt-md-0 mt-2'>
                        <img src='../images/writeforus/Write For Us.png' />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <div className='py-5' style={{ backgroundColor: '#0dcaf08c' }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 mb-md-0 mb-2'>
                            <img src='../images/writeforus/Backlinks.png' />
                        </div>
                        <div className='col-md-6 my-auto'>
                            <h3 className='font-weight-bold'>Who Can Write For Us?</h3>
                            <p className='text-justify'>We welcome contributions from a wide range of professionals within the health sector, including but not limited to:</p>
                            <ul>
                                <li>Medical Experts: We're looking for deep dives into medical research, trends, and analysis from those at the forefront of their field.</li>
                                <li>Medical Practitioners: Doctors, nurses, and other healthcare providers can offer invaluable perspectives on patient care, healthcare policy, and the medical industry.</li>
                                <li>Lab Technicians: Share your insights on the latest laboratory practices, innovations, and challenges in the medical field.</li>
                                <li>Health Fitness Professionals: Inspire our readers with the latest in fitness trends, health tips, and wellness advice.</li>
                            </ul>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6 my-auto'>
                        <h3 className='font-weight-bold'>Submission Guidelines</h3>
                        <ul>
                            <li>Word Count: Submissions should be between 1,000 to 1,500 words. We value quality and depth, ensuring your arguments are thoroughly explored.</li>
                            <li>Citing Sources: Strengthen your arguments by linking to reputable outside resources. All references must be hyperlinked directly in your article, allowing our readers to delve deeper into your points and validating your arguments.</li>
                            <li>Content Style: We’re seeking argumentative pieces that not only inform but also reflect your unique voice and perspective. While we appreciate the value of informational content, our platform thrives on debate and discussion.</li>
                        </ul>
                        <br />
                    </div>
                    <div className='col-md-6 mt-md-0 mt-2'>
                        <img src='../images/writeforus/Write For Us (1).png' />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="pb-100">
                <div class="appointment-area-three">
                    <div class="container-fluid p-0">
                        <div class="row m-0 justify-content-center">
                            <div class="col-12 col-md-7 my-auto">
                                <div class="appointment-item appointment-item-two" style={{ maxWidth: '100%', marginRight: 'auto', padding: '30px 45px' }}>
                                    <div class="blog-details-form">
                                        <div class="blog-details-form-wrap text-center" style={{ maxWidth: '100%' }}>
                                            <h1 className='font-weight-bolder'>Contact US</h1>
                                            <br />
                                            <form>
                                                <div class="form-group">
                                                    <textarea class="form-control" id="message" rows="4" placeholder="Your Message"></textarea>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <input type="text" onKeyUp={(e) => vname(e)} class="form-control" id='name' placeholder="Name" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <input type="email" onKeyUp={(e) => vemail(e)} class="form-control" id='email' placeholder="Email" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-right">
                                                    <button type="button" onClick={sendMessage} class="btn blog-details-form-btn" style={{ pointerEvents: 'none', color: '#ccc' }} id='sendMessage'>Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
