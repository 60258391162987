import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useParams, useLocation } from "react-router-dom";
import BASE_URL from "../../API.js";
import LoadingBar from "react-top-loading-bar";

export default function Pharmacy() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    var parameter1 = useParams();
    const { pathname } = useLocation();
    var state = parameter1.parameter1.replace('best-pharmacies-in-', '').replace(/-/g, ' ').trim();
    var city = parameter1.parameter2.replace('best-pharmacies-in-', '').replace(/-/g, ' ').trim();

    const [progress, setProgress] = useState(70);
    // const [states, setstates] = useState([]);
    // useEffect(() => {
    //     window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    //     axios.get(BASE_URL.URL1 + "/api/Home/GetPharmacyStates")
    //         .then(response => setstates(response.data))
    //         .catch(err => {
    //             //console.log(err);
    //         })
    // }, []);

    const [pharmacy, setpharmacy] = useState([]);
    const [pharmacyerr, setpharmacyerr] = useState(0);
    useEffect(() => {
        axios.get(BASE_URL.URL1 + "/api/Home/GetPharmacy?state=" + state + "&city=" + city)
            .then(response => {
                setpharmacy(response.data)
                setProgress(100)
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
                setpharmacyerr(1)
            })
    }, [pathname]);
    //console.log(pharmacy);

    return (
        <>
            <Helmet>
                <title>Best Pharmacies near {parameter1.parameter2.replace(/-/g, ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase())}, {state.replace(/(^\w|\s\w)/g, m => m.toUpperCase())} | DHPFinder</title>
                <meta name="description" content={"Best best Pharmacies near " + parameter1.parameter2.replace(/-/g, ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + ', ' + state.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + '. Get directions, addresses, phone numbers, office hours and more.'} />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            <div class="page-title-area" style={{ backgroundImage: 'url(../../../images/pharmacy/mainbannerpharmacy.jpg)' }}>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="page-title-item">
                            <h3 className="text-white" style={{ textTransform: 'capitalize' }}><b>Pharmacies in {parameter1.parameter2.replace(/-/g, ' ').trim()}</b></h3>
                            <ul>
                                <li><NavLink to="/best-pharmacies/">Home</NavLink></li>
                                <li class="active text-capitalize">{parameter1.parameter2.replace(/-/g, ' ').trim()}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="urgent-care-area urgent-care-area-two pt-100 pb-70">
                <div class="container">
                    <div class="row justify-content-center">
                        <div className="col-md-9 col-12">
                            <div className="container">
                                {
                                    pharmacyerr != 1 ?
                                        pharmacy.length > 0 &&
                                        pharmacy.map((d, i) => {
                                            return (
                                                <NavLink to={`/best-pharmacies/` + state.replace(/ /g, '-') + `/` + city.replace(/ /g, '-') + `/` + d.pharmacyName.replaceAll(/,/g, '').replaceAll('.', '').replaceAll('#', '').replace(/ /g, '-').toLowerCase().trim() + `-` + d.uid} className="mb-3 w-100">
                                                    <div className="row">
                                                        {/* <div class="col-md-6 col-lg-12"> */}
                                                        <div class="card mb-3 doctor-item" style={{ maxWidth: '100%' }}>
                                                            <div class="row no-gutters p-2">
                                                                <div class="col-md-9 col-12" style={{ textAlign: 'left' }}>
                                                                    <div className="d-flex" style={{ marginTop: 'auto' }}>
                                                                        <img src={'../../../images/pharmacy/pharmacy.png'} style={{ width: '80px', height: '80px', borderRadius: '50px' }} alt={d.pharmacyName} />
                                                                        <div className="my-auto" style={{ marginLeft: '25px' }}>
                                                                            <h6 class="card-title mb-0 font-weight-bolder" style={{ fontFamily: 'sans-serif' }}><a>{d.pharmacyName}</a></h6>
                                                                            <span className="text-dark"><p class="card-text mb-0"><b><i className="fa fa-map-marker" style={{ color: 'red' }}></i> </b><small>{d.address}</small></p></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3 col-12 p-2 my-auto">
                                                                    <button className="btn btn-danger w-100">Details</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* </div> */}
                                                    </div>
                                                </NavLink>
                                            )
                                        })
                                        : <>
                                            <h4 className="text-center">No Pharmacy Found</h4>
                                            <br />
                                            <div className="col-12 text-center">
                                                <NavLink to='/' className='btn btn-danger text-center'>Go To Home</NavLink>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}