import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import LoadingBar from "react-top-loading-bar"
import { NavLink } from "react-router-dom";
import BASE_URL from "../../API";

export default function Index() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }
    
    const [progress, setProgress] = useState(70);
    const [states, setstates] = useState([]);
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        axios.get(BASE_URL.URL1 + "/api/UrgentCare/GetUrgentCareStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                //console.log(err);
            })
    }, []);
    //console.log(states);

    return (
        <>
            <Helmet>
                <title>Best Urgent Care Clinics Near Me | Walk in Clinics Centers in USA | DHPFinder</title>
                <meta name="description" content="Find best Urgent Care Clinics, get directions, addresses, phone numbers, affiliated physicians, and more." />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            <div class="page-title-area" style={{ backgroundImage: 'url(../images/urgentcare/15.png)' }}>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="page-title-item">
                            <h2>Urgent Care</h2>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li class="active">Urgent Care</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="doctor-search-area">
                <div class="container">
                    <form>
                        <div class="row doctor-search-wrap">
                            <div class="col-sm-6 col-lg-6">
                                <div class="doctor-search-item">
                                    <div class="form-group"><i class="icofont-hospital"></i><label>Search</label><input
                                        type="text" class="form-control" placeholder="Urgent Care Name" /></div><button
                                            type="submit" class="btn doctor-search-btn"><i
                                                class="icofont-search-1"></i></button>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="doctor-search-item">
                                    <div class="form-group"><i class="icofont-doctor-alt"></i><label>Search</label><input
                                        type="text" class="form-control" placeholder="City Name" /></div><button
                                            type="submit" class="btn doctor-search-btn"><i
                                                class="icofont-search-1"></i></button>
                                </div>
                            </div>
                            {/* <div class="col-sm-6 col-lg-6">
                                <div class="doctor-search-item">
                                    <div class="form-group"><i class="icofont-hospital"></i><label>Category</label><select
                                        class="form-control">
                                        <option>Neurosurgeon</option>
                                        <option>Cardiology</option>
                                        <option>Pathology</option>
                                        <option>Dental Care</option>
                                    </select></div>
                                </div>
                            </div> */}
                        </div>
                    </form>
                </div>
            </div>
            <div className="services-area my-4">
                {
                    states.length > 0 ?
                    <div className="container">
                        <div className="section-title-two"><span>States</span>
                            <h2 style={{ width: '100%' }}>Select By State</h2>
                        </div>
                        <div className="row justify-content-center" id="states">
                            {
                                states.map((a =>
                                    <div className="col-md-4 col-6 col-lg-4">
                                        <NavLink to={'/best-urgent-care/' + a.state.trim().replace(/ /g, '-').toLowerCase() + '/'}>
                                            <div className="container">
                                                <div className="row service-item rounded-4" style={{ padding: '5px', border: '1px solid blue' }}>
                                                    <div className="col-md-3">
                                                        <img src={a.flag} style={{ width: '50px', height: '50px', borderRadius: '50px' }} />
                                                    </div>
                                                    <div className="col-md-9" style={{ textAlign: 'left' }}>
                                                        <h5>{a.state}</h5>
                                                        <small>Best UrgentCare in {a.state.replace('District of Columbia', 'D.C.')}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    : <div className="text-center"><h2><b>Loading...</b></h2></div>
                }
            </div>
            <br/>
            <br/>
            <br/>
        </>
    )
}