import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import LoadingBar from "react-top-loading-bar";
import BASE_URL from "../../API";
import { NavLink } from "react-router-dom";

export default function GetSpecialities() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }
    
    const [progress, setProgress] = useState(70);
    const [specialities, setspecialities] = useState([]);
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        axios.get(BASE_URL.URL1 + "/api/Doctor/GetDocSpecialties")
            .then(response => {
                setspecialities(response.data)
                setProgress(100)
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
            })
    }, []);
    //console.log(specialities);

    return (
        <>
            <Helmet>
                <title>Best Qualified Doctors near me | DHPFinder</title>
                <meta name="description" content="Search and analyze doctors by Specialty. Use DHPFinder to analyze providers by ratings, search office areas, and timetable an arrangement." />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            <div className="page-title-area" style={{ backgroundImage: 'url(../../images/doctors/mainbannerdoctor.jpg)' }}>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="page-title-item">
                            <h3 className="text-white"><b>All Specialities</b></h3>
                            <ul>
                                <li><NavLink to="/best-doctors/">Home</NavLink></li>
                                <li class="active">Specialities</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="doctor-search-area">
                <div class="container">
                    <form>
                        <div class="row doctor-search-wrap">
                            <div class="col-sm-6 col-lg-6">
                                <div class="doctor-search-item">
                                    <div class="form-group"><i class="icofont-doctor-alt"></i><label>Search</label><input
                                        type="text" class="form-control" placeholder="Doctor Name" /></div><button
                                            type="submit" class="btn doctor-search-btn"><i
                                                class="icofont-search-1"></i></button>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="doctor-search-item">
                                    <div class="form-group"><i class="icofont-doctor-alt"></i><label>Search</label><input
                                        type="text" class="form-control" placeholder="City Name" /></div><button
                                            type="submit" class="btn doctor-search-btn"><i
                                                class="icofont-search-1"></i></button>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="doctor-search-item">
                                    <div class="form-group"><i class="icofont-hospital"></i><label>Category</label><select
                                        class="form-control">
                                        <option>Neurosurgeon</option>
                                        <option>Cardiology</option>
                                        <option>Pathology</option>
                                        <option>Dental Care</option>
                                    </select></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div> */}
            <br />
            <div className="services-area">
                {
                    specialities.length > 0 &&
                    <div className="container">
                        <div className="section-title-two"><span>Specialities</span>
                            <h2 style={{ width: '100%' }}>Select Specialities</h2>
                        </div>
                        <div className="row">
                            {
                                specialities.map((a =>
                                    <div className="col-md-4 col-12 col-lg-4 mb-2" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <NavLink to={'/specialities/best-doctors-for-' + a.specialtyName.trim().replace(/ /g, '-').toLowerCase() + '/'}>
                                            <span style={{ whiteSpace: 'nowrap', fontWeight: '600' }} className="">{a.specialtyName}</span>
                                        </NavLink>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
            </div>
            <br />
            <br />
        </>
    )
}