import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import LoadingBar from "react-top-loading-bar"
import BASE_URL from "../../API";
import { NavLink } from "react-router-dom";

export default function Index() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }
    
    const [progress, setProgress] = useState(70)
    const [states, setstates] = useState([]);
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        axios.get(BASE_URL.URL1 + "/api/Home/GetHospitalStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
            })
    }, []);
    //console.log(states);

    return (
        <>
            <LoadingBar color='blue' progress={progress} height={5} />
            <Helmet>
                <title>Best Hospitals in USA | DHPFinder</title>
                <meta name="description" content="Find best Hospitals including hospital ratings, directions, addresses, phone numbers, affiliated physicians." />
            </Helmet>
            <div class="page-title-area" style={{ backgroundImage: 'url(../images/hospitals/mainbannerhospital.jpg)' }}>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="page-title-item">
                            <h2>Find Best Hospitals</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="doctor-search-area">
                <div class="container">
                    <form>
                        <div class="row doctor-search-wrap">
                            <div class="col-sm-6 col-lg-6">
                                <div class="doctor-search-item">
                                    <div class="form-group"><i class="icofont-hospital"></i><label>Search</label><input
                                        type="text" class="form-control" placeholder="Hospital Name" /></div><button
                                            type="submit" class="btn doctor-search-btn"><i
                                                class="icofont-search-1"></i></button>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="doctor-search-item">
                                    <div class="form-group"><i class="icofont-doctor-alt"></i><label>Search</label><input
                                        type="text" class="form-control" placeholder="City Name" /></div><button
                                            type="submit" class="btn doctor-search-btn"><i
                                                class="icofont-search-1"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="services-area mt-5">
                <div className="container">
                    <div className="section-title-two" style={{ maxWidth: '100%' }}><span>Services</span>
                        <h2>Award Recipient Hospitals</h2>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-6 col-lg-2">
                            <div className="service-item bg rounded-4">
                                <div className="service-front"><img src="../images/hospitals/pulmonary.png" style={{ width: '55%', marginBottom: '10px' }} />
                                    <NavLink>
                                        <h3 style={{fontSize: '14px'}}>Pulmonary</h3>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-2">
                            <div className="service-item bg rounded-4">
                                <div className="service-front"><img src="../images/hospitals/labor & delivery.png" style={{ width: '55%', marginBottom: '10px' }} />
                                    <NavLink>
                                        <h3 style={{fontSize: '14px'}}>Labor & Delivery</h3>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-2">
                            <div className="service-item bg rounded-4">
                                <div className="service-front"><img src="../images/hospitals/stroke.png" style={{ width: '55%', marginBottom: '10px' }} />
                                    <NavLink>
                                        <h3 style={{fontSize: '14px'}}>Stroke</h3>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-2">
                            <div className="service-item bg rounded-4">
                                <div className="service-front"><img src="../images/hospitals/coronary-intervention.png" style={{ width: '55%', marginBottom: '10px' }} />
                                    <NavLink>
                                        <h3 style={{fontSize: '12px'}}>Coronary Intervention</h3>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-2">
                            <div className="service-item bg rounded-4">
                                <div className="service-front"><img src="../images/hospitals/critical care.png" style={{ width: '55%', marginBottom: '10px' }} />
                                    <NavLink>
                                        <h3 style={{fontSize: '14px'}}>Critical Care</h3>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="departments-area pt-100 pb-70">
                <div class="container">
                    <div className="section-title-two" style={{ maxWidth: '100%' }}><span>Services</span>
                        <h2>Awards & Ratings Hospitals</h2>
                    </div>
                    <div class="row justify-content-center" id="hosp_sec">
                        <div class="col-sm-6 col-lg-4">
                            <NavLink>
                                <div class="department-item"><img src="../images/hospitals/america best hospital.png" style={{ width: '15%', marginBottom: '10px' }} />
                                    <h3>America's Best Hospitals</h3>
                                    <p>Address top-level clinical organizations, conveying excellent medical care and patient-focused benefits from one side of the country to the other.</p>
                                </div>
                            </NavLink>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                            <NavLink>
                                <div class="department-item"><img src="../images/hospitals/specialty excellence.png" style={{ width: '15%', marginBottom: '10px' }} />
                                    <h3>Specialty Excellence</h3>
                                    <p>Implies extraordinary capability and administration in a particular field, exhibiting unrivaled mastery and accomplishment.</p>
                                </div>
                            </NavLink>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                            <NavLink>
                                <div class="department-item"><img src="../images/hospitals/patient safety excellence.png" style={{ width: '15%', marginBottom: '10px' }} />
                                    <h3>Patient Safety Excellence</h3>
                                    <p>Guarantees a protected medical services climate, focusing on and carrying out measures for patient prosperity and fulfillment.</p>
                                </div>
                            </NavLink>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                            <NavLink>
                                <div class="department-item"><img src="../images/hospitals/patient experience.png" style={{ width: '15%', marginBottom: '10px' }} />
                                    <h3>Patient Experience</h3>
                                    <p>Embodies unrivaled consideration, compassion, and fulfillment, encouraging trust and positive medical care communications for all.</p>
                                </div>
                            </NavLink>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                            <NavLink>
                                <div class="department-item"><img src="../images/hospitals/women care.png" style={{ width: '15%', marginBottom: '10px' }} />
                                    <h3>Women's Care</h3>
                                    <p>Centers around wellbeing administrations, focusing on ladies' remarkable requirements, health, and strengthening with greatness.</p>
                                </div>
                            </NavLink>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                            <NavLink>
                                <div class="department-item"><img src="../images/hospitals/hospital quality award.png" style={{ width: '15%', marginBottom: '10px' }} />
                                    <h3>Hospital Quality Awards</h3>
                                    <p>Perceive medical care foundations for greatness in tolerant results, security, and outstanding clinical benefits.</p>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-area">
                {
                    states.length > 0 ?
                    <div className="container">
                        <div className="section-title-two"><span>States</span>
                            <h2 style={{ width: '100%' }}>Select By States</h2>
                        </div>
                        <div className="row justify-content-center" id="states">
                            {
                                states.map((a =>
                                    <div className="col-md-4 col-6 col-lg-4">
                                        <NavLink to={'/best-hospitals/' + a.state.trim().replace(/ /g, '-').toLowerCase() + '/'}>
                                            <div className="container">
                                                <div className="row service-item rounded-4" style={{ padding: '5px', border: '1px solid blue' }}>
                                                    <div className="col-md-3">
                                                        <img src={a.flag} style={{ width: '50px', height: '50px', borderRadius: '50px' }} />
                                                    </div>
                                                    <div className="col-md-9" style={{ textAlign: 'left' }}>
                                                        <h5>{a.state}</h5>
                                                        <small>Best Hospitals in {a.state.replace('District of Columbia', 'D.C.')}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    : <div className="text-center"><h2><b>Loading...</b></h2></div>
                }
            </div>
            <br/>
            <br/>
            <br/>
        </>
    )
}