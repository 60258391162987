import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, NavLink } from "react-router-dom";
import BASE_URL from '../../API.js';
import LoadingBar from "react-top-loading-bar";

export default function PharmacyDetails() {
    var parameter1 = useParams();
    var id = parameter1.parameter3.split('-').at(-1).trim();
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    var newUrl = currentUrl.slice(0, -1);
    if (lastElement == "/") {
        window.history.pushState({}, null, newUrl);
    }

    const [pharmacydetail, setpharmacydetail] = useState([]);
    const [progress, setProgress] = useState(70);
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        axios.get(BASE_URL.URL1 + "/api/Home/GetPharmacyDetails?UID=" + id)
            .then(response => {
                setpharmacydetail(response.data)
                setProgress(100)
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
            })
    }, []);
    //console.log(pharmacydetail);

    return (
        <>
            <Helmet>
                <title>{pharmacydetail.length > 0 && pharmacydetail[0].pharmacyName.replaceAll("&#x27;","'") + " - " + pharmacydetail[0].city + ', ' + pharmacydetail[0].stateID + ' | DHPFinder'}</title>
                <meta name="description" content={pharmacydetail.length > 0 && pharmacydetail[0].pharmacyName.replaceAll("&#x27;","'")} />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            {
                pharmacydetail.length > 0 &&
                <>
                    <div class="page-title-area" style={{ backgroundImage: 'url(../../../images/pharmacy/mainbannerpharmacy.jpg)' }}>
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="page-title-item">
                                    <h3 className="text-white"><b>{pharmacydetail[0].pharmacyName.replaceAll("&#x27;","'")}</b></h3>
                                    <ul>
                                        <li><NavLink to="/best-pharmacies/">Home</NavLink></li>
                                        <li class="active">Pharmacy Details</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="doctor-details-area pt-100 pb-70">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-5">
                                    {
                                        pharmacydetail[0].monday != "" || pharmacydetail[0].tuesday != "" ||
                                            pharmacydetail[0].wednesday != "" || pharmacydetail[0].thursday != "" ||
                                            pharmacydetail[0].friday != "" || pharmacydetail[0].saturday != "" ||
                                            pharmacydetail[0].sunday != "" ?
                                            <div class="doctor-details-work">
                                                <h3>Office Hours</h3>
                                                <div class="appointment-item-two-right">
                                                    <div class="appointment-item-content" style={{ textAlign: 'initial' }}>
                                                        <ul>
                                                            <li>Monday <span>{pharmacydetail[0].monday}</span></li>
                                                            <li>Tuesday <span>{pharmacydetail[0].tuesday}</span></li>
                                                            <li>Wednesday <span>{pharmacydetail[0].wednesday}</span></li>
                                                            <li>Thusday <span>{pharmacydetail[0].thursday}</span></li>
                                                            <li>Friday <span>{pharmacydetail[0].friday}</span></li>
                                                            <li>Saturday <span>{pharmacydetail[0].saturday}</span></li>
                                                            <li>Sunday <span>{pharmacydetail[0].sunday}</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="doctor-details-area pb-70">
                        <div className="container">
                            <div className="row border pt-3">
                                <div className="col-12">
                                    <img style={{ width: "100%" }} src={'../../../images/map.png'} alt={pharmacydetail[0].pharmacyName} />
                                </div>
                                <div className="col-12 mt-3 border pt-3">
                                    <h3 className="font-weight-bold">Contact info</h3>
                                    <span><b>Call: </b><a href={'tel:' + pharmacydetail[0].phoneNumber}>{pharmacydetail[0].phoneNumber}</a></span>
                                    <br />
                                    <span><b><i className="fa fa-map-marker" style={{ color: 'red' }}></i> </b> {pharmacydetail[0].address}</span>
                                    <p><a href={pharmacydetail[0].mapDirection} target="_blank" className="font-weight-bold">Direction</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="doctor-details-area pb-70">
                        <div className="container">
                            <h4 style={{ marginLeft: '-1%' }}><b><u>Frequently Asked Questions</u></b></h4>
                            <br/>
                            <div className="row">
                                <div className="col-md-8 border pt-3">
                                    <h6>Where is {pharmacydetail[0].pharmacyName.replaceAll("&#x27;", "'")} located?</h6>
                                    <p>{pharmacydetail[0].pharmacyName.replaceAll("&#x27;", "'")} is located at {pharmacydetail[0].address}</p>
                                </div>
                                <div className="col-md-8 border pt-3 mt-1">
                                    <h6>Is {pharmacydetail[0].pharmacyName.replaceAll("&#x27;", "'")} has Open today?</h6>
                                    <p>Please contact us on this number: {pharmacydetail[0].phoneNumber}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}