import React from "react";
import { NavLink } from "react-router-dom";
import BASE_URL from "../API";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Footer() {
    const d = new Date();
    let year = d.getFullYear().toString();

    let top = {
        display: 'none',
        position: 'fixed',
        bottom: '70px',
        right: '30px',
        zIndex: '99',
        color: '#1000ff',
        cursor: 'pointer',
        width: '40px',
        cursor: 'pointer'
    }

    window.onscroll = function () { scrollFunction() };
    function scrollFunction() {
        if (document.body.scrollTop > 700 || document.documentElement.scrollTop > 700) {
            document.getElementById("top").style.display = "block";
        } else {
            if (document.getElementById("top") != null) {
                document.getElementById("top").style.display = "none";
            }
        }
    }

    function scrollTop() {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    const sendEmail = () => {
        var name = document.getElementById('Name').value;
        var email = document.getElementById('Email').value;
        var message = document.getElementById('Message').value

        if (name == "" || email == "" || message == "") {
            toast("Fill all Fields")
            return;
        }

        var obj = {
            Name: name,
            Email: email,
            Message: message
        }

        let jsonData = JSON.stringify(obj);
        fetch(BASE_URL.URL1 + '/api/Home/SendFeedBackEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: jsonData
        }).then(response => {
            if (response.ok == true) {
                toast("Thank you for giving us Feedback");
                scrollTop()
                document.getElementById('Name').value = '';
                document.getElementById('Email').value = '';
                document.getElementById('Message').value = '';
            } else {
                toast('Failed! ' + response.status);
            }
        }).catch(error => {
            toast(error)
            return;
        });
    }

    return (
        <div className="footer">
            <ToastContainer toastStyle={{ backgroundColor: 'lightblue', color: 'black' }} />
            <svg id='top' onClick={scrollTop} title="Go To Top" className="text-gray-800 dark:text-white" style={top} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2M12 4v12m0-12 4 4m-4-4L8 8" />
            </svg>
            <footer className="pt-100 pb-70">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-6 col-lg-2">
                            <div className="footer-item">
                                <div className="footer-quick">
                                    <h3>Our Services</h3>
                                    <ul>
                                        <li><NavLink to="/best-doctors/">Doctor</NavLink></li>
                                        <li><NavLink to="/best-hospitals/">Hospitals</NavLink></li>
                                        <li><NavLink to="/best-pharmacies/">Pharmacy</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-2">
                            <div className="footer-item">
                                <div className="footer-quick">
                                    <h3>.</h3>
                                    <ul>
                                        <li><NavLink to='/best-urgent-care/'>Urgent Care</NavLink></li>
                                        <li><NavLink to='/best-group-practice/'>Group Practice</NavLink></li>
                                        <li><a>Dental Clinic</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-2">
                            <div className="footer-item">
                                <div className="footer-quick">
                                    <h3>Quick Links</h3>
                                    <ul>
                                        <li><a>Nursing</a></li>
                                        <li><NavLink to={'/blogs/'}>Blogs</NavLink></li>
                                        <li><NavLink to={'/write-for-us/'}>Write For Us</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-2">
                            <div className="footer-item">
                                <div className="footer-quick">
                                    <h3>.</h3>
                                    <ul>
                                        <li><a>About us</a></li>
                                        <li><a>Faqs</a></li>
                                        <li><NavLink to='/contact-us/'>Contact Us</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="footer-item">
                                <div className="footer-feedback">
                                    <h3>Feedback</h3>
                                    <form>
                                        <div className="form-group"><input type="text" className="form-control" id="Name" placeholder="Name" /></div>
                                        <div className="form-group"><input type="text" className="form-control" id="Email" placeholder="Email" /></div>
                                        <div className="form-group"><textarea className="form-control" id="Message" rows="3" placeholder="Message"></textarea></div>
                                        <div className="text-left"><button type="button" onClick={sendEmail} className="btn feedback-btn">SUBMIT</button></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="copyright-area">
                <div className="container">
                    <div className="copyright-item">
                        <p>CopyRights &copy; 2023 - {year} DHPFinder, All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    )
}