import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useParams, useLocation } from "react-router-dom";
import BASE_URL from "../../API.js";
import LoadingBar from "react-top-loading-bar";

export default function Hospitals() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    var parameter1 = useParams();
    const { pathname } = useLocation();
    var state = parameter1.parameter1.replace('best-hospitals-in-', '').replace(/-/g, ' ').trim();
    var city = parameter1.parameter2.replace('best-hospitals-in-', '').replace(/-/g, ' ').trim();

    const [progress, setProgress] = useState(70);
    // const [states, setstates] = useState([]);
    // useEffect(() => {
    //     window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    //     axios.get(BASE_URL.URL1 + "/api/Home/GetHospitalStates")
    //         .then(response => setstates(response.data))
    //         .catch(err => {
    //             //console.log(err);
    //         })
    // }, []);

    const [hospital, sethospital] = useState([]);
    const [hospitalerr, sethospitalerr] = useState(0);
    const [blogs, setblogs] = useState([]);
    const [blogserr, setblogserr] = useState(0);
    useEffect(() => {
        axios.get(BASE_URL.URL1 + "/api/Home/GetHospitals?state=" + state + "&city=" + city)
            .then(response => {
                sethospital(response.data)
                setProgress(100)
                axios.get(BASE_URL.URL1 + "/api/Blog/GetBlogsBySpeciality?speciality=''")
                    .then(response => setblogs(response.data))
                    .catch(err => {
                        //console.log(err);
                        setblogserr(1)
                    })
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
                sethospitalerr(1)
            })
    }, [pathname]);
    //console.log(hospital);

    return (
        <>
            <Helmet>
                <title>Best Hospitals near {parameter1.parameter2.replace(/-/g, ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase())}, {state.replace(/(^\w|\s\w)/g, m => m.toUpperCase())} | DHPFinder</title>
                <meta name="description" content={"Find best hospitals near " + parameter1.parameter2.replace(/-/g, ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + ', ' + state.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + '. Emergency hospitals Review Survey quality ratings, locations, and providers at facilities near you'} />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            <div class="page-title-area" style={{ backgroundImage: 'url(../../../images/hospitals/mainbannerhospital.jpg)' }}>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="page-title-item">
                            <h3 className="text-white" style={{ textTransform: 'capitalize' }}><b>Hospitals in {parameter1.parameter2.replace(/-/g, ' ').trim()}</b></h3>
                            <ul>
                                <li><NavLink to="/best-hospitals/">Home</NavLink></li>
                                <li class="active text-capitalize">{parameter1.parameter2.replace(/-/g, ' ').trim()}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="urgent-care-area urgent-care-area-two pt-100 pb-70">
                <div class="container">
                    <div class="row justify-content-center">
                        <div className="col-md-9 col-12">
                            <div className="container">
                                {
                                    hospitalerr != 1 ?
                                        hospital.length > 0 ?
                                            hospital.map((d, i) => {
                                                return (
                                                    <NavLink to={`/best-hospitals/` + state.replace(/ /g, '-') + `/` + city.replace(/ /g, '-') + `/` + d.hospitalName.replaceAll(/,/g, '').replaceAll('.', '').replaceAll('/', '-').replace(/ /g, '-').toLowerCase().trim() + `-` + d.uid} className="mb-3 w-100">
                                                        <div className="row">
                                                            {/* <div class="col-md-6 col-lg-12"> */}
                                                            <div class="card mb-2 doctor-item" style={{ maxWidth: '100%' }}>
                                                                <div class="row no-gutters p-2">
                                                                    <div class="col-md-9 col-12" style={{ textAlign: 'left' }}>
                                                                        <div className="d-flex" style={{ marginTop: 'auto' }}>
                                                                            <img src='../../../images/hospitals/hospital.png' style={{ width: '80px', height: '80px', borderRadius: '50px' }} alt={d.hospitalName} />
                                                                            <div className="my-auto" style={{ marginLeft: '25px' }}>
                                                                                <h6 class="card-title mb-0 font-weight-bolder" style={{ fontFamily: 'sans-serif' }}><a>{d.hospitalName}</a></h6>
                                                                                {d.rating != "" && <span className="text-dark"><p class="card-text mb-0"><b>Rating: </b><small>{d.rating}</small></p></span>}
                                                                                <span className="text-dark"><p class="card-text mb-0"><b><i className="fa fa-map-marker" style={{ color: 'red' }}></i> </b><small>{d.address}</small></p></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-3 col-12 p-2 my-auto">
                                                                        <button className="btn btn-danger w-100">Details</button>
                                                                    </div>
                                                                </div>
                                                                {/* </div> */}
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                )
                                            })
                                            : <div className="text-center"><h2><b>Loading...</b></h2></div>
                                        : <>
                                            <h4 className="text-center">No Hospital Found</h4>
                                            <br />
                                            <div className="col-12 text-center">
                                                <NavLink to='/' className='btn btn-danger text-center'>Go To Home</NavLink>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog-area pt-100 pb-70 mb-md-0 mb-3">
                {
                    blogs.length > 0 &&
                    <div className="container">
                        <div className="section-title">
                            <h2>Recommended Blogs</h2>
                        </div>
                        <div className="row justify-content-center">
                            {
                                blogs.map((b =>
                                    <div className="col-md-6 col-lg-2">
                                        <NavLink to={'/blogs/' + b.blogHeading.trim().replace(/ /g, '-').toLowerCase()}>
                                            <div className="blog-item">
                                                <div className="blog-top">
                                                    <img src={'https://www.dhpfinder.com' + b.blogImage} alt={b.blogHeading} />
                                                </div>
                                                <div className="blog-bottom">
                                                    <h6 className="font-weight-bold">{b.blogHeading}</h6>
                                                    <p><small className="text-dark">{b.blogDescription}... Click here for more details.</small></p>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
            </div>
        </>
    )
}