import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import LoadingBar from "react-top-loading-bar";
import BASE_URL from "../../API";
import { NavLink } from "react-router-dom";


export default function Index() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }
    
    const [progress, setProgress] = useState(70)
    const [states, setstates] = useState([]);
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        axios.get(BASE_URL.URL1 + "/api/Doctor/GetDoctorStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
            })
    }, []);
    //console.log(states);

    return (
        <>
            <Helmet>
                <title>Best Qualified Doctors in USA | DHPFinder</title>
                <meta name="description" content="Finding Qualified doctor means focusing on the elements that can really affect your health." />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            <div class="page-title-area" style={{ backgroundImage: 'url(../images/doctors/mainbannerdoctor.jpg)' }}>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="page-title-item">
                            <h2>Find Qualified Doctors</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="doctor-search-area">
                <div class="container">
                    <form>
                        <div class="row doctor-search-wrap">
                            <div class="col-sm-6 col-lg-6">
                                <div class="doctor-search-item">
                                    <div class="form-group"><i class="icofont-doctor-alt"></i><label>Search</label><input
                                        type="text" class="form-control" placeholder="Doctor Name" /></div><button
                                            type="submit" class="btn doctor-search-btn"><i
                                                class="icofont-search-1"></i></button>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="doctor-search-item">
                                    <div class="form-group"><i class="icofont-doctor-alt"></i><label>Search</label><input
                                        type="text" class="form-control" placeholder="City Name" /></div><button
                                            type="submit" class="btn doctor-search-btn"><i
                                                class="icofont-search-1"></i></button>
                                </div>
                            </div>
                            {/* <div class="col-sm-6 col-lg-6">
                                <div class="doctor-search-item">
                                    <div class="form-group"><i class="icofont-hospital"></i><label>Category</label><select
                                        class="form-control">
                                        <option>Neurosurgeon</option>
                                        <option>Cardiology</option>
                                        <option>Pathology</option>
                                        <option>Dental Care</option>
                                    </select></div>
                                </div>
                            </div> */}
                        </div>
                    </form>
                </div>
            </div>

            <div className="services-area mt-5">
                <div className="container">
                    <div className="section-title-two" style={{ maxWidth: '100%' }}><span>Services</span>
                        <h2>Search by Doctor Specialities</h2>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-6 col-lg-2">
                            <NavLink to="/specialities/best-doctors-for-dentist/">
                                <div className="service-item bg rounded-4" style={{ paddingTop: '20px' }}>
                                    <div className="service-front"><img src={'../images/doctors/dentisticon.png'} style={{ width: '45%' }} />
                                        <h3 className="mt-3">Dentist</h3>

                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> */}
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-6 col-lg-2">
                            <NavLink to="/specialities/best-doctors-for-cardiologists/">
                                <div className="service-item bg rounded-4" style={{ paddingTop: '20px' }}>
                                    <div className="service-front"><img src={'../images/doctors/cardiologist.png'} style={{ width: '45%' }} />

                                        <h3 className="mt-3">Cardiologists</h3>

                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> */}
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-6 col-lg-2">
                            <NavLink to="/specialities/best-doctors-for-neurologists/">
                                <div className="service-item bg rounded-4" style={{ paddingTop: '20px' }}>
                                    <div className="service-front"><img src={'../images/doctors/neurologist.png'} style={{ width: '45%' }} />
                                        <h3 className="mt-3">Neurologists</h3>

                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> */}
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-6 col-lg-2">
                            <NavLink to="/specialities/best-doctors-for-psychologists/">
                                <div className="service-item bg rounded-4" style={{ paddingTop: '20px' }}>
                                    <div className="service-front"><img src={'../images/doctors/psychology.png'} style={{ width: '45%' }} />
                                        <h3 className="mt-3">Psychologists</h3>

                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> */}
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-6 col-lg-2">
                            <NavLink to="/specialities/best-doctors-for-psychiatrists/">
                                <div className="service-item bg rounded-4" style={{ paddingTop: '20px' }}>
                                    <div className="service-front"><img src={'../images/doctors/psychiatrist.png'} style={{ width: '45%' }} />
                                        <h3 className="mt-3">Psychiatrists</h3>

                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> */}
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <div class="departments-area pt-100 pb-70">
                <div class="container">
                    <div className="section-title-two"><span>Services</span>
                        <h2 style={{ width: '100%' }}>Find Care</h2>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-ear,-nose-and-throat/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/EAR-NOSE-THROAT.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Ear,Nose,Throat</h6>
                                </div>
                            </NavLink>
                        </div>

                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-chiropractors/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/chiropractors.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Chiropractors</h6>
                                </div>
                            </NavLink>
                        </div>
                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-clinical-psychologists/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/Cli-psychologists.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">CLI. Psychologists</h6>
                                </div>
                            </NavLink>
                        </div>
                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-counselors/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/counselors.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Counselors</h6>
                                </div>
                            </NavLink>
                        </div>
                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-dermatologist/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/dermatologist.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Dermatologist</h6>
                                </div>
                            </NavLink>
                        </div>
                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-endocrinologists/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/endocrinologist.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Endocrinologists</h6>
                                </div>
                            </NavLink>
                        </div>

                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-gastroenterologists/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/gastroenterologist.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Gastroenterologists</h6>
                                </div>
                            </NavLink>
                        </div>

                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-geriatricians/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/psychiatrist.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Geriatricians</h6>
                                </div>
                            </NavLink>
                        </div>


                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-family-practitioners/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/Family-Practitioners.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Family Practitioners</h6>
                                </div>
                            </NavLink>
                        </div>


                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-general-surgeons/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/General-Surgeons.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">General Surgeons</h6>
                                </div>
                            </NavLink>
                        </div>

                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-hematologists/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/hematologist.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Hematologists</h6>
                                </div>
                            </NavLink>
                        </div>


                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-gynecologists-and-obstetricians/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/gynecologist.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Gynecologists</h6>
                                </div>
                            </NavLink>
                        </div>

                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-internists/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/internist.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Internists</h6>
                                </div>
                            </NavLink>
                        </div>


                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-nephrologists/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/nephrologist.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Nephrologists</h6>
                                </div>
                            </NavLink>
                        </div>


                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-neurosurgeons/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/neurosurgeon.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Neurosurgeons</h6>
                                </div>
                            </NavLink>
                        </div>


                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-ophthalmologists/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/ophthalmologist.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Ophthalmologists</h6>
                                </div>
                            </NavLink>
                        </div>


                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-orthopedic-surgeons/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/Orthopedic-Surgeons.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Orthopedic Surgeons</h6>
                                </div>
                            </NavLink>
                        </div>


                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-pain-medicine/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/Pain-Medicine.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Pain Medicine</h6>
                                </div>
                            </NavLink>
                        </div>


                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-pediatricians/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/Pediatricians.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Pediatricians</h6>
                                </div>
                            </NavLink>
                        </div>


                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-plastic-surgeon/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/Plastic-Surgeons.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Plastic Surgeons</h6>
                                </div>
                            </NavLink>
                        </div>


                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-rheumatologists/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/Rheumatologists.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Rheumatologists</h6>
                                </div>
                            </NavLink>
                        </div>


                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-sports-medicine/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/Sports-Medicine.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Sports Medicine</h6>
                                </div>
                            </NavLink>
                        </div>


                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/best-doctors-for-urologists/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/urologist.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">Urologists</h6>
                                </div>
                            </NavLink>
                        </div>


                        <div class="col-6 col-lg-3">
                            <NavLink to="/specialities/">
                                <div class="department-item" style={{ paddingTop: '10px', paddingBottom: '0px' }}><img src={'../images/doctors/All-Specialties.png'} style={{ width: '30%' }} />
                                    <h6 className="mt-3">All Specialties</h6>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-area">
                {
                    states.length > 0 ?
                        <div className="container">
                            <div className="section-title-two"><span>States</span>
                                <h2 style={{ width: '100%' }}>Select By States</h2>
                            </div>
                            <div className="row justify-content-center" id="states">
                                {
                                    states.map((a =>
                                        <div className="col-md-4 col-6 col-lg-4">
                                            <NavLink to={'/best-doctors/' + a.state.trim().replace(/ /g, '-').toLowerCase() + '/'}>
                                                <div className="container">
                                                    <div className="row service-item rounded-4" style={{ padding: '5px', border: '1px solid blue' }}>
                                                        <div className="col-md-3">
                                                            <img src={a.flag} style={{ width: '50px', height: '50px', borderRadius: '50px' }} />
                                                        </div>
                                                        <div className="col-md-9" style={{ textAlign: 'left' }}>
                                                            <h5>{a.state}</h5>
                                                            <small>Best Doctors in {a.state.replace('District of Columbia', 'D.C.')}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        : <div className="text-center"><h2><b>Loading...</b></h2></div>
                }
            </div>
            <br />
            <br />
            <br />
        </>
    )
}