import React from 'react'
import { Helmet } from "react-helmet";
import { useEffect } from 'react';
import axios from "axios";
import LoadingBar from "react-top-loading-bar"
import { NavLink } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from "../API";

export default function ContactUs() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    const vname = (event) => {
        var name = document.getElementById("name").value;
        document.getElementById("name").style.textTransform = "capitalize";
        var phone = document.getElementById("phone").value;
        var email = document.getElementById("email").value;
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        var message = document.getElementById("message").value;
        if (name == "" || name == null || name == undefined
            || phone == "" || phone == null || phone == undefined
            || email == "" || email == null || email == undefined
            || message == "" || message == null || message == undefined) {
            document.getElementById("sendMessage").style.pointerEvents = "none";
            document.getElementById("sendMessage").style.color = "#ccc";
        } else if (!filter.test(email)) {
            document.getElementById("sendMessage").style.pointerEvents = "none";
            document.getElementById("sendMessage").style.color = "#ccc";
        } else {
            document.getElementById("sendMessage").style.pointerEvents = "";
            document.getElementById("sendMessage").style.color = "";
        }
    }

    const vphone = (event) => {
        var name = document.getElementById("name").value;
        var phone = document.getElementById("phone").value;
        var email = document.getElementById("email").value;
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        var message = document.getElementById("message").value;
        if (name == "" || name == null || name == undefined
            || phone == "" || phone == null || phone == undefined
            || email == "" || email == null || email == undefined
            || message == "" || message == null || message == undefined) {
            document.getElementById("sendMessage").style.pointerEvents = "none";
            document.getElementById("sendMessage").style.color = "#ccc";
        } else if (!filter.test(email)) {
            document.getElementById("sendMessage").style.pointerEvents = "none";
            document.getElementById("sendMessage").style.color = "#ccc";
        } else {
            document.getElementById("sendMessage").style.pointerEvents = "";
            document.getElementById("sendMessage").style.color = "";
        }
    }

    const vemail = (event) => {
        var name = document.getElementById("name").value;
        var phone = document.getElementById("phone").value;
        var email = document.getElementById("email").value;
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        var message = document.getElementById("message").value;
        if (name == "" || name == null || name == undefined
            || phone == "" || phone == null || phone == undefined
            || email == "" || email == null || email == undefined
            || message == "" || message == null || message == undefined) {
            document.getElementById("sendMessage").style.pointerEvents = "none";
            document.getElementById("sendMessage").style.color = "#ccc";
        } else if (!filter.test(email)) {
            document.getElementById("sendMessage").style.pointerEvents = "none";
            document.getElementById("sendMessage").style.color = "#ccc";
        } else {
            document.getElementById("sendMessage").style.pointerEvents = "";
            document.getElementById("sendMessage").style.color = "";
        }
    }

    const vmessage = (event) => {
        var name = document.getElementById("name").value;
        var phone = document.getElementById("phone").value;
        var email = document.getElementById("email").value;
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        var message = document.getElementById("message").value;
        document.getElementById("message").style.textTransform = "capitalize";
        if (name == "" || name == null || name == undefined
            || phone == "" || phone == null || phone == undefined
            || email == "" || email == null || email == undefined
            || message == "" || message == null || message == undefined) {
            document.getElementById("sendMessage").style.pointerEvents = "none";
            document.getElementById("sendMessage").style.color = "#ccc";
        } else if (!filter.test(email)) {
            document.getElementById("sendMessage").style.pointerEvents = "none";
            document.getElementById("sendMessage").style.color = "#ccc";
        } else {
            document.getElementById("sendMessage").style.pointerEvents = "";
            document.getElementById("sendMessage").style.color = "";
        }
    }

    const progressmsg = () => toast("Sending...");
    const sendMessage = (e) => {
        var name = document.getElementById("name").value;
        var phone = document.getElementById("phone").value;
        var email = document.getElementById("email").value;
        var message = document.getElementById("message").value;

        if (name == "" || phone == "" || email == "" || message == "") {
            return;
        }

        var obj = {
            Name: name,
            Phone: phone,
            Email: email,
            Message: message
        }

        progressmsg()
        let json = JSON.stringify(obj);
        fetch(BASE_URL.URL1 + '/api/Home/SendContactUsEmail', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: json
        }).then(response => {
            //console.log(response);
            if (response.ok == true) {
                toast("Send Successfull!");
                window.location.reload()
            } else {
                toast('Failed! ' + response.status);
            }
        }).catch(error => {
            toast(error);
            return;
        });
    }

    return (
        <>
            <Helmet>
                <title>Contact US | DHPFinder</title>
                <meta name="description" content="Email us with a particular inquiry, remark or request and we'll be glad to reach you consequently. To reach out, visit DHPFInder" />
            </Helmet>
            <LoadingBar color='blue' progress={100} height={5} />
            <div class="container-fluid p-0">
                <img src='../images/writeforus/CONTACT US.png' />
            </div>
            <div class="location-area pt-100 pb-70">
                <div class="container">
                    <div class="row justify-content-center location-wrap">
                        <div class="col-sm-6 col-lg-4">
                            <div class="location-item"><i class="icofont-location-pin"></i>
                                <h3>Location</h3>
                                <p>US</p>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                            <div class="location-item"><i class="icofont-ui-message"></i>
                                <h3>Email</h3>
                                <p><a href="mailto:support@dhpfinder.com">support@dhpfinder.com</a></p>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                            <div class="location-item"><i class="icofont-ui-call"></i>
                                <h3>Phone</h3>
                                <p>+00 000 000 000</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="drop-area">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-7 p-0">
                            <div class="drop-item" style={{backgroundColor: 'ghostwhite'}}>
                                <div class="drop-left">
                                    <h2>Contact Us</h2>
                                    <form>
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <input type="text" onKeyUp={(e) => vname(e)} class="form-control" id='name' placeholder="Your Name" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <input type="number" onKeyUp={(e) => vemail(e)} class="form-control" id='phone' placeholder="Your Phone" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <input type="email" onKeyUp={(e) => vphone(e)} class="form-control" id='email' placeholder="Your Email" />
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <div class="form-group">
                                                    <textarea cols="30" rows="6" onKeyUp={(e) => vmessage(e)} class="form-control" id='message' placeholder="Your Message..."></textarea></div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <button type="button" onClick={sendMessage} class="drop-btn" style={{ pointerEvents: 'none', color: '#ccc' }} id='sendMessage'>Send Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 p-0">
                            <div class="speciality-item speciality-right speciality-right-two speciality-right-three">
                                <img src="../images/blog/35.png" alt="Contact" />
                                <div class="speciality-emergency">
                                    <div class="speciality-icon">
                                        <i class="icofont-ui-call"></i>
                                    </div>
                                    <h3>Contact Us</h3>
                                    <p>+00 000 000 000</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 justify-content-center">
                        <div className="col-5 text-center">
                            <NavLink to='/' className='btn btn-danger text-center'>Go To Home</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
        </>
    )
}
