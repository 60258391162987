import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import MainIndex from './components/mainhomepage/Index';
import DoctorsIndex from './components/doctors/Index';
import DoctorCities from './components/doctors/DoctorCities';
import DoctorSpecialities from './components/doctors/DoctorSpecialities';
import DoctorsBySpeciality from './components/doctors/DoctorsBySpeciality';
import DoctorSpecialityDetails from './components/doctors/DoctorSpecialityDetails';
import GetSpecialities from './components/doctors/GetSpecialities';
import GetDoctorSpecialityList from './components/doctors/GetDoctorSpecialityList';
import DoctorsDetailsBySpeciality from './components/doctors/DoctorsDetailsBySpeciality';
import HospitalsIndex from './components/hospitals/Index';
import HospitalCities from './components/hospitals/HospitalCities';
import Hospitals from './components/hospitals/Hospitals';
import HospitalDetails from './components/hospitals/HospitalDetails';
import PharmaciesIndex from './components/pharmacies/Index';
import PharmacyCities from './components/pharmacies/PharmacyCities';
import Pharmacy from './components/pharmacies/Pharmacy';
import PharmacyDetails from './components/pharmacies/PharmacyDetails';
import UrgentCareIndex from './components/urgentcare/Index';
import UrgentCareCities from './components/urgentcare/UrgentCareCities';
import UrgentCares from './components/urgentcare/UrgentCares';
import UrgentCareDetails from './components/urgentcare/UrgentCareDetails';
import GroupPracticeIndex from './components/grouppractice/Index';
import GroupPracticeCities from './components/grouppractice/GroupPracticeCities';
import GroupPractice from './components/grouppractice/GroupPractice';
import GroupPracticeDetails from './components/grouppractice/GroupPracticeDetails';
import WriteforUs from './components/WriteforUs';
import ContactUs from './components/ContactUs';
import Blogs from './components/blogs/Blogs';
import BlogDetails from './components/blogs/BlogDetails';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<MainIndex />} />
        <Route path="*" element={<MainIndex />} />
        <Route path="/best-doctors/" element={<DoctorsIndex />} />
        <Route path="/best-doctors/:parameter/" element={<DoctorCities />} />
        <Route path="/best-doctors/:parameter1/:parameter2/" element={<DoctorSpecialities />} />
        <Route path="/best-doctors/:parameter1/:parameter2/:parameter3/" element={<DoctorsBySpeciality />} />
        <Route path="/best-doctors/:parameter1/:parameter2/:parameter3/:parameter4" element={<DoctorSpecialityDetails />} />
        <Route path="/specialities/" element={<GetSpecialities />} />
        <Route path="/specialities/:parameter/" element={<GetDoctorSpecialityList />} />
        <Route path="/specialities/:parameter1/:parameter2" element={<DoctorsDetailsBySpeciality />} />
        <Route path="/best-hospitals/" element={<HospitalsIndex />} />
        <Route path="/best-hospitals/:parameter/" element={<HospitalCities />} />
        <Route path="/best-hospitals/:parameter1/:parameter2/" element={<Hospitals />} />
        <Route path="/best-hospitals/:parameter1/:parameter2/:parameter3" element={<HospitalDetails />} />
        <Route path="/best-pharmacies/" element={<PharmaciesIndex />} />
        <Route path="/best-pharmacies/:parameter/" element={<PharmacyCities />} />
        <Route path="/best-pharmacies/:parameter1/:parameter2/" element={<Pharmacy />} />
        <Route path="/best-pharmacies/:parameter1/:parameter2/:parameter3" element={<PharmacyDetails />} />
        <Route path="/best-group-practice/" element={<GroupPracticeIndex />} />
        <Route path="/best-group-practice/:parameter/" element={<GroupPracticeCities />} />
        <Route path="/best-group-practice/:parameter1/:parameter2/" element={<GroupPractice />} />
        <Route path="/best-group-practice/:parameter1/:parameter2/:parameter3" element={<GroupPracticeDetails />} />
        <Route path="/best-urgent-care/" element={<UrgentCareIndex />} />
        <Route path="/best-urgent-care/:parameter/" element={<UrgentCareCities />} />
        <Route path="/best-urgent-care/:parameter1/:parameter2/" element={<UrgentCares />} />
        <Route path="/best-urgent-care/:parameter1/:parameter2/:parameter3" element={<UrgentCareDetails />} />
        <Route path="/write-for-us/" element={<WriteforUs />} />
        <Route path="/contact-us/" element={<ContactUs />} />
        <Route path="/blogs/" element={<Blogs />} />
        <Route path="/blogs/:parameter" element={<BlogDetails />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
