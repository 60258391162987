import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import LoadingBar from "react-top-loading-bar";
import BASE_URL from "../../API";
import { NavLink, useParams, useNavigate } from "react-router-dom";

export default function PharmacyCities() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const navigate = useNavigate();

    const parameter = useParams();
    const [progress, setProgress] = useState(70);
    const [cities, setcities] = useState([]);
    const [pharmacy, setpharmacy] = useState([]);
    const [pharmacyerr, setpharmacyerr] = useState(0);
    useEffect(() => {
        axios.get(BASE_URL.URL1 + "/api/Home/GetPharmacyCity?state=" + parameter.parameter.replace(/-/g, '').trim())
            .then(response => {
                setcities(response.data)
                setProgress(100)
                if (response.data.length == 0) {
                    setProgress(100)
                    navigate('/best-pharmacies/')
                } else {
                    setcities(response.data)
                    setProgress(100)
                }
                axios.get(BASE_URL.URL1 + "/api/Home/GetRandomPharmacyByState?state=" + parameter.parameter.replace(/-/g, '').trim())
                    .then(response => {
                        setpharmacy(response.data)
                        setProgress(100)
                    })
                    .catch(err => {
                        //console.log(err);
                        setProgress(100)
                        setpharmacyerr(1)
                    })
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
            })
    }, []);
    //console.log(cities);

    return (
        <>
            <Helmet>
                <title>Best Pharmacies in {parameter.parameter.replace(/-/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase())} | DHPFinder</title>
                <meta name="description" content={'Find best Pharmacies in ' + parameter.parameter.replace(/-/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + ', get directions, addresses, phone numbers and more.'} />
            </Helmet>
            <LoadingBar color='blue' progress={progress} height={5} />
            <div class="page-title-area" style={{ backgroundImage: 'url(../../images/pharmacy/mainbannerpharmacy.jpg)' }}>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="page-title-item">
                            <h3 className="text-white" style={{ textTransform: 'capitalize' }}><b>Find Pharmacies in {parameter.parameter.replace(/-/g, '')}</b></h3>
                            <ul>
                                <li><NavLink to="/best-pharmacies/">Home</NavLink></li>
                                <li class="active">Cities</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className="services-area">
                {
                    cities.length > 0 &&
                    <div className="container">
                        <div className="section-title-two"><span>Cities</span>
                            <h2 style={{ width: '100%' }}>Select By City</h2>
                        </div>
                        <div className="row border p-2">
                            {
                                cities.map((a =>
                                    <div className="col-md-3 col-6 col-lg-3 mb-2" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <NavLink to={'/best-pharmacies/' + parameter.parameter + '/' + a.city.trim().replace(/ /g, '-').toLowerCase() + '/'}>
                                            <span style={{ whiteSpace: 'nowrap', fontWeight: '600' }} className="">{a.city}</span>
                                        </NavLink>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
            </div>
            <br />
            <div class="urgent-care-area urgent-care-area-two pt-100 pb-70">
                <div class="container">
                    <div class="row">
                        <div className="col-md-9 col-12">
                            <div className="container">
                                {
                                    pharmacyerr != 1 ?
                                        pharmacy.length > 0 &&
                                        pharmacy.map((d, i) => {
                                            return (
                                                <NavLink to={`/best-pharmacies/` + d.state.replace(/ /g, '-').toLowerCase() + `/` + d.city.replace(/ /g, '-').toLowerCase() + `/` + d.pharmacyName.replaceAll(/,/g, '').replaceAll('.', '').replaceAll('#', '').replace(/ /g, '-').toLowerCase().trim() + `-` + d.uid} className="mb-3 w-100">
                                                    <div className="row">
                                                        {/* <div class="col-md-6 col-lg-12"> */}
                                                        <div class="card mb-3 doctor-item" style={{ maxWidth: '100%' }}>
                                                            <div class="row no-gutters p-2">
                                                                <div class="col-md-9 col-12" style={{ textAlign: 'left' }}>
                                                                    <div className="d-flex" style={{ marginTop: 'auto' }}>
                                                                        <img src={'../../../images/pharmacy/pharmacy.png'} style={{ width: '80px', height: '80px', borderRadius: '50px' }} alt={d.pharmacyName} />
                                                                        <div className="my-auto" style={{ marginLeft: '25px' }}>
                                                                            <h6 class="card-title mb-0 font-weight-bolder" style={{ fontFamily: 'sans-serif' }}><a>{d.pharmacyName}</a></h6>
                                                                            <span className="text-dark"><p class="card-text mb-0"><b><i className="fa fa-map-marker" style={{ color: 'red' }}></i> </b><small>{d.address}</small></p></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3 col-12 p-2 my-auto">
                                                                    <button className="btn btn-danger w-100">Details</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* </div> */}
                                                    </div>
                                                </NavLink>
                                            )
                                        })
                                        : <h4 className="text-center">No Pharmacy Found</h4>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </>
    )
}